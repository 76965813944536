import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AuthenticationService } from 'app/services';
import { ToastNotificationService } from 'app/components';
import { SocialUser } from 'app/models';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';

@Component({
  selector: 'app-subscriber-user',
  templateUrl: './subscriber-user.component.html',
  styleUrls: ['./subscriber-user.component.scss']
})
export class SubscriberUserComponent implements OnInit, OnChanges {
  public isAuth0Logged: boolean;
  public loginForm: FormGroup;
  public isLogging: boolean;
  public userProfile: SocialUser;
  public showforgotPasswordDialog: boolean;
  public redirectTo: string;
  public paddingB: string;
  public paddingRL: string;
  @Input() public screenSize: string;
  @Output() handleLogin: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private authService: AuthService,
    private authTechnewService: AuthenticationService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService,
    private store: Store<AppState>
  ) {
    this.isLogging = false;
    this.userProfile = null;
    this.showforgotPasswordDialog = false;
    this.paddingB = '2rem';
    this.paddingRL = '6rem';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.screenSize.currentValue === 'small') {
      this.paddingB = '4rem';
      this.paddingRL = '2rem';
    } else {
      this.paddingB = '2rem';
      this.paddingRL = '6rem';
    }
  }

  ngOnInit(): void {
    this.logout();

    this.loginForm = this.formBuilder.group({
      username: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
        ]
      ],
      password: ['', Validators.required]
    });

    this.activateRoute.queryParams.subscribe(params => {
      this.redirectTo = params.redirectUrl || '/';
      this.authTechnewService.setRedirectUrl(this.redirectTo);
    });
  }

  goToTrial() {
    this.router.navigateByUrl('/auth/signup');
  }

  invalidField(field: string) {
    return (
      this.loginForm.controls[field].errors &&
      this.loginForm.controls[field].touched
    );
  }

  enterKeyPress(event) {
    event.preventDefault();
    this.loginWithTechnews();
  }

  loginWithTechnews(): void {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    this.isLogging = true;
    this.authTechnewService
      .loginWithTechnews(this.loginForm.value)
      .subscribe(({ tokenAccess, denied }: any) => {
        this.isLogging = false;
        if (tokenAccess) {
          // this.router.navigateByUrl(`auth?jwtt=${tokenAccess}&type=technews`);
          this.handleLogin.emit({ jwtt: tokenAccess, type: 'technews' });
        } else {
          this.notificationService.showMessage(
            'Subcriber user login denied.',
            'Error'
          );
        }
      });
  }

  loginWithGoogle(): void {
    this.authTechnewService.saveUserProfile({
      createTrial: false
    });
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        connection: 'google-oauth2'
      }
    });
  }

  loginWithLinkedIn(): void {
    this.authTechnewService.saveUserProfile({
      createTrial: false
    });
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        connection: 'linkedin'
      }
    });
  }

  loginWithMicrosoft(): void {
    this.authTechnewService.saveUserProfile({
      createTrial: false
    });
    this.authService.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        connection: 'windowslive'
      }
    });
  }

  logout(): void {
    this.store.dispatch(actions.unSetUserData());
    this.authTechnewService.clearStorage();
  }

  showForgotPasswordDialog() {
    this.showforgotPasswordDialog = true;
  }

  changeVisibleForgotPasswordDialog(e) {
    this.showforgotPasswordDialog = e;
  }
}
