import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Search, FacetSearch } from 'models/index';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private api: string;
  private httpOptions: any;

  constructor(private http: HttpClient) {
    this.api = `${environment.api_url}/search/content`;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  public searchByTypeahead(keywords) {
    // TODO Don't call the API if there are no keywords
    // if (!keywords || keywords.length === 0) {
    //   return of([]);
    // }
    return this.http.get(
      `${environment.api_url}/search/${keywords}`,
      this.httpOptions
    );
  }

  public collectionContent(id: number, page: number = 0) {
    return this.http.post(
      `${environment.api_url}/content/collection/${id}/recent?page=${page}&size=50`,
      {},
      this.httpOptions
    );
  }

  public search(
    search: Search,
    sort: string,
    searchBy: string,
    p,
    s
  ): Observable<any> {
    let url = '';
    switch (searchBy) {
      case 'authors':
        url = `${this.api}/authors/${sort}?page=${p}&size=${s}`;
        break;
      case 'outlets':
        url = `${this.api}/outlets/${sort}?page=${p}&size=${s}`;
        break;
      case 'contents':
        url = `${this.api}/${sort}?page=${p}&size=${s}`;
        break;
    }
    return this.http.post(url, search, this.httpOptions);
  }

  public facetsSearch(
    search: Search,
    page: number = 0,
    size: number = 50
  ): Observable<any> {
    const url = `${this.api}/facets?page=${page}&size=${size}`;
    return this.http.post(url, search, this.httpOptions);
  }

  public searchContent(facetSearch: FacetSearch): Observable<any> {
    let search = {};
    switch (facetSearch.getSearchBy) {
      case 'author':
        search = {
          keywords: facetSearch.getKeywords,
          authors: [facetSearch.getId]
        };
        break;
      case 'outlet':
        search = {
          keywords: facetSearch.getKeywords,
          outlets: [facetSearch.getId]
        };
        break;
      case 'company':
        search = {
          keywords: facetSearch.getKeywords,
          authors: facetSearch.getAuthors,
          outlets: facetSearch.getOutlets
        };
        break;
      case 'topic':
        search = {
          keywords: facetSearch.getKeywords,
          authors: facetSearch.getAuthors,
          outlets: facetSearch.getOutlets
        };
        break;
    }
    return this.http.post(
      `${this.api}/${facetSearch.getSort}?page=${facetSearch.getPage}&size=${facetSearch.getSize}`,
      search,
      this.httpOptions
    );
  }
}
