export interface OutletContact {
  id?: number;
  name?: string;
  webSiteUrl?: string;
  youTubeUrl?: string;
  city?: string;
  state?: string;
  country?: string;
  streetAddress?: string;
  location?: string;
  locationUrl?: string;
  emailAddress?: string;
  twitter?: string;
  linkedIn?: string;
  facebook?: string;
  imageUrl?: string;
  twitterUrl?: string;
  linkedInUrl?: string;
  linkedInSalesUrl?: string;
  facebookUrl?: string;
}

export class OutletProfile {
  public id: number;
  public name: string;
  public emailAddress: string;
  public webSiteUrl: string;
  public youTubeUrl: string;
  public city: string;
  public state: string;
  public country: string;
  public streetAddress: string;
  public location: string;
  public locationUrl: string;
  public twitter: string;
  public linkedIn: string;
  public facebook: string;
  public imageUrl: string;
  public twitterUrl: string;
  public linkedInUrl: string;
  public linkedInSalesUrl: string;
  public facebookUrl: string;
  constructor({
    id,
    name,
    emailAddress,
    webSiteUrl,
    youTubeUrl,
    city,
    state,
    country,
    streetAddress,
    location,
    locationUrl,
    twitter,
    linkedIn,
    facebook,
    imageUrl,
    twitterUrl,
    linkedInUrl,
    linkedInSalesUrl,
    facebookUrl
  }: OutletContact) {
    this.id = id || null;
    this.name = name || null;
    this.emailAddress = emailAddress || null;
    this.webSiteUrl = webSiteUrl || null;
    this.youTubeUrl = youTubeUrl || null;
    this.city = city || null;
    this.state = state || null;
    this.country = country || null;
    this.streetAddress = streetAddress || null;
    this.location = location || null;
    this.locationUrl = locationUrl || null;
    this.twitter = twitter || null;
    this.linkedIn = linkedIn || null;
    this.facebook = facebook || null;
    this.imageUrl = imageUrl || null;
    this.twitterUrl = twitterUrl || null;
    this.linkedInUrl = linkedInUrl || null;
    this.linkedInSalesUrl = linkedInSalesUrl || null;
    this.facebookUrl = facebookUrl || null;
  }
}
