export interface TopicContact {
  id?: number;
  name?: string;
  webSiteUrl?: string;
  picture?: string;
  twitter?: string;
  twitterUrl?: string;
  linkedIn?: string;
  linkedInUrl?: string;
  facebook?: string;
  facebookUrl?: string;
  financial?: string;
}

export class TopicProfile {
  public id: number;
  public name: string;
  public webSiteUrl: string;
  public twitter: string;
  public linkedIn: string;
  public facebook: string;
  public picture: string;
  public twitterUrl: string;
  public linkedInUrl: string;
  public linkedInSalesUrl: string;
  public facebookUrl: string;
  public financial: string;
  constructor({
    id,
    name,
    webSiteUrl,
    linkedIn,
    picture,
    twitter,
    twitterUrl,
    linkedInUrl,
    facebook,
    facebookUrl,
    financial
  }: TopicContact) {
    this.id = id || null;
    this.name = name || null;
    this.webSiteUrl = webSiteUrl || null;
    this.picture = picture || null;
    this.twitter = twitter || null;
    this.twitterUrl = twitterUrl || null;
    this.linkedIn = linkedIn || null;
    this.linkedInUrl = linkedInUrl || null;
    this.facebook = facebook || null;
    this.facebookUrl = facebookUrl || null;
    this.financial = financial || null;
  }
}
