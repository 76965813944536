import { createReducer, on } from '@ngrx/store';
import { AuthorProfile } from 'app/models';
import {
  loadAuthorProfile,
  unSetPosts,
  loadAuthorProfileSuccess,
  loadAuthorProfileError,
  resetAuthorProfile
} from 'redux/actions';

export interface AuthorProfileState {
  authorContactProfile: AuthorProfile;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: AuthorProfileState = {
  authorContactProfile: null,
  loaded: false,
  loading: false,
  error: null
};

const _authorProfileReducer = createReducer(
  initialState,

  on(loadAuthorProfile, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(loadAuthorProfileSuccess, (state, { authorContactProfile }) => ({
    ...state,
    authorContactProfile,
    loading: false,
    loaded: true
  })),

  on(loadAuthorProfileError, (state, { payload }) => {
    const { url, name, message } = payload;
    return {
      ...state,
      authorContactProfile: null,
      loading: false,
      loaded: false,
      error: {
        url: url || null,
        name: name || null,
        message:
          message || 'An error occurred while fetching author contact details.'
      }
    };
  }),

  on(resetAuthorProfile, state => ({
    ...state,
    authorContactProfile: null
  }))
);

export function authorProfileReducer(state, action) {
  return _authorProfileReducer(state, action);
}
