import { createAction, props } from '@ngrx/store';
import { OutletProfile } from 'app/models';

export const loadOutletProfile = createAction(
  '[Outlet Profile] loadOutletProfile',
  props<{
    outletId: number;
  }>()
);

export const loadOutletProfileSuccess = createAction(
  '[Outlet Profile] loadOutletProfileSuccess',
  props<{ outletContactProfile: OutletProfile }>()
);

export const loadOutletProfileError = createAction(
  '[Outlet Profile] loadOutletProfileError',
  props<{ payload: any }>()
);

export const resetOutletProfile = createAction(
  '[Outlet Profile] resetOutletProfile'
);
