import { createReducer, on } from '@ngrx/store';
import { OutletProfile } from 'app/models';
import {
  loadOutletProfile,
  loadOutletProfileSuccess,
  loadOutletProfileError,
  resetOutletProfile
} from 'redux/actions';

export interface OutletProfileState {
  outletContactProfile: OutletProfile;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: OutletProfileState = {
  outletContactProfile: null,
  loaded: false,
  loading: false,
  error: null
};

const _outletProfileReducer = createReducer(
  initialState,

  on(loadOutletProfile, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(loadOutletProfileSuccess, (state, { outletContactProfile }) => ({
    ...state,
    outletContactProfile,
    loading: false,
    loaded: true
  })),

  on(loadOutletProfileError, (state, { payload }) => {
    const { url, name, message } = payload;
    return {
      ...state,
      outletContactProfile: null,
      loading: false,
      loaded: false,
      error: {
        url: url || null,
        name: name || null,
        message:
          message || 'An error occurred while fetching outlet contact details.'
      }
    };
  }),

  on(resetOutletProfile, state => ({
    ...state,
    outletContactProfile: null
  }))
);

export function outletProfileReducer(state, action) {
  return _outletProfileReducer(state, action);
}
