import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { map, head } from 'lodash/fp';
import { eq, includes, flatMap, isEmpty } from 'lodash';
import { Location } from '@angular/common';
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject, Subscription, fromEvent } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import {
  FacetSearch,
  Search,
  SelectedMenuItem,
  TopicResponse,
  TypeaheadResponse,
  TypeaheadSearchGrouped
} from 'app/models';
import {
  TOPIC,
  TYPEAHEAD_FILTER_GROUP,
  COMPANY,
  AUTHOR,
  OUTLET,
  EVENT,
  DEBOUNCE_TIME
} from './typeahead-filter-group';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TopicService } from 'app/services';
import { RELEVANT_SORT } from 'app/constants';

@Component({
  selector: 'app-typeahead-search',
  templateUrl: './typeahead-search.component.html',
  styles: [
    `
      p-grid,
      div,
      h5 {
        color: #d9d9d9 !important;
      }
    `
  ]
})
export class TypeaheadSearchComponent implements OnInit, OnChanges {
  public typeaheadResponseList: TypeaheadSearchGrouped[];
  private searchTypeaheadSubject = new Subject<any>();

  public placeholderSearch: string;
  public selectedItem: any;
  public isLoading: boolean;
  private facets: any;
  private inputTypeaheadValue: string;
  public currentProject: any;
  public body$: Observable<any>;
  public isPanelShowed: boolean;
  public collectionMenuItems: SelectedMenuItem[];

  @ViewChild('typeahead') typeahead: ElementRef;
  @ViewChild('opTypeahead') op: OverlayPanel;

  @Output() runSearchFromTypeahead: EventEmitter<Search> = new EventEmitter();
  @Output() runAuthorOutletSearchFromTypeahead: EventEmitter<
    Search
  > = new EventEmitter();
  @Output() runSearchFromInput: EventEmitter<any> = new EventEmitter();
  @Output() inputTypeahead: EventEmitter<string> = new EventEmitter();

  @Input() onSearch: boolean;

  constructor(
    private store: Store<AppState>,
    private topicService: TopicService,
    private location: Location
  ) {
    this.placeholderSearch = `Search for a topic (e.g. IoT, "3D Printers", Microservices)`;
    this.typeaheadResponseList = [];
    this.collectionMenuItems = [];
    this.selectedItem = null;
    this.isLoading = true;
    this.facets = null;
    this.inputTypeaheadValue = null;
    this.isPanelShowed = false;
    this.body$ = fromEvent(document, 'click');
  }

  ngOnChanges(changes): void {
    if (changes.onSearch.currentValue) {
      if (this.typeahead.nativeElement.value) {
        this.searchTypeaheadSubject.next('');
        this.hideOverlayPanel();
      }
    }
  }

  ngOnInit(): void {
    // Handle hide OverlayPanel when user make a click outside panel
    this.body$.subscribe(() => {
      if (this.isPanelShowed) {
        this.hideOverlayPanel();
      }
    });
    this.store.select('facetsSearch').subscribe(({ searchBy }) => {
      this.facets = searchBy;
    });
    this.store.select('routerReducer').subscribe(({ state }) => {
      const { url, params } = state;
      if (includes(url, 'search') && includes(url, 'coverage')) {
        const { id } = params;
        this.store.dispatch(actions.tabControl({ index: 1 }));
        this.runSearchForCoverageSection(id);
      }

      if (includes(url, 'collections')) {
        const { id, menuItem } = params;
        if (params) {
          this.handleSelectMenuItemCollection(menuItem, Number(id));
        }
      }

      if (includes(url, 'author')) {
        const { id } = params;
        this.runAuthorSearchForCoverageSection(id);
      }

      if (includes(url, 'outlet')) {
        const { id } = params;
        this.runOutletSearchForCoverageSection(id);
      }
    });

    this.store.select('search').subscribe(({ onReset, searchBody }) => {
      if (onReset) {
        this.resetTypeahed();
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
        // this.typeahead.nativeElement.value = null;
        // this.typeahead.nativeElement.placeholder = this.placeholderSearch;
      }
    });

    this.store
      .select('topics')
      .subscribe(({ topicMenuItemSelected, topicsMenu }) => {
        if (topicsMenu) {
          const topicsFilter =
            topicsMenu.filter(menu => !eq(menu.label, 'Collections')) || [];
          const collections =
            topicsMenu.filter(menu => eq(menu.label, 'Collections')) || [];

          if (collections.length > 0) {
            const collectionItems = map('items', collections);
            const collectionsSubItema = map('items', head(collectionItems));
            this.collectionMenuItems = flatMap(
              collectionsSubItema,
              item => item
            );
          }

          if (topicMenuItemSelected) {
            const prePlaceholder = this.typeahead.nativeElement.placeholder;
            const { label } = topicMenuItemSelected;
            const isCollectionFilter = !!this.collectionMenuItems.find(item =>
              eq(item.label, label)
            );

            const hasTypeaheadValue = this.selectedItem
              ? this.selectedItem.name
              : null;

            if (!hasTypeaheadValue) {
              if (eq(prePlaceholder, this.placeholderSearch)) {
                this.typeahead.nativeElement.placeholder = label;
              } else {
                const isTopicsFilter = !!topicsFilter.find(item =>
                  eq(item.label, prePlaceholder)
                );
                if (isTopicsFilter) {
                  this.typeahead.nativeElement.placeholder = label;
                } else {
                  const isPreCollectionFilter = !!this.collectionMenuItems.find(
                    item => eq(item.label, prePlaceholder)
                  );
                  if (isPreCollectionFilter) {
                    this.typeahead.nativeElement.placeholder = label;
                  }
                }
              }
            }

            if (isCollectionFilter) {
              this.typeahead.nativeElement.value = null;
              this.typeahead.nativeElement.placeholder = eq(label, 'All')
                ? this.placeholderSearch
                : label;
            }
          }
        }
      });

    this.store
      .select('typeaheadSearch')
      .subscribe(({ content, loading, loaded }) => {
        this.isLoading = loading;
        if (loaded) {
          if (!!content.length) {
            this.typeaheadResponseList = this.groupedFilter(content);
            this.showOverlayPanel();
          } else {
            this.hideOverlayPanel();
          }
        }
      });

    this.searchTypeaheadSubject
      .pipe(
        debounceTime(DEBOUNCE_TIME),
        distinctUntilChanged()
      )
      .subscribe(keywords => {
        this.getTypeaheadSearch(keywords);
      });
  }

  destroyAllTypeaheadSearch() {
    this.isLoading = false;
    this.typeaheadResponseList = [];
    this.hideOverlayPanel();
  }

  public resetTypeahed() {
    this.typeahead.nativeElement.value = null;
    this.typeahead.nativeElement.placeholder = `Search for a topic (e.g. IoT, "3D Printers", Microservices)`;
    this.typeaheadResponseList = [];
    this.selectedItem = null;
    this.inputTypeaheadValue = null;
    this.hideOverlayPanel();
  }

  public handleSimpleSearchOnEnter(e) {
    this.runSearchFromInput.emit(e);
    this.searchTypeaheadSubject.next('');
  }

  public onKeywordsChange(query) {
    this.inputTypeahead.emit(query);
    this.searchTypeaheadSubject.next(query);
    this.inputTypeaheadValue = query;
  }

  public getTypeaheadSearch(keywords) {
    this.store.dispatch(
      actions.loadTypeaheadSearch({
        keywords
      })
    );
  }
  public showOverlayPanel() {
    this.op.show(new MouseEvent('click'), this.typeahead.nativeElement);
    this.isPanelShowed = true;
  }

  public hideOverlayPanel() {
    this.op.hide();
    this.isPanelShowed = false;
  }

  public selectItemOnClick() {
    if (
      eq(this.selectedItem.type, TOPIC) ||
      eq(this.selectedItem.type, COMPANY) ||
      eq(this.selectedItem.type, EVENT)
    ) {
      this.runSearchTopicOrCompany(this.selectedItem.id);
    }
    if (eq(this.selectedItem.type, AUTHOR)) {
      // TODO: Open coverage section
      this.store.dispatch(actions.tabControl({ index: 1 }));
      // TODO: Hide outlet/author profile
      this.store.dispatch(actions.resetOutletProfile());
      this.store.dispatch(actions.resetTopicProfile());
      // TODO: Show outlet profile
      this.store.dispatch(
        actions.loadAuthorProfile({
          authorId: this.selectedItem.id
        })
      );

      const search = new Search({
        keywords: null,
        facets: { OUTLETS: null, COMPANIES: null, TOPICS: null },
        authors: [this.selectedItem.id],
        outlets: []
      });
      // TODO: Run search to content items on converage section
      this.store.dispatch(
        actions.setCoverages({
          searchBody: search,
          topicId: null,
          sort: 'recent',
          page: 0,
          size: 50,
          isSearch: true
        })
      );
      // TODO: Show facets table
      this.store.dispatch(
        actions.setFacetsSearch({
          searchBody: search,
          page: 0,
          isNewSearch: true
        })
      );
      this.store.dispatch(
        actions.setSearch({
          search: search
        })
      );
      const placeholder = `${this.selectedItem.name} (Author)`;
      this.placeholderSearch = placeholder;
      this.setPlaceholderValues(placeholder);

      // this.runAuthorOutletSearchFromTypeahead.emit(search);
      // const placeholder = `${this.selectedItem.name} (Author)`;
      // this.placeholderSearch = placeholder;
      // this.setPlaceholderValues(placeholder);
      // this.openDialog(AUTHOR, this.selectedItem, this.inputTypeaheadValue);
    }
    if (eq(this.selectedItem.type, OUTLET)) {
      // TODO: Open coverage section
      this.store.dispatch(actions.tabControl({ index: 1 }));
      // TODO: Hide author/outlet profile
      this.store.dispatch(actions.resetAuthorProfile());
      this.store.dispatch(actions.resetTopicProfile());
      // TODO: Show author profile
      this.store.dispatch(
        actions.loadOutletProfile({
          outletId: this.selectedItem.id
        })
      );
      const search = new Search({
        keywords: null,
        facets: { AUTHORS: null, COMPANIES: null, TOPICS: null },
        outlets: [this.selectedItem.id],
        authors: []
      });
      // TODO: Run search to content items on converage section
      this.store.dispatch(
        actions.setCoverages({
          searchBody: search,
          topicId: null,
          sort: 'recent',
          page: 0,
          size: 50,
          isSearch: true
        })
      );
      // TODO: Show facets table
      this.store.dispatch(
        actions.setFacetsSearch({
          searchBody: search,
          page: 0,
          isNewSearch: true
        })
      );
      this.store.dispatch(
        actions.setSearch({
          search: search
        })
      );
      const placeholder = `${this.selectedItem.name} (Outlet)`;
      this.placeholderSearch = placeholder;
      this.setPlaceholderValues(placeholder);
      // const search = new Search({
      //   keywords: null,
      //   facets: this.facets,
      //   outlets: [this.selectedItem.id]
      // });
      // this.runAuthorOutletSearchFromTypeahead.emit(search);
      // const placeholder = `${this.selectedItem.name} (Outlet)`;
      // this.placeholderSearch = placeholder;
      // this.setPlaceholderValues(placeholder);
      // this.openDialog(OUTLET, this.selectedItem, this.inputTypeaheadValue);
    }
    this.store.dispatch(
      actions.typeaheadItemSelected({
        itemSelected: this.selectedItem.name,
        facet: this.selectedItem.type
      })
    );
    this.op.hide();
  }

  public runSearchTopicOrCompany(id) {
    this.topicService.getTopicRelated(id).subscribe(response => {
      if (response) {
        const { name, query, implicitQuery } = response;
        const keywords = query ? query : implicitQuery ? implicitQuery : name;

        const search = new Search({
          keywords,
          facets: {
            OUTLETS: null,
            COMPANIES: null,
            AUTHORS: null,
            TOPICS: null
          }
        });
        const type = eq(this.selectedItem.type, COMPANY) ? 'Company' : 'Topic';
        const placeholder = `${this.selectedItem.name} (${type})`;
        this.placeholderSearch = placeholder;
        this.setPlaceholderValues(placeholder);
        // TODO: Open coverage section
        this.store.dispatch(actions.tabControl({ index: 1 }));

        // TODO: Hide outlet/author profile
        this.store.dispatch(actions.resetOutletProfile());
        this.store.dispatch(actions.resetAuthorProfile());

        // TODO: Show topic profile
        this.store.dispatch(
          actions.loadTopicProfile({
            topicId: id
          })
        );

        // TODO: Run search to content items on converage section
        this.store.dispatch(
          actions.setCoverages({
            searchBody: new Search({
              keywords
            }),
            topicId: null,
            sort: 'recent',
            page: 0,
            size: 50,
            isSearch: true
          })
        );

        // TODO: Show facets table
        this.store.dispatch(
          actions.setFacetsSearch({
            searchBody: search,
            page: 0,
            isNewSearch: true
          })
        );

        this.store.dispatch(
          actions.setSearch({
            search: search
          })
        );
        // this.runSearchFromTypeahead.emit(search);
        // if (eq(type, 'Company')) {
        //   this.openDialog(COMPANY, this.selectedItem, keywords);
        // } else {
        //   this.openDialog(TOPIC, this.selectedItem, keywords);
        // }
      }
    });
  }

  private setPlaceholderValues(placeholder) {
    this.typeahead.nativeElement.value = null;
    this.typeahead.nativeElement.placeholder = placeholder;
  }

  private groupedFilter(
    content: TypeaheadResponse[]
  ): TypeaheadSearchGrouped[] {
    const filtered: TypeaheadSearchGrouped[] = TYPEAHEAD_FILTER_GROUP;
    const topicList = [];
    const companyList = [];
    const authorList = [];
    const outletList = [];
    const eventsList = [];
    if (!!content.length) {
      content.forEach(item => {
        // Topic
        if (eq(item.type, 'TO') || eq(item.type, 'PET')) {
          topicList.push({ ...item, type: TOPIC });
        }
        // Company
        if (
          eq(item.type, 'VE') ||
          eq(item.type, 'VC') ||
          eq(item.type, 'PRT')
        ) {
          companyList.push({ ...item, type: COMPANY });
        }
        // Author
        if (eq(item.type, 'AU') || eq(item.type, 'AN')) {
          authorList.push({ ...item, type: AUTHOR });
        }
        // Outlet
        if (eq(item.type, 'CS')) {
          outletList.push({ ...item, type: OUTLET });
        }
        // Events & Awards
        if (eq(item.type, 'ES') || eq(item.type, 'AS')) {
          eventsList.push({ ...item, type: EVENT });
        }
      });
      filtered[0].items = companyList;
      filtered[1].items = topicList;
      filtered[2].items = authorList;
      filtered[3].items = outletList;
      // filtered[4].items = eventsList;
    }
    return filtered;
  }

  private openDialog(dialog, selectedItem, keywords) {
    this.store.dispatch(actions.startLoading());
    switch (dialog) {
      case AUTHOR:
        this.store.dispatch(
          actions.setAuthorDialog({
            authorId: selectedItem.id,
            projectSelected: this.currentProject,
            keywords: null,
            sort: 'recent'
          })
        );
        break;

      case OUTLET:
        this.store.dispatch(
          actions.setOutletDialog({
            outletId: selectedItem.id,
            projectSelected: this.currentProject,
            keywords: null,
            sort: 'recent'
          })
        );
        break;

      case TOPIC:
      case EVENT:
        this.store.dispatch(
          actions.setTopicDialog({
            topicSelected: new TopicResponse({ ...selectedItem }),
            projectSelected: this.currentProject,
            keywords,
            sort: RELEVANT_SORT,
            isSidebarTopic: false
          })
        );
        break;

      case COMPANY:
        this.store.dispatch(
          actions.setCompanyDialog({
            companyId: selectedItem.id,
            projectSelected: this.currentProject,
            keywords,
            sort: RELEVANT_SORT
          })
        );
        break;
    }
  }

  private runAuthorSearchForCoverageSection(id) {
    // TODO: Open coverage section
    this.store.dispatch(actions.tabControl({ index: 1 }));

    // TODO: Show outlet profile
    this.store.dispatch(
      actions.loadAuthorProfile({
        authorId: id
      })
    );

    // TODO: Run search to content items on converage section
    this.store.dispatch(
      actions.setCoverages({
        searchBody: new Search({
          keywords: null,
          authors: [id],
          outlets: []
        }),
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );

    // TODO: Show facets table
    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: new Search({
          keywords: null,
          facets: { OUTLETS: null, COMPANIES: null, TOPICS: null },
          authors: [id]
        }),
        page: 0,
        isNewSearch: true
      })
    );

    // const search = new Search({
    //   keywords: null,
    //   facets: this.facets,
    //   authors: [id]
    // });
    // this.runAuthorOutletSearchFromTypeahead.emit(search);
  }

  private runOutletSearchForCoverageSection(id) {
    // TODO: Open coverage section
    this.store.dispatch(actions.tabControl({ index: 1 }));

    // TODO: Show author profile
    this.store.dispatch(
      actions.loadOutletProfile({
        outletId: id
      })
    );

    // TODO: Run search to content items on converage section
    this.store.dispatch(
      actions.setCoverages({
        searchBody: new Search({
          keywords: null,
          outlets: [id],
          authors: []
        }),
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );

    // TODO: Show facets table
    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: new Search({
          facets: { AUTHORS: null, COMPANIES: null, TOPICS: null },
          outlets: [id]
        }),
        page: 0,
        isNewSearch: true
      })
    );
    // const search = new Search({
    //   keywords: null,
    //   facets: this.facets,
    //   outlets: [id]
    // });
    // this.runAuthorOutletSearchFromTypeahead.emit(search);
  }

  private runSearchForCoverageSection(id) {
    this.store.dispatch(actions.startLoading());
    this.topicService.getTopicWithContact(id).subscribe(
      response => {
        this.runTopicSearch(response);
        this.store.dispatch(
          actions.loadTopicProfile({
            topicId: id
          })
        );
      },
      topicWithContactError => {
        this.topicService.getUserTopic(id).subscribe(
          response => {
            this.runTopicSearch(response);
          },
          ({ error }) => {
            console.log(error);
          }
        );
      }
    );
  }

  runTopicSearch(response) {
    const { name, query, implicitQuery } = response;
    let keywords = '';
    if (query) {
      keywords = query;
    } else if (implicitQuery) {
      keywords = implicitQuery;
    } else {
      keywords = `\"${name}\"`;
    }

    this.typeahead.nativeElement.value = keywords;
    const newSearch = new Search({
      keywords,
      facets: this.facets,
      topicsCollection: null,
      topicsFilter: null
    });

    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );

    this.store.dispatch(
      actions.setCoverages({
        searchBody: newSearch,
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );

    setTimeout(() => {
      this.dispatchRankedTopicSarch(keywords);
      this.location.replaceState('/');
      this.store.dispatch(
        actions.setSearch({
          search: newSearch
        })
      );
    }, 1500);
  }

  public dispatchRankedTopicSarch(keywords) {
    const newSearch = new Search({
      keywords,
      facets: {
        TOPICS: 'relatedScore desc'
      }
    });

    this.store.dispatch(
      actions.setTopicsRanked({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );
  }

  handleSelectMenuItemCollection(option: string, id: number) {
    setTimeout(() => {
      const collectionSelected = this.getCollectionItem(id);
      const item = {
        label: collectionSelected.label || option,
        header: collectionSelected.label || option,
        description: collectionSelected.description || '',
        topicsCollection: id
      };

      this.store.dispatch(actions.startLoading());
      const newSearch = new Search({
        facets: { TOPICS: 'newsAuthorsWeek desc' },
        topicsFilter: null,
        topicsCollection: id
      });

      this.store.dispatch(
        actions.setSearch({
          search: newSearch
        })
      );
      this.store.dispatch(
        actions.setTopicsRanked({
          searchBody: newSearch,
          page: 0,
          isNewSearch: true
        })
      );
      this.store.dispatch(
        actions.setCollectionContent({
          page: 0,
          collectionId: id
        })
      );
      this.store.dispatch(actions.setTopicMenuItemSelected({ item }));
      this.store.dispatch(actions.tabControl({ index: 3 }));
    }, 1000);
  }

  getCollectionItem(id: number): SelectedMenuItem {
    const collection = this.collectionMenuItems.find(item =>
      eq(item.topicsCollection, id)
    );
    return new SelectedMenuItem({ ...collection });
  }
}
