import { ProjectsEffects } from './projects.effects';
import { TopicsEffects } from './topics.effects';
import { ToggleEmailEffects } from './toggleEmail.effects';
import { SearchEffects } from './search.effects';
import { CoveragesEffects } from './coverages.effects';
import { FacetsSearchEffects } from './facetsSearch.effects';
import { AuthorsEffects } from './authors.effects';
import { OutletsEffects } from './outlets.effects';
import { TopicsRankedEffects } from './topicsRanked.effects';
import { OffCanvasContentsEffects } from './offCanvasContents.effects';
import { CompaniesEffects } from './companies.effects';
import { UserDataEffects } from './userData.effects';
import { TopicDialogEffects } from './topicDialog.effects';
import { CompanyDialogEffects } from './companyDialog.effects';
import { AuthorDialogEffects } from './authorDialog.effects';
import { OutletDialogEffects } from './outletDialog.effects';
import { TypeaheadSearchEffects } from './typeaheadSearch.effects';
import { AuthorsOutletsListEffects } from './authorsOutletList.effects';
import { AuthorManagerEffects } from './authorManager.effects';
import { OutletManagerEffects } from './outletManager.effects';
import { CategoryDialogEffects } from './categoryDialog.effects';
import { ReportMisssingDataEffects } from './report-missing.data.effects';
import { NewsFeedEffects } from './new-feed.effects';
import { CollectionContentEffects } from './collectionContent.effects';
import { BlogEffects } from './blog.effects';
import { ContentItemsListEffects } from './contentItemsList.effects';
import { AuthorProfileEffects } from './authorProfile.effects';
import { OutletProfileEffects } from './outletProfile.effects';
import { TopicProfileEffects } from './topicProfile.effects';

export const EffectsArray: any[] = [
  ProjectsEffects,
  TopicsEffects,
  ToggleEmailEffects,
  CoveragesEffects,
  SearchEffects,
  FacetsSearchEffects,
  OffCanvasContentsEffects,
  AuthorsEffects,
  OutletsEffects,
  TopicsRankedEffects,
  CompaniesEffects,
  UserDataEffects,
  TopicDialogEffects,
  CompanyDialogEffects,
  AuthorDialogEffects,
  OutletDialogEffects,
  TypeaheadSearchEffects,
  AuthorsOutletsListEffects,
  AuthorManagerEffects,
  CategoryDialogEffects,
  OutletManagerEffects,
  ReportMisssingDataEffects,
  NewsFeedEffects,
  CollectionContentEffects,
  BlogEffects,
  ContentItemsListEffects,
  AuthorProfileEffects,
  OutletProfileEffects,
  TopicProfileEffects
];
