import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthorService } from 'app/services';
import { AuthorProfile } from 'app/models';
import { MAP_URL } from 'app/pages/topics/constants';

@Injectable()
export class AuthorProfileEffects {
  constructor(
    private actions$: Actions,
    private authorService: AuthorService
  ) {}

  loadPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadAuthorProfile),
      mergeMap(({ authorId }) =>
        this.getAuthorContactDetails$(authorId).pipe(
          switchMap(response => [
            actions.loadAuthorProfileSuccess({
              authorContactProfile: new AuthorProfile({
                id: response.id,
                name: response.name,
                emailAddress: response.contact.emailAddress,
                contactUrl: response.contact.contactUrl,
                phone: response.contact.phone,
                city: response.contact.city,
                state: response.contact.state,
                country: response.contact.country,
                twitter: response.contact.twitter,
                linkedIn: response.contact.linkedIn,
                facebook: response.contact.facebook,
                imageUrl: response.contact.imageUrl,
                twitterUrl: response.contact.twitterUrl,
                linkedInUrl: response.contact.linkedInUrl,
                linkedInSalesUrl: response.contact.linkedInSalesUrl,
                facebookUrl: response.contact.facebookUrl,
                streetAddress: response.contact.streetAddress,
                location: `${response.contact.city}, ${response.contact.state}`,
                locationUrl: response.contact.city
                  ? `${MAP_URL}/${
                      response.contact.streetAddress
                        ? response.contact.streetAddress
                        : ''
                    }, ${response.contact.city}, ${response.contact.state}`
                  : ''
              })
            })
          ]),
          catchError(err =>
            of(actions.loadAuthorProfileError({ payload: err }))
          )
        )
      )
    )
  );

  getAuthorContactDetails$(id): Observable<any> {
    return this.authorService.getAuthorById(id);
  }
}
