import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'app/redux/actions';
import { tap, mergeMap, switchMap, catchError } from 'rxjs/operators';
import {
  AuthenticationService,
  ProjectService,
  UserService
} from 'app/services';
import { of, Observable } from 'rxjs';
import { UserData } from 'app/models';
import { includes } from 'lodash';

@Injectable()
export class UserDataEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthenticationService,
    private userService: UserService,
    private store: Store<AppState>
  ) {}

  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setUserData),
      mergeMap(() =>
        this.getUserData$()
          .pipe(
            tap(data => console.log('loadUserData', data)),
            switchMap((data: any) => [
              actions.setUserDataSuccess({
                userData: new UserData({
                  ...data
                  // firstLogin: !data.firstLogin
                })
              }),
              actions.setProjects({ userId: data.userId })
            ]),
            catchError(err => of(actions.setUserDataError({ payload: err })))
          )
          .pipe(
            tap((response: any) => {
              if (response) {
                if (includes(response.type, 'setUserDataSuccess')) {
                  const { userData } = response;
                  if (userData) {
                    this.runRequiredLoginEp(userData.userId);
                    this.authService.saveUserId(userData.userId);
                    this.authService.saveUserData(userData);
                    this.getSSOTokens();
                  }
                }
              }
            })
          )
      )
    )
  );

  getUserData$(): Observable<any> {
    let userData$: Observable<any>;
    let response = null;
    userData$ = this.authService.getUserData$();
    userData$.subscribe((data: any) => (response = data));
    if (response) {
      return userData$;
    }
    return this.userService.getUserAccountProductsInfo();
  }

  runRequiredLoginEp(userId) {
    this.userService.login(userId).subscribe(
      response => {
        this.authService.saveUserAccountProductsInfo(response);
        /**
         * When all the user information is ready, only then will we show the dashboard
         */
        this.store.dispatch(
          actions.setIsUserLogged({ loginLoading: true, message: 'Success' })
        );
      },
      ({ error }) => {
        this.store.dispatch(
          actions.setIsUserLogged({ loginLoading: false, message: 'Error' })
        );
        console.log(`Potential issue here: ${error}`);
      }
    );
  }

  getSSOTokens() {
    this.authService.getSsoToken().subscribe(
      ({ value }) => {
        const ssoToken = value;
        this.authService.getLegacySsoToken().subscribe(
          // tslint:disable-next-line:no-shadowed-variable
          ({ value }) => {
            const legacySsoToken = value;
            this.authService.saveSSOTokens(ssoToken, legacySsoToken);
          },
          ({ error }) => {
            console.log(error);
          }
        );
      },
      ({ error }) => {
        console.log(error);
      }
    );
  }
}
