<div class="scroll-container">
  <div class="card border">
    <app-loading-topic></app-loading-topic>
    <p-table
      #topicsTable
      [value]="topics"
      styleClass="p-datatable-sm"
      selectionMode="single"
      [scrollable]="true"
      scrollHeight="calc(100vh - 360px)"
    >
      <ng-template pTemplate="header">
        <tr>
          <th rowspan="7">
            <button
              *ngIf="!hideFollowing"
              (click)="handleToggleMyTopics()"
              pButton
              type="button"
              [icon]="starToggle"
              class="p-button-secondary p-button-text"
            ></button>
          </th>
          <th
            rowspan="7"
            [style]="relevaceLabel ? { width: '13%' } : { width: '16%' }"
          >
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortNameIcon)"
              >Name
              <i id="sortNameIcon" class="pi pi-sort" #sortNameIcon></i>
            </span>
          </th>
          <th
            rowspan="7"
            [style]="relevaceLabel ? { width: '7.9%' } : { width: '0%' }"
          >
            <span
              role="button"
              *ngIf="relevaceLabel"
              href="javascript:;"
              (click)="sortChange(sortRelevanceIcon)"
            >
              {{ relevaceLabel }}
              <i
                id="sortRelevanceIcon"
                class="pi pi-sort"
                #sortRelevanceIcon
              ></i>
              <i
                class="m-l-10 pi pi-question-circle"
                pTooltip="How frequently this topic gets mentioned together with the search topic"
              ></i>
            </span>
          </th>
          <th colspan="3">Authors Past Week</th>
          <th colspan="3">Authors Past Month</th>
          <th colspan="3">Authors Past Year</th>
          <th rowspan="7">Coverage Past Year</th>
        </tr>
        <tr>
          <th>
            <span
              id="sortAuthorsWeekButton"
              role="button"
              href="javascript:;"
              (click)="sortChange(sortAuthorsWeekIcon)"
              >#
              <i
                id="sortAuthorsWeekIcon"
                class="pi pi-sort"
                #sortAuthorsWeekIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortWeekDeltaIcon)"
              >+/-
              <i
                id="sortWeekDeltaIcon"
                class="pi pi-sort"
                #sortWeekDeltaIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortWeekDeltaPercentIcon)"
              >%
              <i
                id="sortWeekDeltaPercentIcon"
                class="pi pi-sort"
                #sortWeekDeltaPercentIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortAuthorsMonthIcon)"
              >#
              <i
                id="sortAuthorsMonthIcon"
                class="pi pi-sort"
                #sortAuthorsMonthIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortMonthDeltaIcon)"
              >+/-
              <i
                id="sortMonthDeltaIcon"
                class="pi pi-sort"
                #sortMonthDeltaIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortMonthDeltaPercentIcon)"
              >%
              <i
                id="sortMonthDeltaPercentIcon"
                class="pi pi-sort"
                #sortMonthDeltaPercentIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortAuthorsYearIcon)"
              >#
              <i
                id="sortAuthorsYearIcon"
                class="pi pi-sort"
                #sortAuthorsYearIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortYearDeltaIcon)"
            >
              +/-
              <i
                id="sortYearDeltaIcon"
                class="pi pi-sort"
                #sortYearDeltaIcon
              ></i>
            </span>
          </th>
          <th>
            <span
              role="button"
              href="javascript:;"
              (click)="sortChange(sortYearDeltaPercentIcon)"
            >
              %
              <i
                id="sortYearDeltaPercentIcon"
                class="pi pi-sort"
                #sortYearDeltaPercentIcon
              ></i>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <app-not-records-found
          [visible]="topics.length === 0"
        ></app-not-records-found>
      </ng-template>
      <ng-template pTemplate="body" let-topic>
        <tr *ngIf="isLoadingContent">
          <td class="p-p-3"><p-skeleton></p-skeleton></td>
          <td
            class="p-p-3"
            [style]="relevaceLabel ? { width: '11.9%' } : { width: '16%' }"
          >
            <p-skeleton></p-skeleton>
          </td>
          <td
            class="p-p-3"
            [style]="relevaceLabel ? { width: '7.2%' } : { width: '0%' }"
            *ngIf="relevaceLabel"
          >
            <p-skeleton></p-skeleton>
          </td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
          <td class="skeleton-row"><p-skeleton></p-skeleton></td>
        </tr>
        <tr (click)="showTopicDialog(topic)" *ngIf="!isLoadingContent">
          <td>
            <button
              *ngIf="!hideFollowing"
              (click)="handleStarCheckOnClick(topic)"
              pButton
              type="button"
              [icon]="topic?.starIcon"
              class="p-button-secondary p-button-text"
            ></button>
          </td>
          <td
            [class]="sortColumn === 'name' ? 'sort-color' : ''"
            [style]="relevaceLabel ? { width: '11.7%' } : { width: '11%' }"
          >
            {{ topic.name }}
          </td>
          <td
            [class]="sortColumn === 'relatedScore' ? 'sort-color' : ''"
            [style]="relevaceLabel ? { width: '5.4%' } : { width: '0%' }"
          >
            <p-progressBar
              [style]="{ background: 'transparent' }"
              [showValue]="false"
              [value]="topic.percentage"
            ></p-progressBar>
          </td>
          <td
            [class]="
              sortColumn === 'newsAuthorsWeek'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>{{ topic.newsAuthorsWeek | number }}</span>
          </td>
          <td
            [class]="
              sortColumn === 'deltaWeek'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span
              >{{ topic.deltaWeek > 0 ? '+' : ''
              }}{{ topic.deltaWeek | number }}</span
            >
          </td>
          <td
            [class]="
              sortColumn === 'deltaWeekPercent'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>
              {{ topic.deltaWeekPercent > 0.000001 ? '+' : ''
              }}{{
                topic.deltaWeekPercent == 0.000001
                  ? '&infin;'
                  : (topic.deltaWeekPercent | percent)
              }}</span
            >
          </td>
          <td
            [class]="
              sortColumn === 'newsAuthorsMonth'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>{{ topic.newsAuthorsMonth | number }}</span>
          </td>
          <td
            [class]="
              sortColumn === 'deltaMonth'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span
              >{{ topic.deltaMonth > 0 ? '+' : ''
              }}{{ topic.deltaMonth | number }}</span
            >
          </td>
          <td
            [class]="
              sortColumn === 'deltaMonthPercent'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>
              {{ topic.deltaMonthPercent > 0.000001 ? '+' : ''
              }}{{
                topic.deltaMonthPercent == 0.000001
                  ? '&infin;'
                  : (topic.deltaMonthPercent | percent)
              }}
            </span>
          </td>
          <td
            [class]="
              sortColumn === 'newsAuthorsYear'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>{{ topic.newsAuthors | number }}</span>
          </td>
          <td
            [class]="
              sortColumn === 'deltaYear'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>
              {{ topic.deltaYear > 0 ? '+' : '' }}{{ topic.deltaYear | number }}
            </span>
          </td>
          <td
            [class]="
              sortColumn === 'deltaYearPercent'
                ? 'col-numarticle sort-color'
                : 'col-numarticle'
            "
          >
            <span>
              {{ topic.deltaYearPercent > 0.000001 ? '+' : ''
              }}{{
                topic.deltaYearPercent == 0.000001
                  ? '&infin;'
                  : (topic.deltaYearPercent | percent)
              }}
            </span>
          </td>
          <td [class]="sortColumn === 'longTrend' ? 'sort-color' : ''">
            <div id="chart-2" *ngIf="topic?.longTermChart">
              <app-long-term-chart
                [topic]="topic.longTermChart"
              ></app-long-term-chart>
            </div>
          </td>
        </tr>
      </ng-template>
      <!-- <ng-template pTemplate="footer">
        <tr>
          <td colspan="3">
            <p-paginator
              *ngIf="!!topics.length"
              #p
              [showCurrentPageReport]="false"
              currentPageReportTemplate="Showing {{ page }} of {{
                totalPages
              }} pages"
              [pageLinkSize]="3"
              [showFirstLastIcon]="false"
              (onPageChange)="getPage($event)"
              [rows]="pageSize"
              [totalRecords]="totalItems"
            >
            </p-paginator>
          </td>
        </tr>
      </ng-template> -->
    </p-table>
    <div class="p-d-flex p-jc-between topics-footer">
      <div>
        <p-paginator
          *ngIf="totalPages >= 2 && !isLoadingContent"
          #p
          [showCurrentPageReport]="false"
          currentPageReportTemplate="Showing {{ page }} of {{
            totalPages
          }} pages"
          [pageLinkSize]="3"
          [showFirstLastIcon]="false"
          (onPageChange)="getPage($event)"
          [rows]="pageSize"
          [totalRecords]="totalItems"
        >
        </p-paginator>
      </div>
    </div>
  </div>
</div>
