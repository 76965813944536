import { EventEmitter, Injectable, Output } from '@angular/core';
import { isNil, isEmpty, eq } from 'lodash';
import { Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserData, UserProfile } from 'app/models';
import { Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private technews = environment.technews;
  private storage = localStorage;
  private api: string;
  private auhtApi: string;
  private httpOptions: any;

  constructor(private http: HttpClient, private router: Router) {
    this.api = environment.api_url;
    this.auhtApi = environment.auth_url;
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
  }

  isProduction(): boolean {
    return environment.production;
  }

  saveUserAccountProductsInfo(userInfo) {
    if (userInfo) {
      this.clearUserData();
      this.saveName(userInfo.name);
      this.saveUsername(userInfo.userName);
      this.saveAccountId(userInfo.accountId);
      this.saveAccount(userInfo.accountName);
      this.saveAccountType(userInfo.organizationType);
      if (userInfo.paymentsUrl !== null && userInfo.paymentsUrl !== undefined) {
        this.savePaymentsUrl(userInfo.paymentsUrl);
      }
      if (
        userInfo.trialActivityExceeded !== null &&
        userInfo.trialActivityExceeded !== undefined
      ) {
        this.saveTrialActivityExceeded(userInfo.trialActivityExceeded);
      }
      if (userInfo.products) {
        for (const productAccess of userInfo.products) {
          switch (productAccess.product) {
            case 'TOPICS':
              this.saveTrial(productAccess.trial);
              this.saveSubscriber(productAccess.subscriber);
              this.saveExpiration(productAccess.expiration);
              break;
            case 'NEWS':
              this.saveTechNewsJsAccess(true);
              break;
          }
        }
      }
    }
  }

  clearUserData() {
    this.storage.removeItem('name');
    this.storage.removeItem('username');
    this.storage.removeItem('account');
    this.storage.removeItem('accountType');
    this.storage.removeItem('pic');
    this.storage.removeItem('trial');
    this.storage.removeItem('subscriber');
    this.storage.removeItem('expirationDate');
    this.storage.removeItem('maxProjects');
    this.storage.removeItem('paymentsUrl');
    this.storage.removeItem('showProductTour');
    this.storage.removeItem('techNewsJs');
    this.storage.removeItem('trialActivityExceeded');
    this.storage.removeItem('redirectUrl');
  }

  public goAuth0Login(
    state: RouterStateSnapshot = null,
    callback: string = null
  ) {
    const url = state ? state.url : null;
    if (eq(callback, 'logout')) {
      // With this navigation avoid to redirect out of the application
      this.clearStorage();
      this.router.navigate(['auth/logout']);
    } else {
      if (eq(url, '/')) {
        // this.initializeLogin();
        this.router.navigate(['auth/login']);
      } else {
        this.clearStorage();
        this.router.navigate(['auth/login'], {
          queryParams: { redirectUrl: url }
        });
      }
    }
  }

  public goPrevUrl(state: RouterStateSnapshot = null) {
    this.router.navigate(['/']);
  }

  public loginWithTechnews(user) {
    return this.http.post(
      `${this.auhtApi}/login/password`,
      user,
      this.httpOptions
    );
  }

  public loginWithAuth0(userProfile: UserProfile) {
    return this.http.post(
      `${this.auhtApi}/login/oauth${
        userProfile.createTrial ? '/newtrial' : ''
      }`,
      userProfile,
      this.httpOptions
    );
  }

  public forgotPassword(emailAddress: string) {
    return this.http.post(
      `${this.api}/email/forgetPassword`,
      { emailAddress },
      this.httpOptions
    );
  }

  public resetPassword(u: string, p: string, newPassword: string) {
    return this.http.post(
      `${this.api}/reset-password?u=${encodeURIComponent(
        u
      )}&p=${encodeURIComponent(p)}`,
      { newPassword },
      this.httpOptions
    );
  }

  public getPricing(): Observable<any> {
    return this.http.get(`${this.api}/pricing`, this.httpOptions);
  }

  public initializeLogin() {
    this.clearStorage();
    window.open(environment.public_url, '_self');
  }

  private goTechNewsjs() {
    window.open(`${this.technews}`, '_self');
  }

  public logOut() {
    this.clearStorage();
  }

  public isAuthenticated() {
    return !isNil(this.getToken());
  }

  getSsoToken(): Observable<any> {
    return this.http.get(
      `${this.api}/user/${this.getUserId()}/ssoToken?legacy=false`,
      this.httpOptions
    );
  }

  getLegacySsoToken(): Observable<any> {
    return this.http.get(
      `${this.api}/user/${this.getUserId()}/ssoToken?legacy=true`,
      this.httpOptions
    );
  }

  public getRedirectUrl() {
    return this.storage.getItem('redirectUrl');
  }

  public setRedirectUrl(redirectUrl) {
    this.storage.setItem('redirectUrl', redirectUrl);
  }

  public getToken() {
    return this.storage.getItem('token');
  }

  public saveToken(token) {
    this.storage.setItem('token', token);
  }

  public getSSOToken() {
    return this.storage.getItem('ssoToken');
  }

  public getLegacySSOToken() {
    return this.storage.getItem('legacySsoToken');
  }

  public saveSSOTokens(ssoToken, legacySsoToken) {
    this.storage.setItem('ssoToken', ssoToken);
    this.storage.setItem('legacySsoToken', legacySsoToken);
  }

  public saveName(name) {
    this.storage.setItem('name', name);
  }

  public getName() {
    return this.storage.getItem('name');
  }

  public saveUsername(user) {
    this.storage.setItem('username', user);
  }

  public getUsername() {
    return this.storage.getItem('username');
  }

  public saveAccount(account) {
    this.storage.setItem('account', account);
  }

  public getAccount() {
    return this.storage.getItem('account');
  }

  public saveAccountType(accountType) {
    this.storage.setItem('accountType', accountType);
  }

  public getAccountType() {
    return this.storage.getItem('accountType');
  }

  public saveFirstLogin(firstLogin) {
    this.storage.removeItem('firstLogin');
    this.storage.setItem('firstLogin', firstLogin);
  }

  public getFirstLogin() {
    const first = this.storage.getItem('firstLogin');
    return isEmpty(first) ? true : JSON.parse(first);
  }

  public showBlogAfterLogin() {
    const open = this.storage.getItem('openBlog');
    let checkOpenBlog = isEmpty(open) ? true : JSON.parse(open);
    if (!environment.openBlog) {
      checkOpenBlog = false;
    }
    return checkOpenBlog;
  }

  public saveBlogAfterLogin(openBlog) {
    this.storage.setItem('openBlog', openBlog);
  }

  public saveAccountId(id) {
    this.storage.setItem('accountId', id);
  }

  public getAccountId() {
    return this.storage.getItem('accountId');
  }

  public saveUserId(id) {
    this.storage.setItem('userId', id);
  }

  public saveUserData(userData: UserData) {
    this.storage.setItem('userData', JSON.stringify(userData));
  }

  public getUserData$(): Observable<any> {
    const userData = this.storage.getItem('userData');
    return isEmpty(userData) ? of(null) : of(JSON.parse(userData));
  }

  public setLoginRender(value) {
    this.storage.setItem('loginRender', JSON.stringify(value));
  }

  public isLoginRender(): boolean {
    const value = this.storage.getItem('loginRender');
    return isEmpty(value) ? false : JSON.parse(value);
  }

  public getUserId() {
    return this.storage.getItem('userId');
  }

  public saveUserPic(pic) {
    this.storage.setItem('pic', pic);
  }

  public getUserPic() {
    return this.storage.getItem('pic');
  }

  public saveTrial(trial: boolean = false) {
    this.storage.setItem('trial', JSON.stringify(trial));
  }

  public isTrial(): boolean {
    const trial = JSON.parse(this.storage.getItem('trial'));
    return eq(trial, true);
  }

  public saveTrialActivityExceeded(trialActivityExceeded: boolean = false) {
    this.storage.setItem(
      'trialActivityExceeded',
      JSON.stringify(trialActivityExceeded)
    );
  }

  public isTrialActivityExceeded(): boolean {
    const trialActivityExceeded = JSON.parse(
      this.storage.getItem('trialActivityExceeded')
    );
    return eq(trialActivityExceeded, true);
  }

  public saveSubscriber(subscriber: boolean = false) {
    this.storage.setItem('subscriber', JSON.stringify(subscriber));
  }

  public isSubscriber(): boolean {
    const subscriber = JSON.parse(this.storage.getItem('subscriber'));
    return eq(subscriber, true);
  }

  public saveExpiration(expirationDate) {
    this.storage.setItem('expirationDate', expirationDate);
  }

  public getExpiration() {
    return this.storage.getItem('expirationDate');
  }

  public saveMaxProjects(maxProjects) {
    this.storage.setItem('maxProjects', maxProjects);
  }

  public getMaxProjects() {
    return this.storage.getItem('maxProjects');
  }

  public saveProductTour(showProductTour: boolean = false) {
    this.storage.setItem('showProductTour', JSON.stringify(showProductTour));
  }

  public showProductTour(): boolean {
    const showProductTour = JSON.parse(this.storage.getItem('showProductTour'));
    return eq(showProductTour, true);
  }

  public saveTechNewsJsAccess(hasAccess: boolean = false) {
    this.storage.setItem('techNewsJs', JSON.stringify(hasAccess));
  }

  public hasTechNewsJsAccess(): boolean {
    const hasAccess = JSON.parse(this.storage.getItem('techNewsJs'));
    return eq(hasAccess, true);
  }

  getCheckoutUrl(
    annualSubscription: boolean,
    numUsers: number
  ): Observable<any> {
    return this.http.get(
      `${this.api}/user/${this.getUserId()}/account/checkout/` +
        (annualSubscription ? `yearly` : `monthly`) +
        `?numUsers=${numUsers}`,
      this.httpOptions
    );
  }

  public getPaymentsUrl() {
    return this.storage.getItem('paymentsUrl');
  }

  public savePaymentsUrl(token) {
    this.storage.setItem('paymentsUrl', token);
  }

  public clearStorage() {
    this.storage.clear();
  }

  public clearKeyStorage(key: string) {
    localStorage.removeItem(key);
  }

  public getSearchUrl(ssoToken) {
    return `${environment.search_url}&ssoToken=${encodeURIComponent(ssoToken)}`;
  }

  public getLogoutUrl() {
    return `${environment.logout_url}`;
  }

  public getMyAccountUrl(ssoToken) {
    return `${environment.myAccount_url}&ssoToken=${encodeURIComponent(
      ssoToken
    )}`;
  }

  public getListUrl(ssoToken) {
    return `${environment.list_url}&ssoToken=${encodeURIComponent(ssoToken)}`;
  }

  public getChartsUrl(ssoToken) {
    return `${environment.charts_url}&ssoToken=${encodeURIComponent(ssoToken)}`;
  }

  public getEventsUrl(ssoToken) {
    return `${environment.events_url}?ssoToken=${encodeURIComponent(ssoToken)}`;
  }

  public getHelpUrl() {
    return `${environment.help_url}`;
  }

  public getProjectLabel(plural = false) {
    switch (this.getAccountType()) {
      case 'PR':
        return plural ? 'Clients' : 'Client';
      case 'VC':
        return plural ? 'Portfolio Companies' : 'Portfolio Company';
      default:
        return plural ? 'Projects' : 'Project';
    }
  }

  public getUserProfile() {
    const user = this.storage.getItem('userProfile');
    return isEmpty(user) ? null : JSON.parse(user);
  }

  public saveUserProfile(userProfile: UserProfile) {
    this.storage.setItem('userProfile', JSON.stringify(userProfile));
  }
}
