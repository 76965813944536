import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { update } from 'lodash/fp';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import { get, eq, isEmpty, includes, orderBy, split } from 'lodash';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Search, TopicList, TopicResponse } from 'app/models';
import { Notification } from 'app/models';
import { ToastNotificationService } from 'app/components/toast-notification/toast-notification.service';
import { Paginator } from 'primeng/paginator';
import {
  RECENT_SORT,
  RELEVANT_SORT,
  STAR_ICON,
  STAR_ICON_FILL,
  SPINNER_ICON,
  TOPIC_ITEMS
} from 'app/constants';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-mini-topics-table',
  templateUrl: './mini-topics-table.component.html',
  styleUrls: ['./mini-topics-table.component.scss']
})
export class MiniTopicsTableComponent implements OnInit, OnChanges {
  public topics: TopicList[];
  public topicsSavedList: any[];
  public page: number;
  public totalPages: number;
  public pageSize: number;
  public totalItems: number;
  public sort: string;
  public config: PerfectScrollbarConfigInterface = {};
  public topicSelected: any;
  public starIcon: string;
  public starCheckbox: boolean;
  public isClickOnstar: boolean;
  public project: any;
  public keywords: string;
  public hideFollowing: boolean;
  public loading: boolean;
  public loaded: boolean;
  public sortColumn: string;
  public isNewSearch: boolean;
  public showMyTopics: boolean;
  public currentPage: number;
  public searchBy: any;
  public sortIcon: string;
  public starToggle: string;
  public search: Search;
  public ramdomArray: any[];
  public relevaceLabel: string;
  public prevIconAfterLoading: string;
  public screenSize: number;
  public sliceControl: number;
  public visibleEllipses: boolean;
  public showPageButton: boolean;

  @ViewChild('rankedListPerfectScroll', { static: false })
  rankedListPerfectScroll: PerfectScrollbarComponent;

  @Input() topicsObj;
  @Input() topicsList: any[];

  @ViewChildren(
    // tslint:disable-next-line:max-line-length
    'sortNameIcon, sortRelevanceIcon, sortAuthorsWeekIcon, sortWeekDeltaIcon, sortWeekDeltaPercentIcon, sortAuthorsMonthIcon, sortMonthDeltaIcon, sortMonthDeltaPercentIcon, sortAuthorsYearIcon, sortYearDeltaIcon, sortYearDeltaPercentIcon',
    {
      read: ElementRef
    }
  )
  sortIcons: QueryList<ElementRef>;

  @ViewChild('p', { static: false }) paginator: Paginator;
  @ViewChild('topicsTable', { static: false }) table: Table;
  constructor(
    private store: Store<AppState>,
    private notificationService: ToastNotificationService
  ) {
    this.prevIconAfterLoading = null;
    this.currentPage = 0;
    this.page = 1;
    this.totalPages = 0;
    this.pageSize = 50;
    this.totalItems = 0;
    this.isClickOnstar = false;
    this.keywords = null;
    this.loading = true;
    this.loaded = false;
    this.isNewSearch = false;
    this.sortIcon = 'pi pi-sort';
    this.starToggle = STAR_ICON;
    this.showMyTopics = false;
    this.topics = TOPIC_ITEMS;
    this.relevaceLabel = null;
    this.topicsSavedList = [];
    this.visibleEllipses = true;
    this.sliceControl = 9;
    this.showPageButton = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.topicsObj) {
      const {
        topicsRankedList,
        searchBody,
        isNewSearch,
        loaded,
        loading,
        error
      } = changes.topicsObj.currentValue;
      if (error) {
        const { status } = error;
        this.notificationService.showMessage(
          `Error ${status} searching topic query: ${searchBody.keywords}`,
          'Error'
        );
        this.store.dispatch(actions.stopLoading());
      }
      setTimeout(() => {
        this.topicsData(topicsRankedList.list);
        this.totalPages = topicsRankedList.totalPages;
        this.pageSize = topicsRankedList.size;
        this.totalItems = topicsRankedList.totalElements;
        this.keywords = searchBody.keywords;
        this.loading = loading;
        this.loaded = loaded;
        this.search = searchBody;
        if (loaded) {
          this.isNewSearch = isNewSearch;
          const pageNumber = topicsRankedList.number;
          this.resetPaginatorPage(pageNumber);
          this.setColumnSort(searchBody.facets);
          this.store.dispatch(actions.stopLoading());
        }
      }, 1000);
    }

    if (changes.topicsList) {
      this.topicsSavedList = changes.topicsList.currentValue || [];
    }
  }

  ngOnInit() {
    this.hideFollowing = false;
    this.store
      .select('topics')
      .subscribe(({ addTopicToProjectError, removeTopicToProjectError }) => {
        // handle error on add/remove topic to project
        if (addTopicToProjectError) {
          const { message, status, topic } = addTopicToProjectError;
          this.store.dispatch(
            actions.showNotification({
              notify: new Notification(
                `${status}: Error adding topic to project.`,
                'error',
                'tl'
              )
            })
          );
          setTimeout(() => {
            const index = this.topics.findIndex(t => eq(t.id, topic.id));
            this.topics[index].isSaved = false;
            this.topics[index].starIcon = STAR_ICON;
          }, 1000);
        }

        if (removeTopicToProjectError) {
          const { message, status, topic } = removeTopicToProjectError;
          this.store.dispatch(
            actions.showNotification({
              notify: new Notification(
                `${status}: Error removing topic to project.`,
                'error',
                'tl'
              )
            })
          );
          setTimeout(() => {
            const index = this.topics.findIndex(t => t.id === topic.id);
            this.topics[index].isSaved = true;
            this.topics[index].starIcon = STAR_ICON_FILL;
          }, 1000);
        }
      });

    this.store
      .select('topicDialog')
      .subscribe(({ topicSelected, loading, loaded, isSidebarTopic }) => {
        if (!isSidebarTopic) {
          if (topicSelected) {
            this.loadingTopicDialog(topicSelected.id, loading, loaded);
          }
        }
      });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });
    this.store.select('search').subscribe(({ searchBody }) => {
      this.relevaceLabel = searchBody.keywords ? 'Related' : null;
    });

    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      this.screenSize = screenSizeValues.scrWidth;
      // if (this.screenSize <= 1710) {
      //   this.sliceControl = 10;
      //   this.visibleEllipses = false;
      //   if (this.screenSize < 1500) {
      //     this.sliceControl = 5;
      //   }
      // } else {
      //   this.visibleEllipses = true;
      // }

      if (this.screenSize >= 1500) {
        this.showPageButton = true;
        this.visibleEllipses = true;
      } else {
        this.showPageButton = false;
        this.visibleEllipses = false;
      }
    });
  }

  resetPaginatorPage(pageNumber: any) {
    if (this.isNewSearch && this.page > 1 && eq(pageNumber, 0)) {
      this.paginator.changePage(0);
    }
  }

  handleToggleMyTopics() {
    this.showMyTopics = !this.showMyTopics;
    this.starToggle = this.showMyTopics ? STAR_ICON_FILL : STAR_ICON;
    if (this.showMyTopics) {
      this.handleDispatchMyTopics();
    } else {
      this.handleDispatchMyTopics();
    }
  }

  handleDispatchMyTopics() {
    /* Reset topics menubar*/
    const item = {
      label: 'All',
      items: null
    };
    this.store.dispatch(actions.setTopicMenuItemSelected({ item }));

    const newSearch = new Search({
      ...this.search,
      topicsFilter: null,
      topicsCollection: this.showMyTopics ? this.project.value : null
    });

    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    setTimeout(() => {
      this.store.dispatch(
        actions.setTopicsRanked({
          searchBody: newSearch,
          page: 0,
          isNewSearch: false
        })
      );
    }, 500);
  }

  goTopicSearch() {
    this.store.dispatch(actions.tabControl({ index: 0 }));
    this.store.dispatch(
      actions.headerMenuActive({
        menuActive: 'Topics'
      })
    );
  }

  handleSwitchStarIcon(topic) {
    const index = this.topics.findIndex(t => eq(t.id, topic.id));
    const isSaved = this.topics[index].isSaved;
    this.topics[index].isSaved = isSaved;
    this.topics[index].starIcon = isSaved ? STAR_ICON : STAR_ICON_FILL;
    return isSaved;
  }

  handleStarCheckOnClick(topic) {
    this.isClickOnstar = !this.isClickOnstar;
    const isSaved = this.handleSwitchStarIcon(topic);
    if (isSaved) {
      this.store.dispatch(
        actions.deleteTopicToProject({
          topic,
          userProject: this.project
        })
      );
    } else {
      this.store.dispatch(
        actions.addTopicToProject({
          topic,
          userProject: this.project
        })
      );
    }
  }

  public topicsData(topics) {
    if (!!topics.length) {
      this.topics = topics;
      this.updateTopicsRankedList(this.topicsSavedList);
      this.table.scrollTo({ top: 0 });
    } else {
      this.topics = [];
    }
  }

  updateTopicsRankedList(arrList) {
    this.topics = this.topics.map(item => {
      return {
        ...item,
        starIcon: arrList.find(li => eq(li.name, item.name))
          ? STAR_ICON_FILL
          : STAR_ICON,
        isSaved: arrList.find(li => eq(li.name, item.name)) ? true : false
      };
    });
  }

  showTopicDialog(t) {
    if (!this.isClickOnstar) {
      this.isClickOnstar = false;
      this.prevIconAfterLoading = t.starIcon;
      const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
      this.store.dispatch(
        actions.setTopicDialog({
          topicSelected: t,
          projectSelected: this.project,
          keywords: this.keywords,
          sort,
          isSidebarTopic: false
        })
      );
    } else {
      this.isClickOnstar = !this.isClickOnstar;
    }
  }

  loadingTopicDialog(topicId, loading, loaded) {
    const index = this.topics.findIndex(({ id }) => eq(id, topicId));
    let updateTopics: TopicResponse[] = [];
    if (loading) {
      updateTopics = update(
        index,
        topic => ({ ...topic, starIcon: SPINNER_ICON }),
        this.topics
      );
    }
    if (loaded) {
      updateTopics = update(
        index,
        topic => ({ ...topic, starIcon: this.prevIconAfterLoading }),
        this.topics
      );
    }
    this.topics = updateTopics;
  }

  getPage(e: any) {
    this.currentPage = e.page;
    this.page = e.page + 1;
    this.store.dispatch(actions.startLoading());

    setTimeout(() => {
      this.store.dispatch(
        actions.setTopicsRanked({
          searchBody: this.search,
          page: e.page,
          isNewSearch: false
        })
      );
    }, 500);
  }

  setColumnSort(sortBy: any) {
    if (sortBy.TOPICS) {
      const sort = split(sortBy.TOPICS, ' ');
      const column = sort[0];
      const sortOrientation = sort[1];
      let sortIcon; // = 'sortWeekDeltaIcon';
      this.sort = sortBy.TOPICS;

      switch (column) {
        case 'name':
          this.sortColumn = 'name';
          sortIcon = 'sortNameIcon';
          break;
        case 'relatedScore':
          this.sortColumn = 'relatedScore';
          sortIcon = 'sortRelevanceIcon';
          break;

        case 'newsAuthorsWeek':
          this.sortColumn = 'newsAuthorsWeek';
          sortIcon = 'sortAuthorsWeekIcon';
          break;

        case 'deltaWeek':
          this.sortColumn = 'deltaWeek';
          sortIcon = 'sortWeekDeltaIcon';
          break;

        case 'deltaWeekPercent':
          this.sortColumn = 'deltaWeekPercent';
          sortIcon = 'sortWeekDeltaPercentIcon';
          break;

        case 'newsAuthorsMonth':
          this.sortColumn = 'newsAuthorsMonth';
          sortIcon = 'sortAuthorsMonthIcon';
          break;

        case 'deltaMonth':
          this.sortColumn = 'deltaMonth';
          sortIcon = 'sortMonthDeltaIcon';
          break;

        case 'deltaMonthPercent':
          this.sortColumn = 'deltaMonthPercent';
          sortIcon = 'sortMonthDeltaPercentIcon';
          break;

        case 'newsAuthors':
          this.sortColumn = 'newsAuthorsYear';
          sortIcon = 'sortAuthorsYearIcon';
          break;

        case 'deltaYear':
          this.sortColumn = 'deltaYear';
          sortIcon = 'sortYearDeltaIcon';
          break;

        case 'deltaYearPercent':
          this.sortColumn = 'deltaYearPercent';
          sortIcon = 'sortYearDeltaPercentIcon';
          break;
      }

      setTimeout(() => {
        this.sortIcons.forEach(({ nativeElement }) => {
          if (eq(nativeElement.id, sortIcon)) {
            nativeElement.className = eq(sortOrientation, 'desc')
              ? 'pi pi-sort-down'
              : 'pi pi-sort-up';
          } else {
            nativeElement.className = 'pi pi-sort';
          }
        });
      }, 800);
    } else {
      this.sortColumn = null;
      this.sort = null;
    }
  }

  sortChange(icon: any) {
    // this.isSorting = true;
    const defaultSortIcon = 'pi pi-sort-down';
    const selectedSortIcon = 'pi pi-sort-down';
    this.sortIcons.forEach(({ nativeElement }) => {
      if (eq(nativeElement, icon)) {
        if (eq(icon.id, 'sortNameIcon')) {
          icon.className = eq(icon.className, 'pi pi-sort')
            ? 'pi pi-sort-up'
            : eq(icon.className, 'pi pi-sort-up')
            ? 'pi pi-sort-down'
            : 'pi pi-sort-up';
        } else {
          icon.className = eq(icon.className, 'pi pi-sort')
            ? 'pi pi-sort-down'
            : eq(icon.className, 'pi pi-sort-up')
            ? 'pi pi-sort-down'
            : 'pi pi-sort-up';
        }
      } else {
        nativeElement.className = 'pi pi-sort';
      }
    });
    switch (icon.id) {
      case 'sortNameIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'name asc'
          : 'name desc';
        this.sortColumn = 'name';
        break;

      case 'sortRelevanceIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'relatedScore asc'
          : 'relatedScore desc';
        this.sortColumn = 'relatedScore';
        break;

      case 'sortAuthorsWeekIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'newsAuthorsWeek asc'
          : 'newsAuthorsWeek desc';
        this.sortColumn = 'newsAuthorsWeek';
        break;

      case 'sortWeekDeltaIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'deltaWeek asc'
          : 'deltaWeek desc';
        this.sortColumn = 'deltaWeek';
        break;

      case 'sortWeekDeltaPercentIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'deltaWeekPercent asc'
          : 'deltaWeekPercent desc';
        this.sortColumn = 'deltaWeekPercent';
        break;

      case 'sortAuthorsMonthIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'newsAuthorsMonth asc'
          : 'newsAuthorsMonth desc';
        this.sortColumn = 'newsAuthorsMonth';
        break;

      case 'sortMonthDeltaIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'deltaMonth asc'
          : 'deltaMonth desc';
        this.sortColumn = 'deltaMonth';
        break;

      case 'sortAuthorsYearIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'newsAuthors asc'
          : 'newsAuthors desc';
        this.sortColumn = 'newsAuthorsYear';
        break;

      case 'sortMonthDeltaPercentIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'deltaMonthPercent asc'
          : 'deltaMonthPercent desc';
        this.sortColumn = 'deltaMonthPercent';
        break;

      case 'sortYearDeltaIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'deltaYear asc'
          : 'deltaYear desc';
        this.sortColumn = 'deltaYear';
        break;

      case 'sortYearDeltaPercentIcon':
        this.sort = eq(icon.className, 'pi pi-sort-up')
          ? 'deltaYearPercent asc'
          : 'deltaYearPercent desc';
        this.sortColumn = 'deltaYearPercent';
        break;

      default:
        // this.sort =
        //   icon.className === 'pi pi-sort-up'
        //     ? 'deltaWeekPercent asc'
        //     : 'deltaWeekPercent desc';
        this.sortColumn = '';
        break;
    }

    this.store.dispatch(actions.startLoading());
    const newSearch = new Search({
      ...this.search,
      facets: { TOPICS: this.sort }
    });

    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    setTimeout(() => {
      this.store.dispatch(
        actions.setTopicsRanked({
          searchBody: newSearch,
          page: 0,
          isNewSearch: true
        })
      );
    }, 500);
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
