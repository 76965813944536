import { Component, OnInit, ViewChild } from '@angular/core';
import { Blog } from 'app/models';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { removeContent, showNotification, addContent } from 'redux/actions';
import {
  PerfectScrollbarComponent,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { ToastNotificationService } from 'app/components';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public posts: Blog[];

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild('blogContentPerfectScroll', { static: false })
  blogContentPerfectScroll: PerfectScrollbarComponent;

  constructor(
    private store: Store<AppState>,
    private notificationService: ToastNotificationService
  ) {
    this.posts = [];
  }

  ngOnInit(): void {
    this.store.select('blog').subscribe(({ posts, error }) => {
      this.posts = posts;
      if (error) {
        this.notificationService.showMessage('Error fetching posts', 'Error');
      }
    });
  }
}
