<div class="media m-t-1">
  <div class="media-body">
    <div class="p-d-flex p-jc-between">
      <!-- Header title -->
      <div>
        <div class="p-d-flex">
          <div class="p-mr-2">
            <h2 id="authorTitle" class="mt-4">{{ author.name }}</h2>
          </div>
          <div class="p-mr-2 p-mt-4" *ngIf="!hideFollowing">
            <button
              (click)="handleSaveAuthor(author)"
              pButton
              pRipple
              type="button"
              [icon]="starToggle"
              class="p-button-rounded p-button-secondary p-button-text"
            ></button>
          </div>
        </div>
        <!-- Outlet associated -->
        <ul
          class="nav customizer-tab p-mb-4"
          style="padding: 0px; background: rgb(255, 255, 255);"
        >
          <li>
            <div class="row">
              <div class="col">
                <h5>
                  <a
                    href="javascript:;"
                    role="button"
                    (click)="onDisplayOutletContent(author.outletId)"
                    >{{ author.outlet }}</a
                  >
                </h5>
              </div>
            </div>
          </li>
        </ul>
        <!-- Social media contacts -->
        <ul
          class="nav customizer-tab"
          style="padding: 0px; background: rgb(255, 255, 255);"
        >
          <div class="row align-items-end">
            <li *ngIf="author.emailAddress && author.emailAddress != 'cbf'">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a
                    href="mailto:{{ author.emailAddress }}"
                    [target]="'_blank'"
                  >
                    <i class="pi pi-envelope font-17 m-r-3"></i>
                    {{ author.emailAddress }}
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="author?.contactUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="author?.contactUrl" [target]="'_blank'">
                    <i class="pi pi-globe font-17 m-r-3"></i>
                    Contact
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="author.twitter && author.twitter != 'cbf'">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="author.twitter" [target]="'_blank'">
                    <i class="pi pi-twitter font-17 m-r-3"></i>
                    X
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="author.linkedIn && author.linkedIn != 'cbf'">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="author.linkedIn" [target]="'_blank'">
                    <i class="pi pi-linkedin font-17 m-r-3"></i>
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="author.locationUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="author.locationUrl" [target]="'_blank'">
                    <i class="pi pi-map-marker font-17 m-r-3"></i>
                    {{ author.location }}
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>

      <!-- Avatar image -->
      <div>
        <img
          class="mt-4 mr-5 photo"
          [src]="author.picture"
          onerror="this.src='assets/images/blank.jpg'"
        />
      </div>
    </div>
  </div>
</div>

<hr />
<div class="p-grid">
  <div class="p-col-8">
    <label style="font-size:large">Recent content by {{ author.name }}</label>
  </div>
  <div class="p-col-4">
    <p-button
      class="p-button-sm coverage"
      type="button"
      icon="pi pi-search"
      label="View all content by {{ author.name }}"
      (click)="goToExploreRelatedCoverage(author)"
    ></p-button>
  </div>
</div>
<app-content-items
  [parentName]="parentName"
  [items]="contentItems"
  [loading]="!loading"
  [pageSize]="authorContentPageSize"
  [page]="currentPage"
  [total]="authorContentTotal"
  [keywords]="keywords"
></app-content-items>
<div id="paginator" class="p-d-flex p-jc-between">
  <div *ngIf="authorContentTotalPages >= 2">
    <p-paginator
      #pp
      [showCurrentPageReport]="false"
      currentPageReportTemplate="Showing {{ authorContentPage }} of {{
        authorContentPageSize
      }} pages"
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="authorContentPageSize"
      [totalRecords]="authorContentTotal"
    >
    </p-paginator>
  </div>
  <div class="p-mt-2">
    <p-button
      pTooltip="Report Missing Data"
      class="p-button-sm coverage"
      icon="pi pi-flag"
      label=""
      (click)="openReportMissingDataModal()"
    ></p-button>
  </div>
  <div id="sort">
    <app-sort-options
      (sortChangesEmiter)="sortChange($event)"
      [defaultSort]="sortSelected"
    ></app-sort-options>
  </div>
</div>
