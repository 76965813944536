import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import {
  tap,
  mergeMap,
  map,
  catchError,
  switchMap,
  filter
} from 'rxjs/operators';

import { Observable, of, throwError } from 'rxjs';
import { TopicService } from 'app/services';
import { FacetSearch, TopicResponse } from 'app/models';
import { isEmpty, includes } from 'lodash';

@Injectable()
export class TopicDialogEffects {
  constructor(private actions$: Actions, private topicService: TopicService) {}

  loadTopicDialog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.setTopicDialog),
      mergeMap(
        ({
          keywords,
          projectSelected,
          topicSelected,
          sort,
          authors,
          outlets
        }) =>
          this.getContents$(topicSelected).pipe(
            // filter(response => !response?.type.includes("setTopicDialogError")),
            switchMap(response => [
              actions.setTopicDialogSuccess({
                topic: this.getTopic(response, projectSelected, topicSelected)
              }),
              actions.loadTopicContent({
                facetSearch: new FacetSearch(
                  topicSelected.id,
                  'topic',
                  this.getKeywords(response, keywords),
                  sort,
                  0,
                  50,
                  authors,
                  outlets
                ),
                topic: this.getTopic(response, projectSelected, topicSelected)
              })
            ])
          )
      )
    )
  );

  getKeywords(response, keywords) {
    if (!response) {
      return keywords;
    }
    const query = response.query ? response.query : response.implicitQuery;
    if (keywords) {
      return `(${query}) AND (${keywords})`;
    } else {
      return query;
    }
  }

  getContents$(topic): Observable<any> {
    return (topic.custom
      ? this.topicService.getUserTopic(topic.userTopicId)
      : this.topicService.getTopicRelated(topic.id)
    ).pipe(
      catchError(error => {
        if (error.status === 500) {
          return of(actions.setTopicDialogError({ payload: error }));
        }
        return of(actions.setTopicDialogError({ payload: error }));
      })
    );
  }

  getTopic(topic, projectSelected, topicSelected): TopicResponse {
    if (topic?.type.includes('setTopicDialogError')) {
      return new TopicResponse({
        id: topicSelected.id,
        name: topicSelected.name,
        query: topicSelected.name
      });
    }
    return new TopicResponse({
      id: topic.id,
      name: topic.name,
      query: topic.query,
      implicitQuery: topic.implicitQuery,
      influenceScore: topic.influenceScore,
      categoryId: topicSelected.categoryId,
      categoryName: topicSelected.categoryName,
      coverage: topicSelected.coverage,
      authors: topicSelected.authors,
      outlets: topicSelected.outlets,
      selected: topicSelected.selected,
      custom: topicSelected.custom,
      userTopicId: topicSelected.userTopicId,
      wikipediaUrl: topic.contact.wikipediaUrl,
      webSiteUrl: topic.contact.webSiteUrl,
      youTubeUrl: topic.contact.youTubeUrl,
      twitterUrl: topic.contact.twitterUrl,
      linkedInUrl: topic.contact.linkedInUrl,
      picture: topic.contact.imageUrl,
      financial: topic.organizationDetail
        ? this.getYahooFinanceURL(topic.organizationDetail.stockTicker)
        : null,
      related: topic.related,
      stats: topic.stats,
      project: projectSelected,
      isSaved: topicSelected.isSaved
    });
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }
}
