import {
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Search } from 'app/models';
import { eq } from 'lodash';
import { distinctUntilKeyChanged } from 'rxjs/operators';
import { HeaderMenuItems } from 'redux/actions';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [DatePipe]
})
export class SearchComponent implements OnInit {
  public searchValue: string;
  public placeholderSearch: string;
  public inputIcon: string;
  public loading: boolean;
  public searchLoaded: boolean;
  public showFilters: boolean;
  private facets: any;
  public searchLabel: string;
  public keywordsDispached: string;
  public tabControl: number;
  public search: Search;
  public searchToAdvFilter: Search;
  public onReset: boolean;
  public onSearch: boolean;
  public filterText: string;
  public showFilterSection: boolean;
  public showNewThisWeekButton: boolean;
  public showTopicsMenubar: boolean;
  public screenSize: number;
  public topicsMenubarClass: string;
  public searchWidth: string;
  public buttonWidth: string;
  public coverageMargin: string;
  public isTopicsFilterSelected: boolean;
  public headerMenuActive: HeaderMenuItems;

  @Output() setKeywordsEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('keywords') keywords: ElementRef;
  constructor(private store: Store<AppState>) {
    this.inputIcon = 'pi pi-search';
    this.loading = false;
    this.searchLoaded = false;
    this.showFilters = false;
    this.facets = null;
    this.tabControl = 1;
    this.search = null;
    this.onReset = false;
    this.onSearch = false;
    this.filterText = null;
    this.searchToAdvFilter = null;
    this.showFilterSection = false;
    this.showNewThisWeekButton = true;
    this.showTopicsMenubar = true;
    this.screenSize = null;
    this.topicsMenubarClass = 'p-col p-grid p-jc-center';
    this.searchLabel = 'Search';
    this.searchWidth = 'width: 500px;';
    this.buttonWidth = 'width: 110px;';
    this.coverageMargin = 'margin-left: 5rem;';
  }

  ngOnInit(): void {
    this.searchValue = null;
    this.placeholderSearch = `Search for a topic (e.g. IoT, "3D Printers", microservices)`;
    this.store.select('search').subscribe(({ searchBody, onReset }) => {
      this.keywordsDispached = searchBody ? searchBody.keywords : null;
      this.searchValue = searchBody ? searchBody.keywords : null;
      this.search = searchBody || null;
      const topicsCollection = searchBody ? searchBody.topicsCollection : null;
      const topicsFilter = searchBody ? searchBody.topicsFilter : null;
      if (topicsCollection || topicsFilter || this.keywordsDispached) {
        this.showNewThisWeekButton = false;
      } else {
        this.showNewThisWeekButton = true;
      }
      if (onReset) {
        this.resetSearch();
      }
    });

    this.store
      .select('advancedSearch')
      .pipe(distinctUntilKeyChanged('filterText'))
      .subscribe(({ filterText }) => {
        if (filterText) {
          this.showTopicsMenubar = false;
        } else {
          this.showTopicsMenubar = true;
        }
      });
    this.store
      .select('facetsSearch')
      .subscribe(({ searchBy, loading, loaded }) => {
        this.facets = searchBy;
        this.loading = loading;
        this.searchLoaded = loaded;
      });
    this.store.select('topicsRanked').subscribe(({ loading, loaded }) => {
      this.loading = loading;
      this.searchLoaded = loaded;
      this.onSearch = loading;
    });

    this.store
      .select('ui')
      .subscribe(({ indexTab, screenSizeValues, headerMenuActive }) => {
        this.tabControl = indexTab;
        this.showFilterSection = eq(indexTab, 1);
        this.screenSize = screenSizeValues ? screenSizeValues.scrWidth : null;
        this.headerMenuActive = headerMenuActive;
        if (this.screenSize < 1650) {
          this.topicsMenubarClass = 'p-col p-grid p-jc-end';
          this.searchLabel = null;
          this.searchWidth = 'width: 350px;';
          this.buttonWidth = 'width: 50px;';
          this.coverageMargin = 'margin-left: 1rem;';
        } else {
          this.topicsMenubarClass = 'p-col p-grid p-jc-center';
          this.searchLabel = 'Search';
          this.searchWidth = 'width: 500px;';
          this.buttonWidth = 'width: 110px;';
          this.coverageMargin = 'margin-left: 5rem;';
        }
      });
    this.store.select('topics').subscribe(({ topicMenuItemSelected }) => {
      this.isTopicsFilterSelected = !!topicMenuItemSelected;
    });
  }

  public clearInput() {
    // this.newHandleSearch();
    this.resetSearch();
    this.store.dispatch(actions.resetSearch({ onReset: true }));
    if (eq(this.headerMenuActive, 'Home')) {
      this.store.dispatch(actions.resetAuthorProfile());
      this.store.dispatch(actions.resetOutletProfile());
      this.store.dispatch(actions.resetTopicProfile());
    } else if (eq(this.headerMenuActive, 'Topics')) {
      this.dispatchRankedTopicSarch(this.search);
    }
  }

  resetSearch() {
    this.store.dispatch(actions.tabControl({ index: 0 }));
    this.store.dispatch(actions.unSetSearch());
    this.resetTopicMenuBar();
    this.onReset = true;
    this.searchValue = null;
    this.search = null;
    this.store.dispatch(actions.resetSearch({ onReset: false }));
  }

  resetAdvanceFilters() {
    this.onReset = true;
    this.newHandleSearch();
  }

  setFocusOnClick() {
    this.keywords.nativeElement.focus();
    this.keywords.nativeElement.select();
  }

  public toggleModal() {
    this.showFilters = !this.showFilters;
  }

  public handleVisible(visible) {
    this.showFilters = visible;
  }

  public onKeywordsChange(value: string) {
    this.searchValue = value;
    this.setKeywordsEmitter.emit(this.searchValue);
  }

  public handleSearchByTypeahead(search: Search) {
    this.searchToAdvFilter = search;
    this.dispatchRankedTopicFromTypeaheadSearch(search);
    this.dispatchCoverageFromTypeaheadSearch(search);
  }

  public handleAuthorOutletSearchByTypeahead(search: Search) {
    this.searchToAdvFilter = search;
    this.dispatchRankedTopicSarch(search);
    this.dispatchAuthorOutletSearch(search);
  }

  public handleAdvanceSearchOptions(search: Search) {
    if (eq(this.tabControl, 0)) {
      this.dispatchRankedTopicSarch(search);
    } else if (eq(this.tabControl, 1)) {
      this.dispatchDashboardSearch(search);
    }
  }

  public handleSearchOnEnter(e) {
    this.newHandleSearch();
  }

  public oldHandlehandleSearch() {
    if (eq(this.tabControl, 0)) {
      this.dispatchRankedTopicSarch(this.search);
    } else if (eq(this.tabControl, 1)) {
      this.dispatchDashboardSearch(this.search);
    }
  }

  public newHandleSearch() {
    if (this.searchValue) {
      if (eq(this.headerMenuActive, 'Home')) {
        this.dispatchDashboardSearch(this.search);
      } else if (eq(this.headerMenuActive, 'Topics')) {
        this.dispatchRankedTopicSarch(this.search);
      }
      this.store.dispatch(actions.hideSidebar());
    }
  }

  public handleNewThisWeek() {
    this.resetTopicMenuBar();
    const newSearch = new Search({
      facets: { TOPICS: 'newsAuthorsWeek desc' },
      topicsFilter: 'NEW_PAST_WEEK',
      topicsCollection: null
    });

    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );
    this.store.dispatch(
      actions.setTopicsRanked({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );
  }

  public resetTopicMenuBar() {
    /* Reset topics menubar*/
    if (this.isTopicsFilterSelected) {
      const item = {
        label: 'All',
        items: null
      };
      this.store.dispatch(actions.setTopicMenuItemSelected({ item }));
    }
  }

  public dispatchRankedTopicSarch(search: Search) {
    const defaultFacets = this.searchValue
      ? 'relatedScore desc'
      : 'newsAuthorsWeek desc';
    let newSearch = null;
    if (search) {
      newSearch = new Search({
        ...search,
        keywords: this.searchValue,
        facets: { TOPICS: defaultFacets },
        topicsFilter: null,
        topicsCollection: null
      });
    } else {
      newSearch = new Search({
        keywords: this.searchValue,
        facets: {
          TOPICS: defaultFacets
        },
        topicsFilter: null,
        topicsCollection: null
      });
    }
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    this.store.dispatch(
      actions.setTopicsRanked({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );
  }

  public dispatchRankedTopicFromTypeaheadSearch(search: Search) {
    const newSearch = new Search({
      ...search,
      facets: { TOPICS: 'relatedScore desc' },
      topicsFilter: eq(search.topicsFilter, 'NEW_PAST_WEEK')
        ? null
        : search.topicsFilter
    });
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    this.store.dispatch(
      actions.setTopicsRanked({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );
  }

  private dispatchCoverageFromTypeaheadSearch(search: Search) {
    const newSearch = {
      ...search,
      keywords: search.keywords,
      topicsCollection: null,
      topicsFilter: null
    };
    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );

    this.store.dispatch(
      actions.setCoverages({
        searchBody: newSearch,
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );

    this.store.dispatch(actions.tabControl({ index: 1 }));
  }

  public searchCoverage(keywords) {
    this.searchValue = keywords;
    this.newHandleSearch();
    setTimeout(() => {
      this.store.dispatch(actions.tabControl({ index: 1 }));
    }, 1500);
  }

  public searchTopic(keywords) {
    this.searchValue = keywords;
    this.newHandleSearch();
    this.store.dispatch(actions.tabControl({ index: 0 }));
  }

  private dispatchDashboardSearch(search: Search) {
    let newSearch = null;
    if (search) {
      newSearch = new Search({
        ...search,
        keywords: this.searchValue,
        facets: this.facets
      });
    } else {
      newSearch = new Search({
        keywords: this.searchValue,
        facets: this.facets
      });
    }
    this.runCoverageSearch(newSearch);
  }

  private dispatchAuthorOutletSearch(search: Search) {
    const newSearch = {
      ...search,
      keywords: null,
      facets: this.facets,
      topicsCollection: null,
      topicsFilter: null
    };
    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );

    this.store.dispatch(
      actions.setCoverages({
        searchBody: newSearch,
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );

    this.store.dispatch(actions.tabControl({ index: 1 }));
  }

  private runCoverageSearch(search: Search) {
    const newSearch = {
      ...search,
      keywords: search.keywords,
      topicsCollection: null,
      topicsFilter: null
    };

    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );

    this.store.dispatch(
      actions.setCoverages({
        searchBody: newSearch,
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );

    this.store.dispatch(actions.tabControl({ index: 1 }));
  }
  goCoverage() {
    this.store.dispatch(actions.tabControl({ index: 1 }));
  }
}
