import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { ToastNotificationService } from 'app/components';
import { SocialUser, UserProfile } from 'app/models';
import { AuthenticationService } from 'app/services';

@Component({
  selector: 'app-callback',
  template: `
    <toast-notification></toast-notification>
    <app-spinner alwaysVisible="true"></app-spinner>
  `
})
export class CallbackComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: AuthenticationService,
    private notificationService: ToastNotificationService
  ) {}

  ngOnInit() {
    this.getUserProfile();
  }

  getUserProfile(): void {
    this.authService.user$.subscribe(response => {
      if (response) {
        const userProfile = this.userService.getUserProfile();
        userProfile.firstName = response.given_name;
        userProfile.lastName = response.family_name;
        userProfile.emailAddress = response.email;
        userProfile.picture = response.picture;
        userProfile.connection = response.sub;
        this.userService.saveUserProfile(userProfile);
        this.signInSocialUser(userProfile);
      } else {
        this.goToLogin(1000);
      }
    });
  }

  signInSocialUser(userProfile) {
    this.userService.loginWithAuth0(userProfile).subscribe(
      ({ tokenAccess, denied }: any) => {
        if (tokenAccess) {
          this.router.navigateByUrl(`auth?jwtt=${tokenAccess}&type=oauth`);
        } else {
          this.notificationService.showMessage(
            'Social user login denied',
            'Error'
          );
          this.goToLogin(3000);
        }
      },
      error => {
        this.notificationService.showMessage(
          'Social user login error ',
          'Error'
        );
        this.goToLogin(3000);
      }
    );
  }

  goToLogin(life) {
    setTimeout(() => {
      this.userService.goAuth0Login(null, 'CallbackAuth0');
    }, life);
  }
}
