import { createReducer, on } from '@ngrx/store';
import { Search } from 'app/models';
import {
  setFacetsSearch,
  setFacetsSearchSuccess,
  setFacetsSearchError
} from 'redux/actions';

export interface FacetsSearchState {
  searchBy: any;
  contents: any;
  searchBody: Search;
  loaded: boolean;
  loading: boolean;
  error: any;
  page: number;
  isNewSearch: boolean;
  facets: any;
}

const initialState: FacetsSearchState = {
  searchBy: {
    AUTHORS: null,
    OUTLETS: null,
    COMPANIES: null,
    TOPICS: null,
  },
  contents: null,
  searchBody: null,
  loaded: false,
  loading: false,
  error: null,
  page: null,
  isNewSearch: false,
  facets: null
};

const _facetsSearchReducer = createReducer(
  initialState,
  on(setFacetsSearch, (state, { searchBody, page, isNewSearch }) => {
    return {
      ...state,
      searchBody,
      page,
      loading: true,
      loaded: false,
      isNewSearch
    };
  }),

  on(setFacetsSearchSuccess, (state, { contents }) => {
    const { facets } = state?.searchBody;
    return {
      ...state,
      contents,
      facets,
      loading: false,
      loaded: true
    };
  }),

  on(setFacetsSearchError, (state, { payload }) => ({
    ...state,
    contents: null,
    loading: false,
    loaded: false,
    isNewSearch: false,
    facets: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  }))
);

export function facetsSearchReducer(state, action) {
  return _facetsSearchReducer(state, action);
}
