import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../actions';
import { mergeMap, catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { TopicService } from 'app/services';
import { TopicProfile } from 'app/models';

@Injectable()
export class TopicProfileEffects {
  constructor(private actions$: Actions, private topicService: TopicService) {}

  loadPosts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadTopicProfile),
      mergeMap(({ topicId }) =>
        this.getTopicContactDetails$(topicId).pipe(
          switchMap(response => [
            actions.loadTopicProfileSuccess({
              topicContactProfile: new TopicProfile({
                id: response.id,
                name: response.name,
                webSiteUrl: response.contact.webSiteUrl,
                picture: response.contact.imageUrl,
                twitter: response.contact.twitter,
                twitterUrl: response.contact.twitterUrl,
                linkedIn: response.contact.linkedIn,
                linkedInUrl: response.contact.linkedInUrl,
                facebook: response.contact.facebook,
                facebookUrl: response.contact.facebookUrl,
                financial: response.financial
              })
            })
          ]),
          catchError(err =>
            of(actions.loadAuthorProfileError({ payload: err }))
          )
        )
      )
    )
  );

  getTopicContactDetails$(id): Observable<any> {
    return this.topicService.getTopicWithContact(id);
  }
}
