import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { ToastNotificationService } from 'app/components';
import { isEmpty, eq } from 'lodash';
import { CategoryResponse } from 'app/models';
import { Listbox } from 'primeng/listbox';

@Component({
  selector: 'app-topic-manager',
  template: `
    <p-dialog
      [dismissableMask]="true"
      [(visible)]="showModal"
      [style]="{ width: '60vw' }"
      [modal]="true"
      position="center"
      (onHide)="closeModalDialog()"
    >
      <ng-template pTemplate="header">
        <h4>{{ title }}</h4>
      </ng-template>
      <div class="p-mt-3">
        <!-- <div class="p-d-flex p-jc-between">
          <div></div>
          <div *ngIf="topicSelected">
            <p-button
              pRipple
              icon="pi pi-star"
              (click)="handleDelete()"
              label="Unfollow"
              styleClass="p-button-raised p-button-danger p-button-text"
            ></p-button>
          </div>
        </div> -->
        <form [formGroup]="form">
          <div class="p-d-flex p-jc-center">
            <div class="p-col">
              <div class="p-fluid">
                <div class="p-field">
                  <label>Topic Name*</label>
                  <input
                    id="name"
                    formControlName="name"
                    inputId="name"
                    type="text"
                    aria-describedby="topic-help"
                    [class]="
                      invalidField('name')
                        ? 'details-input ng-invalid ng-dirty'
                        : 'details-input'
                    "
                    pInputText
                  />
                  <small
                    *ngIf="invalidField('name')"
                    id="topic-help"
                    class="p-error"
                    >Topic name is a required field.</small
                  >
                </div>
                <div class="p-field">
                  <label>Search Query</label>
                  <a
                    href="http://search.technews.io/help.html#keysearch"
                    target="_blank"
                    title="Help Guide"
                    class="ml-1"
                    ><i class="fa fa-question-circle"></i
                  ></a>
                  <input
                    id="query"
                    formControlName="query"
                    inputId="query"
                    type="text"
                    aria-describedby="query-help"
                    pInputText
                  />
                </div>
                <div>
                  <span *ngIf="categorySelected"
                    ><b>Category:</b> {{ categorySelected.name }}</span
                  >
                </div>
                <div class="p-field" *ngIf="categories">
                  <!--                  <label>Select a category</label>-->
                  <div class="p-mt-2"></div>
                  <p-listbox
                    #myListbox
                    (onClick)="selectItemOnClick()"
                    [filter]="true"
                    [options]="categories"
                    formControlName="category"
                    optionLabel="name"
                    filterPlaceHolder="Select a category"
                    [listStyle]="{
                      height: '180px',
                      'max-height': '180px',
                      'max-with': '100%'
                    }"
                    emptyMessage="Loading categories.."
                  ></p-listbox>
                  <small
                    *ngIf="invalidField('category')"
                    id="company-help"
                    class="p-error"
                    >Category is a required option.</small
                  >
                  <div class="p-d-flex p-jc-between p-mt-2">
                    <div>
                      <button
                        pButton
                        icon="pi pi-cog"
                        type="button"
                        class="p-button-sm"
                        label="Manage Categories"
                        (click)="openCategoryManagerModal()"
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <ng-template pTemplate="footer">
        <div class="p-mt-3"></div>
        <p-button
          pRipple
          icon="pi pi-times"
          (click)="closeModalDialog()"
          label="Close"
          styleClass="p-button-raised p-button-secondary p-button-text"
        ></p-button>

        <p-button
          [disabled]="isSaving"
          pRipple
          icon="pi pi-check"
          (click)="handleSubmit()"
          label="Save"
          class="save"
        >
        </p-button>
      </ng-template>
    </p-dialog>
  `,
  styles: [
    `
      :host ::ng-deep .p-dialog .p-dialog-header {
        border-bottom: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-footer {
        border-top: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-content {
        padding-top: 10px;
      }

      :host ::ng-deep {
        .p-button:focus {
          box-shadow: none;
        }

        .p-inputtext:focus {
          box-shadow: none;
        }

        .p-button {
          background: #465675;
          border-color: transparent;
        }

        .p-button:hover {
          background: #4e5f80;
          border-color: #4e5f80;
        }
      }
    `,
  ],
})
export class SidebarTopicManagerComponent implements OnInit {
  public isSaving: boolean;
  public isCompany: boolean;
  public categories: CategoryResponse[];
  public beforeCategoriesFilter: CategoryResponse[];
  public topicId: number;
  public title: string;
  public projectId: any;
  public userProject: any;
  public topicSelected: any;
  public showModal: boolean;
  public userId: any;
  public saveSearchAsTopicData;
  public categorySelected: any;
  public form: FormGroup;

  @ViewChild('myListbox') myListboxComponent: Listbox;

  constructor(
    private notificationService: ToastNotificationService,
    private formBuilder: FormBuilder,
    private store: Store<AppState>
  ) {
    this.title = 'New Topic';
    this.projectId = null;
    this.isSaving = false;
    this.userId = null;
    this.topicId = null;
    this.categories = [];
    this.categorySelected = null;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      name: ['', Validators.required],
      query: [null],
      category: [
        {
          value: null,
        },
        Validators.required,
      ],
    });

    this.store.select('category').subscribe(({ categories }) => {
      this.categories = categories;
      this.beforeCategoriesFilter = categories;
    });

    this.store.select('search').subscribe(({ searchBody, saveAsTopic }) => {
      if (saveAsTopic) {
        this.saveSearchAsTopicData = {
          query: searchBody.keywords,
          isSaveAsTopic: saveAsTopic,
        };
      }
    });

    this.store.select('userData').subscribe(({ data }) => {
      if (data) {
        this.userId = data.userId;
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.projectId = projectSelected.value;
        this.userProject = projectSelected;
      }
    });
    this.store
      .select('topic')
      .subscribe(
        ({
          loading,
          error,
          message,
          creationMode,
          editionMode,
          topic,
          showTopicManager,
          successResponse,
        }) => {
          this.isSaving = loading;
          this.showModal = showTopicManager;
          this.title = topic ? 'Edit Topic' : 'New Topic';
          if (showTopicManager) {
            if (this.myListboxComponent._filterValue) {
              this.myListboxComponent._filterValue = null;
              this.myListboxComponent._filteredOptions = this.categories;
            }
          }

          // Store to Create/Edit/delete Topic
          if (successResponse) {
            this.notificationService.showMessage(message, 'Success');
          } else if (error) {
            this.notificationService.showMessage('Error Topic manager.', 'Error');
          }

          // Store to show topic manager
          if (editionMode) {
            let query =
              isEmpty(topic.query) || eq(topic.query, 'null') ? null : topic.query;
            if (this.saveSearchAsTopicData?.isSaveAsTopic) {
              query =
                isEmpty(this.saveSearchAsTopicData.query) ||
                eq(this.saveSearchAsTopicData.query, 'null')
                  ? null
                  : this.saveSearchAsTopicData.query;
            }
            this.categorySelected = this.categories.find((item) =>
              eq(item.id, topic?.categoryId)
            );
            this.topicId = topic.userTopicId;
            this.topicSelected = topic;
            this.form.get('id').setValue(topic.userTopicId);
            this.form.get('name').setValue(topic.name);
            this.form.get('query').setValue(query);
            this.form.get('category').setValue(this.categorySelected);
            if (!topic.custom) {
              this.form.get('name').disable();
              this.form.get('query').disable();
            } else {
              this.form.get('name').enable();
              this.form.get('query').enable();
            }
          }

          if (creationMode) {
            this.topicSelected = null;
            this.categorySelected = null;
            this.form.get('id').setValue(null);
            this.form.get('name').setValue('');
            this.form.get('query').setValue(null);
            this.form.get('category').setValue(null);
            this.form.get('name').enable();
            this.form.get('query').enable();
          }
        }
      );
    this.isCompany = false;
  }

  selectItemOnClick() {
    this.categorySelected = this.form.get('category').value;
  }

  copyValueToQuery() {
    if (isEmpty(this.form.get('query').value)) {
      this.form.get('query').setValue(`\"${this.form.get('name').value}\"`);
    }
  }

  closeModalDialog() {
    this.form.reset();
    this.store.dispatch(actions.hideTopicDialog());
  }

  invalidField(field: string) {
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const topic = {
      name: this.form.get('name').value,
      query: this.form.get('query').value || null,
      categoryId: this.categorySelected.id,
    };

    if (this.topicSelected) {
      this.edit(topic);
    } else {
      this.add(topic);
    }
  }

  // handleDelete() {
  //   this.confirmationService.confirm({
  //     message: 'Are you sure that you want to delete?',
  //     header: 'Delete Confirmation',
  //     icon: 'pi pi-exclamation-triangle',
  //     key: 'deleteTopic',
  //     accept: () => {
  //       this.delete();
  //     },
  //     reject: type => {
  //       // handle rejected option ConfirmEventType.CANCEL
  //     }
  //   });
  // }

  onClose() {
    this.store.dispatch(actions.resetTopicState());
  }

  private add(newTopic) {
    this.store.dispatch(
      actions.createTopic({
        topic: newTopic,
        userId: this.userId,
        userProject: this.userProject,
      })
    );
  }

  private edit(editTopic) {
    this.store.dispatch(
      actions.updateTopic({
        topic: editTopic,
        userTopicId: this.topicId,
        userId: this.userId,
        userProject: this.userProject,
      })
    );
  }

  // private delete() {
  //   this.store.dispatch(
  //     actions.deleteTopic({
  //       topicId: this.topicId,
  //       userId: this.userId,
  //       userProject: this.userProject
  //     })
  //   );
  // }

  openCategoryManagerModal() {
    this.store.dispatch(actions.showCategoriesListDialog());
  }
}
