import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { get, maxBy, capitalize, isEmpty, eq } from 'lodash';
import { FACETS } from 'app/constants';
import { SelectOption } from '../../../interfaces';
import { Paginator } from 'primeng/paginator';
import { FacetContent } from '../dashboard-facets/facet-content.interface';
import { HeaderMenuItems } from 'redux/actions';

@Component({
  selector: 'app-facets-table',
  template: `
    <app-loading [isLoading]="isLoadingContent"></app-loading>
    <div class="card p-mt-2">
      <div class="card-body" style="padding: 0px;">
        <div>
          <perfect-scrollbar
            class="scroll-container"
            [config]="config"
            #contentItemsPerfectScroll
          >
            <div class="text-muted" *ngIf="isLoadingContent">
              <app-facets-table-skeleton></app-facets-table-skeleton>
            </div>
            <div class="facets-container" *ngIf="!isLoadingContent">
              <div
                class="d-flex justify-content-center text-muted"
                *ngIf="allContentsAreEmpty && facetsLoaded"
              >
                <app-not-records-found
                  [visible]="allContentsAreEmpty"
                ></app-not-records-found>
              </div>

              <div class="d-flex flex-row" *ngIf="!allContentsAreEmpty">
                <div class="p-pr-2" *ngFor="let content of facets">
                  <app-content-table
                    [entity]="content"
                    [authorsSavedList]="authorsList"
                    [outletsSavedList]="outletsList"
                  ></app-content-table>
                </div>
              </div>
            </div>
          </perfect-scrollbar>
        </div>
        <div class="p-d-flex p-jc-between facet-footer">
          <div>
            <p-paginator
              *ngIf="totalPages >= 2"
              #p
              [showCurrentPageReport]="false"
              [pageLinkSize]="3"
              [showFirstLastIcon]="false"
              (onPageChange)="getPage($event)"
              [rows]="pageSize"
              [totalRecords]="totalItems"
              [showPageLinks]="showPageButton"
            >
            </p-paginator>
          </div>
          <div></div>
          <div class="p-3" *ngIf="showSort">
            <app-facets-sort
              (sortEmitter)="handleSortTables($event)"
            ></app-facets-sort>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./facets-table.scss']
})
export class FacetsTableComponent implements OnInit {
  public summaries: string[];
  public facetsOptions: SelectOption[];
  public selectedFacets: any[];
  public facets: FacetContent[];
  public companies: FacetContent;
  public selectOptions: any[];
  public authors: any;
  public outlets: any;
  public topics: any;
  public products: any;
  public people: any;
  public locations: any;
  public countries: any;
  public cities: any;
  public filterSearch: any;
  public facetsLoading: boolean;
  public facetsLoaded: boolean;
  public sortAuthorFacets: string;
  public sortOutletFacets: string;
  public sortTopicFacets: string;
  public sortCompanyFacets: string;
  public currentPage: number;
  public page: number;
  public totalPages: number;
  public totalItems: number;
  public pageSize: number;
  public showLabels: number;
  public allContentsAreEmpty: boolean;
  public facetsFromSearch: any;
  public isNewSearch: boolean;
  public project: any;
  public outletsList: any[];
  public authorsList: any[];
  public isLoadingContent: boolean;
  public showPageButton: boolean;
  public screenSize: number;
  public showSort: boolean;
  public headerMenuActive: HeaderMenuItems;

  public perfectScrollbarHeight: any;

  public config: PerfectScrollbarConfigInterface = {};

  @ViewChild('contentItemsPerfectScroll', { static: false })
  contentItemsPerfectScroll: PerfectScrollbarComponent;

  @ViewChild('p', { static: false }) paginator: Paginator;

  constructor(private store: Store<AppState>) {
    this.facetsOptions = FACETS;
    this.totalPages = 0;
    this.totalItems = 0;
    this.page = 1;
    this.currentPage = 0;
    this.pageSize = 50;
    this.facets = [];
    this.selectedFacets = [];
    this.showLabels = 1;
    this.selectOptions = FACETS;
    this.allContentsAreEmpty = false;
    this.facetsFromSearch = null;
    this.isNewSearch = false;
    this.project = null;
    this.authorsList = [];
    this.outletsList = [];
    this.showPageButton = true;
    this.showSort = true;
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store
      .select('facetsSearch')
      .subscribe(
        ({ searchBody, contents, loading, loaded, isNewSearch, facets }) => {
          this.selectedFacets = facets;
          this.facetsLoading = loading;
          this.isLoadingContent = loading;
          this.facetsLoaded = loaded;
          this.isNewSearch = isNewSearch;
          if (loaded) {
            this.filterSearch = searchBody;
            this.facetsFromSearch = facets;
            this.setContents(contents);
            this.scrollTop();
          }
        }
      );

    this.store
      .select('authorsOutletsList')
      .subscribe(({ authors, outlets }) => {
        this.authorsList = authors.map(item => ({ ...item }));
        this.outletsList = outlets.map(item => ({ ...item }));
      });

    this.store
      .select('ui')
      .subscribe(({ screenSizeValues, headerMenuActive }) => {
        this.screenSize = screenSizeValues.scrWidth;
        this.headerMenuActive = headerMenuActive;
        if (this.screenSize >= 1500) {
          this.showPageButton = true;
        } else {
          this.showPageButton = false;
        }
        if (this.screenSize <= 1200) {
          this.showSort = false;
        } else {
          this.showSort = true;
        }

        if (eq(this.headerMenuActive, 'Home')) {
          if (this.facets.length > 0) {
            const exists = this.facets.find(facet =>
              eq(facet.title, this.companies.title)
            );
            if (!exists) {
              this.facets.push(this.companies);
            }
          }
        }
        if (eq(this.headerMenuActive, 'Topics')) {
          if (this.facets.length > 0) {
            const exists = this.facets.find(facet =>
              eq(facet.title, this.companies.title)
            );
            if (exists) {
              this.facets = this.facets.filter(
                facet => !eq(facet.title, this.companies.title)
              );
            }
          }
        }
      });
  }

  setContents(contents) {
    if (!isEmpty(contents)) {
      this.facets = [];
      const author = get(contents, 'authors', null);
      const outlet = get(contents, 'outlets', null);
      const company = get(contents, 'companies', null);
      this.companies = {
        contents: company.content,
        title: 'Topics',
        facets: this.facetsFromSearch,
        pages: company.totalPages,
        items: company.totalElements,
        pageNumber: company.number
      };

      if (author) {
        this.facets.push({
          contents: author.content,
          title: 'Authors',
          facets: this.facetsFromSearch,
          pages: author.totalPages,
          items: author.totalElements,
          pageNumber: author.number
        });
      }
      if (outlet) {
        this.facets.push({
          contents: outlet.content,
          title: 'Outlets',
          facets: this.facetsFromSearch,
          pages: outlet.totalPages,
          items: outlet.totalElements,
          pageNumber: outlet.number
        });
      }
      if (company && eq(this.headerMenuActive, 'Home')) {
        this.facets.push(this.companies);
      }

      const pageNumber = maxBy(this.facets, 'pageNumber').pageNumber;
      this.resetPaginatorPage(pageNumber);
      this.totalPages = maxBy(this.facets, 'pages').pages;
      this.totalItems = maxBy(this.facets, 'items').items;
      this.allContentsAreEmpty = this.isEmptyAllContents(this.facets);
    } else {
      this.facets = [];
    }
  }
  resetPaginatorPage(pageNumber: any) {
    if (this.isNewSearch && this.currentPage > 0 && eq(pageNumber, 0)) {
      this.paginator.changePage(0);
    }
  }

  isEmptyAllContents(facets) {
    if (!!facets.length) {
      for (const facet of facets) {
        if (facet.items > 0) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  goToPageOne() {
    if (this.currentPage > 0) {
      this.paginator.changePage(0);
    } else {
      this.handleFacetsOption();
    }
  }

  getPage(e: any) {
    this.currentPage = e.page;
    this.page = e.page + 1;
    this.handleFacetsOption();
  }

  handleFacetsOption() {
    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: this.filterSearch,
        page: this.currentPage,
        isNewSearch: false
      })
    );
  }

  handleSortTables(sort) {
    const { value } = sort;
    const newFacets = {
      ...this.selectedFacets,
      AUTHORS: eq(value, 'influence') ? 'influenceScore desc' : null,
      OUTLETS: eq(value, 'influence') ? 'influenceScore desc' : null,
      COMPANIES: eq(value, 'growth') ? 'deltaYearPercent desc' : null
    };
    this.setFacetes(newFacets);

    this.goToPageOne();
  }

  setFacetes(newFacets) {
    const searchBody = { ...this.filterSearch, facets: newFacets };
    this.filterSearch = searchBody;
    this.selectedFacets = newFacets;
  }

  private scrollTop() {
    if (this.contentItemsPerfectScroll) {
      this.contentItemsPerfectScroll.directiveRef.scrollTo(0, 1);
    }
  }
}
