import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavNoticationComponent } from './nav-notification/nav-notification.component';
import { SharedModule } from '../shared/shared.module';
import { SearchComponent } from './search/search.component';
import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import {
  LoadingTopicComponent,
  LoadingComponent,
  NotRecordsFoundComponent,
  TopicsMenubarComponent,
  TypeaheadSearchComponent,
  SortOptionsComponent,
  ToastNotificationComponent,
  ToastNotificationService,
  DescriptionBoxComponent,
  CoveragePreviewComponent,
  LongTermChartComponent,
  ToolbarContentComponent,
  FilterTextComponent,
  SovChartComponent,
  BlockUIComponent,
  ScreenSizeComponent,
  ContentItemsComponent,
  ContentItemsSkeletonComponent,
  SidebarListComponent,
  BadgeComponent,
  CollectionsPanelComponent,
  CollectionItemComponent,
  CollectionCarouselComponent,
  AuthorOutletProfileComponent,
  OutletProfileComponent,
  TopicProfileComponent
} from './';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [
    NavNoticationComponent,
    SearchComponent,
    ToastNotificationComponent,
    AdvancedSearchComponent,
    LoadingTopicComponent,
    LoadingComponent,
    NotRecordsFoundComponent,
    TopicsMenubarComponent,
    TypeaheadSearchComponent,
    SortOptionsComponent,
    DescriptionBoxComponent,
    CoveragePreviewComponent,
    LongTermChartComponent,
    ToolbarContentComponent,
    FilterTextComponent,
    SovChartComponent,
    BlockUIComponent,
    ScreenSizeComponent,
    ContentItemsComponent,
    ContentItemsSkeletonComponent,
    SidebarListComponent,
    BadgeComponent,
    CollectionsPanelComponent,
    CollectionItemComponent,
    CollectionCarouselComponent,
    AuthorOutletProfileComponent,
    OutletProfileComponent,
    TopicProfileComponent
  ],
  exports: [
    NavNoticationComponent,
    SearchComponent,
    ToastNotificationComponent,
    AdvancedSearchComponent,
    LoadingTopicComponent,
    LoadingComponent,
    NotRecordsFoundComponent,
    TopicsMenubarComponent,
    TypeaheadSearchComponent,
    SortOptionsComponent,
    DescriptionBoxComponent,
    CoveragePreviewComponent,
    LongTermChartComponent,
    ToolbarContentComponent,
    FilterTextComponent,
    SovChartComponent,
    BlockUIComponent,
    ScreenSizeComponent,
    ContentItemsComponent,
    ContentItemsSkeletonComponent,
    SidebarListComponent,
    BadgeComponent,
    CollectionsPanelComponent,
    CollectionItemComponent,
    CollectionCarouselComponent,
    AuthorOutletProfileComponent,
    OutletProfileComponent,
    TopicProfileComponent
  ],
  providers: [ToastNotificationService]
})
export class ComponentsModule {}
