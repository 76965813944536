// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  technews: 'http://search.dev.technews.io/#/',
  api_url: 'https://api.dev.itdatabase.com',
  // api_url: 'http://localhost:8081',
  auth_url: 'https://auth.dev.itdatabase.com',
  product: 'TOPICS',
  cdn_url: 'http://cdn.itdatabase.com/Development',
  s3_url: 'https://s3-us-west-2.amazonaws.com',
  icon_url: 'https://itdatabase-media.s3.us-west-2.amazonaws.com',
  images_version: '20160711',
  search_url: 'http://search.dev.technews.io/#/?v=2',
  list_url: 'http://search.dev.technews.io/#/?v=1',
  charts_url: 'http://search.dev.technews.io/#/?v=4',
  events_url: 'http://events.dev.technews.io/login',
  help_url: 'http://search.dev.technews.io/search.html',
  logout_url: 'http://search.dev.technews.io/#/logout',
  myAccount_url: 'http://search.dev.technews.io/#/?v=2&active=myaccount',
  public_url: 'http://www.technews.io',
  auth0: {
    domain: 'auth.dev.technews.io', // 'dev-6q6owftoos5h8iay.us.auth0.com',
    clientId: 'Zc3DSgSRAXo4CgzhCavFZHQ4csgXJwK2',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/auth/callback`
    }
  },
  openBlog: false
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
