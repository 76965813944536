import { Component, OnInit } from '@angular/core';
import { eq } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as actions from 'redux/actions';
import { CategoryService } from 'app/services';

@Component({
  selector: 'app-company-manager',
  template: `
    <p-confirmDialog
      key="deleteCompany"
      [style]="{ width: '40vw' }"
      [baseZIndex]="10000"
      rejectButtonStyleClass="p-button-text"
    ></p-confirmDialog>
    <p-dialog
      [dismissableMask]="true"
      [(visible)]="showModal"
      [style]="{ width: '70vw' }"
      [modal]="true"
      position="center"
      (onHide)="closeModalDialog()"
    >
      <ng-template pTemplate="header">
        <h4>{{ title }}</h4>
      </ng-template>
      <div class="p-mt-3">
        <form [formGroup]="form">
          <div class="p-d-flex p-jc-center">
            <div class="p-col">
              <div class="p-fluid">
                <div class="p-field">
                  <label>Company Name*</label>
                  <input
                    id="company"
                    formControlName="companyName"
                    inputId="companyName"
                    type="text"
                    aria-describedby="company-help"
                    [class]="
                      invalidField('companyName')
                        ? 'details-input ng-invalid ng-dirty'
                        : 'details-input'
                    "
                    pInputText
                  />
                  <small
                    *ngIf="invalidField('companyName')"
                    id="company-help"
                    class="p-error"
                    >Company name is a required field.</small
                  >
                </div>
                <div class="p-field">
                  <label>Search Query</label>
                  <a
                    href="http://search.technews.io/help.html#keysearch"
                    target="_blank"
                    title="Help Guide"
                    class="ml-1"
                    ><i class="fa fa-question-circle"></i
                  ></a>
                  <input
                    id="query"
                    formControlName="query"
                    inputId="query"
                    type="text"
                    aria-describedby="query-help"
                    pInputText
                  />
                </div>
                <div class="p-field" *ngIf="categories">
                  <label>Category</label>
                  <div class="p-mt-2"></div>
                  <div class="form-check form-check-inline">
                    <div
                      class="custom-control custom-radio"
                      *ngFor="let data of categories"
                    >
                      <input
                        formControlName="category"
                        type="radio"
                        class="custom-control-input"
                        [id]="data.id"
                        [value]="data.id"
                      />
                      <label class="custom-control-label" [for]="data.id"
                        >{{ data.name }} &nbsp;</label
                      >
                    </div>
                  </div>
                  <br />
                  <small
                    *ngIf="invalidField('category')"
                    id="company-help"
                    class="p-error"
                    >Category is a required option.</small
                  >
                </div>
                <div class="p-d-flex p-jc-between">
                  <div>
                    <button
                      pButton
                      icon="pi pi-cog"
                      type="button"
                      class="p-button-sm"
                      label="Manage Categories"
                      (click)="openCategoryManagerModal()"
                    ></button>
                  </div>
                  <div *ngIf="companySelected">
                    <p-button
                      pRipple
                      icon="pi pi-star"
                      (click)="handleDelete()"
                      label="Unfollow"
                      styleClass="p-button-raised p-button-danger p-button-text"
                    ></p-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <ng-template pTemplate="footer">
        <div class="p-mt-3"></div>
        <p-button
          pRipple
          icon="pi pi-times"
          (click)="closeModalDialog()"
          label="Cancel"
          styleClass="p-button-raised p-button-secondary p-button-text"
        ></p-button>

        <p-button
          [disabled]="isSaving || !form.valid"
          pRipple
          icon="pi pi-check"
          (click)="handleSubmit()"
          label="Save"
          styleClass="p-button-primary"
        >
        </p-button>
      </ng-template>
    </p-dialog>
  `,
  styles: [
    `
      :host ::ng-deep .p-dialog .p-dialog-header {
        border-bottom: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-footer {
        border-top: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-content {
        padding-top: 10px;
      }
    `,
  ],
})
export class SidebarCompanyManagerComponent implements OnInit {
  public form: FormGroup;
  public categories: any[];
  public title: string;
  public showModal: boolean;
  public isSaving: boolean;
  private projectId: number;
  public companySelected: any;
  public project: any;
  public userProject: any;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private categoryService: CategoryService
  ) {
    this.title = 'New Company';
    this.projectId = null;
    this.isSaving = false;
    this.categories = null;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      id: [null],
      companyName: ['', Validators.required],
      query: [{ value: '' }],
      category: [
        {
          value: null,
        },
        Validators.required,
      ],
    });
    this.store
      .select('company')
      .subscribe(
        ({ loading, error, message, success, showCompanyManager, company }) => {
          this.isSaving = loading;
          this.showModal = showCompanyManager;
          this.title = company ? 'Edit Company' : 'New Company';

          if (showCompanyManager) {
            this.getCategories(this.projectId);
          }

          // Store to Create/Edit/delete Topic
          if (success) {
            // this.alert.success(message);
            this.onClose();
          } else if (error?.message) {
            // this.alert.error(error?.message);
          }

          if (company && !success) {
            this.companySelected = company;
            this.form.get('id').setValue(company.id);
            this.form.get('companyName').setValue(company.name);
            this.form.get('query').setValue(company.query);
            this.form.get('category').setValue(company?.categoryId);
            if (eq(company?.custom, false)) {
              this.form.get('companyName').disable();
              this.form.get('query').disable();
            }
          } else {
            this.companySelected = null;
            this.form.get('id').setValue(null);
            this.form.get('companyName').setValue('');
            this.form.get('query').setValue('');
            this.form.get('category').setValue(null);
            this.form.get('companyName').enable();
            this.form.get('query').enable();
          }
        }
      );
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.projectId = projectSelected?.value;
        this.userProject = projectSelected;
      }
    });
  }

  closeModalDialog() {
    this.form.reset();
    this.store.dispatch(actions.hideCompanyDialog());
  }

  invalidField(field: string) {
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.companySelected) {
      this.editCompany();
    } else {
      this.createNewCompany();
    }
  }

  onClose() {
    this.store.dispatch(actions.resetCompanyState());
  }

  handleDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'deleteCompany',
      accept: () => {
        this.deleteCompany();
      },
      reject: (type) => {
        // handle rejected option ConfirmEventType.CANCEL
      },
    });
  }

  createNewCompany() {
    this.isSaving = true;
    const payload = {
      userProject: this.userProject,
      name: this.form.get('companyName').value,
      query: this.form.get('query').value,
      categoryId: this.form.get('category').value,
    };
    this.store.dispatch(actions.createCompany({ payload }));
  }

  editCompany() {
    this.isSaving = true;
    const payload = {
      companyId: this.companySelected.id,
      userProject: this.userProject,
      name: this.form.get('companyName').value,
      query: this.form.get('query').value,
      categoryId: this.form.get('category').value,
    };
    this.store.dispatch(actions.updateCompany({ payload }));
  }

  deleteCompany() {
    this.isSaving = true;
    const payload = {
      companySelected: this.companySelected.id,
      userProject: this.userProject,
    };
    this.store.dispatch(
      actions.deleteCompany({
        payload,
      })
    );
  }

  getCategories(projectId) {
    this.categories = [];
    this.categoryService.getCategoriesOfProjects(projectId).subscribe(
      (response: any) => this.categories = response,
      ({ error }) => {
        console.log(error);
      }
    );
  }

  openCategoryManagerModal() {
    this.store.dispatch(actions.showCategoriesListDialog());
  }
}
