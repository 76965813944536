export class FacetSearch {
  private id: number;
  private searchBy: string;
  private keywords: string;
  private sort: string;
  private page: number;
  private size: number;
  private authors?: number[];
  private outlets?: number[];

  constructor(
    id,
    searchBy,
    keywords,
    sort,
    page,
    size,
    authors = [],
    outlets = []
  ) {
    this.id = id;
    this.searchBy = searchBy;
    this.keywords = keywords;
    this.sort = sort;
    this.page = page;
    this.size = size;
    this.authors = authors;
    this.outlets = outlets;
  }

  set setPage(page: number) {
    this.page = page;
  }

  set setSize(size: number) {
    this.size = size;
  }

  set setSort(sort: string) {
    this.sort = sort;
  }

  get getId() {
    return this.id;
  }

  get getKeywords() {
    return this.keywords;
  }

  get getSearchBy() {
    return this.searchBy;
  }

  get getPage() {
    return this.page;
  }

  get getSize() {
    return this.size;
  }

  get getSort() {
    return this.sort;
  }

  get getAuthors() {
    return this.authors;
  }

  get getOutlets() {
    return this.outlets;
  }
}
