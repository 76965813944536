<p-overlayPanel
  #opTypeahead
  [style]="{ width: '60%' }"
  [showCloseIcon]="true"
  [dismissable]="true"
  [appendTo]="'body'"
>
  <div *ngIf="!!typeaheadResponseList.length"></div>
  <div class="p-grid" *ngIf="!!typeaheadResponseList.length">
    <div class="p-col-3">
      <h5>
        {{ typeaheadResponseList[0]?.label }}
      </h5>
      <p-listbox
        (onClick)="selectItemOnClick()"
        [options]="typeaheadResponseList[0]?.items"
        [(ngModel)]="selectedItem"
        optionLabel="name"
        [listStyle]="{
          height: '400px',
          'max-height': '400px',
          'max-with': '100%',
          'font-size': '12px'
        }"
        emptyMessage=" "
      ></p-listbox>
    </div>
    <div class="p-col-3">
      <h5>
        {{ typeaheadResponseList[1]?.label }}
      </h5>
      <p-listbox
        (onClick)="selectItemOnClick()"
        [options]="typeaheadResponseList[1]?.items"
        [(ngModel)]="selectedItem"
        optionLabel="name"
        [listStyle]="{
          height: '400px',
          'max-height': '400px',
          'max-with': '100%',
          'font-size': '12px'
        }"
        emptyMessage=" "
      ></p-listbox>
    </div>
    <div class="p-col-3">
      <h5>
        {{ typeaheadResponseList[2]?.label }}
      </h5>
      <p-listbox
        (onClick)="selectItemOnClick()"
        [options]="typeaheadResponseList[2]?.items"
        [(ngModel)]="selectedItem"
        optionLabel="name"
        [listStyle]="{
          height: '400px',
          'max-height': '400px',
          'max-with': '100%',
          'font-size': '12px'
        }"
        emptyMessage=" "
      ></p-listbox>
    </div>
    <div class="p-col-3">
      <h5>
        {{ typeaheadResponseList[3]?.label }}
      </h5>
      <p-listbox
        (onClick)="selectItemOnClick()"
        [options]="typeaheadResponseList[3]?.items"
        [(ngModel)]="selectedItem"
        optionLabel="name"
        [listStyle]="{
          height: '400px',
          'max-height': '400px',
          'max-with': '100%',
          'font-size': '12px'
        }"
        emptyMessage=" "
      ></p-listbox>
    </div>
<!--    <div class="p-col-3">-->
<!--      <h5>-->
<!--        {{ typeaheadResponseList[4]?.label }}-->
<!--      </h5>-->
<!--      <p-listbox-->
<!--        (onClick)="selectItemOnClick()"-->
<!--        [options]="typeaheadResponseList[4]?.items"-->
<!--        [(ngModel)]="selectedItem"-->
<!--        optionLabel="name"-->
<!--        [listStyle]="{-->
<!--          height: '400px',-->
<!--          'max-height': '400px',-->
<!--          'max-with': '100%',-->
<!--          'font-size': '12px'-->
<!--        }"-->
<!--        emptyMessage=" "-->
<!--      ></p-listbox>-->
<!--    </div>-->
  </div>
</p-overlayPanel>
<span class="p-input-icon-right">
  <i class="pi pi-spin pi-spinner" *ngIf="isLoading"></i>
  <input
    id="searchTxt"
    #typeahead
    type="text"
    pInputText
    (input)="onKeywordsChange(typeahead.value)"
    (keydown.enter)="handleSimpleSearchOnEnter(typeahead.value)"
    [placeholder]="placeholderSearch"
  />
</span>
<app-filter-text></app-filter-text>
