import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as actions from 'redux/actions';
import { CategoryResponse } from 'app/models';
import { Listbox } from 'primeng/listbox';
import { eq } from 'lodash';
import { ToastNotificationService } from 'app/components';

@Component({
  selector: 'app-author-manager',
  template: `
    <p-dialog
      [dismissableMask]="true"
      [(visible)]="showModal"
      [style]="{ width: '60vw' }"
      [modal]="true"
      position="center"
      (onHide)="closeModalDialog()"
    >
      <ng-template pTemplate="header">
        <h4>{{ title }}</h4>
      </ng-template>
      <div class="p-mt-3">
        <form [formGroup]="form">
          <div class="p-d-flex p-jc-center">
            <div class="p-col">
              <div class="p-fluid">
                <div class="p-field">
                  <label>Name*</label>
                  <input
                    id="name"
                    formControlName="name"
                    inputId="name"
                    type="text"
                    aria-describedby="name-help"
                    [class]="
                      invalidField('name')
                        ? 'details-input ng-invalid ng-dirty'
                        : 'details-input'
                    "
                    pInputText
                  />
                  <small
                    *ngIf="invalidField('name')"
                    id="company-help"
                    class="p-error"
                    >Name is a required field.</small
                  >
                </div>
                <div>
                  <span *ngIf="categorySelected"
                    ><b>Category:</b> {{ categorySelected.name }}</span
                  >
                </div>

                <div class="p-field" *ngIf="categories">
                  <!--                  <label>Select a category</label>-->
                  <div class="p-mt-2"></div>
                  <p-listbox
                    #myListbox
                    (onClick)="selectItemOnClick()"
                    [filter]="true"
                    [options]="categories"
                    formControlName="category"
                    optionLabel="name"
                    filterPlaceHolder="Select a category"
                    [listStyle]="{
                      height: '180px',
                      'max-height': '180px',
                      'max-with': '100%'
                    }"
                    emptyMessage="Loading categories.."
                  ></p-listbox>
                  <small
                    *ngIf="invalidField('category')"
                    id="company-help"
                    class="p-error"
                    >Category is a required option.</small
                  >
                  <div class="p-d-flex p-jc-between p-mt-2">
                    <div>
                      <button
                        pButton
                        icon="pi pi-cog"
                        type="button"
                        class="p-button-sm"
                        label="Manage Categories"
                        (click)="openCategoryManagerModal()"
                      ></button>
                    </div>
                  </div>
                </div>
                <!-- <div class="p-d-flex p-jc-between">
                  <div></div>
                  <div *ngIf="authorSelected">
                    <p-button
                      pRipple
                      icon="pi pi-star"
                      (click)="handleDelete()"
                      label="Unfollow"
                      styleClass="p-button-raised p-button-danger p-button-text"
                    ></p-button>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </form>
      </div>

      <ng-template pTemplate="footer">
        <div class="p-mt-3"></div>
        <p-button
          pRipple
          icon="pi pi-times"
          (click)="closeModalDialog()"
          label="Cancel"
          styleClass="p-button-raised p-button-secondary p-button-text"
        ></p-button>
        <p-button
          [disabled]="isSaving"
          pRipple
          icon="pi pi-check"
          (click)="handleSubmit()"
          label="Save"
          styleClass="p-button-primary"
        >
        </p-button>
      </ng-template>
    </p-dialog>
  `,
  styles: [
    `
      :host ::ng-deep .p-dialog .p-dialog-header {
        border-bottom: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-footer {
        border-top: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-content {
        padding-top: 10px;
      }

      :host ::ng-deep {
        .p-button:focus {
          box-shadow: none;
        }

        .p-inputtext:focus {
          box-shadow: none;
        }

        .p-button {
          background: #465675;
          border-color: transparent;
        }

        .p-button:hover {
          background: #4e5f80;
          border-color: #4e5f80;
        }
      }
    `,
  ],
})
export class SidebarAuthorManagerComponent implements OnInit {
  public form: FormGroup;
  public categories: CategoryResponse[];
  public title: string;
  public showModal: boolean;
  public isSaving: boolean;
  public authorSelected: any;
  public project: any;
  public userProject: any;
  public categorySelected: any;

  @ViewChild('myListbox') myListboxComponent: Listbox;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private notificationService: ToastNotificationService
  ) {
    this.title = 'New Author';
    this.isSaving = false;
    this.categories = [];
    this.categorySelected = null;
  }

  ngOnInit() {
    this.store.select('category').subscribe(({ categories }) => {
      this.categories = categories;
    });

    this.form = this.formBuilder.group({
      id: [null],
      name: [{ value: null, disabled: true }],
      category: [
        {
          value: null,
        },
        Validators.required,
      ],
    });
    this.store
      .select('authorManager')
      .subscribe(
        ({
          loading,
          error,
          message,
          creationMode,
          editionMode,
          successResponse,
          showAuthorManager,
          author,
        }) => {
          this.isSaving = loading;
          this.showModal = showAuthorManager;
          this.title = author ? 'Edit Author' : 'New Author';

          if (showAuthorManager) {
            if (this.myListboxComponent._filterValue) {
              this.myListboxComponent._filterValue = null;
              this.myListboxComponent._filteredOptions = this.categories;
            }
          }

          // Store to Create/Edit/delete Topic
          if (successResponse) {
            this.notificationService.showMessage(message, 'Success');
          } else if (error) {
            this.notificationService.showMessage('Error author manager.', 'Error');
          }

          if (editionMode) {
            this.categorySelected = this.categories.find((item) =>
              eq(item.id, author?.categoryId)
            );
            this.authorSelected = author;
            this.form.get('id').setValue(author.id);
            this.form.get('name').setValue(author.name);
            this.form.get('category').setValue(this.categorySelected);
          }

          if (creationMode) {
            this.categorySelected = null;
            this.authorSelected = null;
            this.form.get('id').setValue(null);
            this.form.get('name').setValue('');
            this.form.get('category').setValue('');
          }
        }
      );
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.userProject = projectSelected;
      }
    });
  }

  selectItemOnClick() {
    this.categorySelected = this.form.get('category').value;
  }

  closeModalDialog() {
    this.form.reset();
    this.store.dispatch(actions.hideAuthorManagerDialog());
  }

  invalidField(field: string) {
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.authorSelected) {
      this.editAuthor();
    } else {
      this.createAuthor();
    }
  }

  onClose() {
    this.store.dispatch(actions.resetAuthorState());
  }

  handleDelete() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to delete?',
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle',
      key: 'deleteAuthor',
      accept: () => {
        this.deleteAuthor();
      },
      reject: (type) => {
        // handle rejected option ConfirmEventType.CANCEL
      },
    });
  }

  createAuthor() {
    this.isSaving = true;
    const payload = {
      userProject: this.userProject,
      name: this.form.get('name').value,
      categoryId: this.categorySelected.id,
    };
    this.store.dispatch(actions.createAuthor({ payload }));
  }

  editAuthor() {
    this.isSaving = true;
    const payload = {
      id: this.authorSelected.id,
      partyId: this.authorSelected.partyId,
      userProject: this.userProject,
      name: this.form.get('name').value,
      userLabelId: this.categorySelected.id,
    };
    this.store.dispatch(actions.updateAuthor({ payload }));
  }

  deleteAuthor() {}

  openCategoryManagerModal() {
    this.store.dispatch(actions.showCategoriesListDialog());
  }
}
