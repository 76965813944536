<div class="media m-t-1">
  <div class="media-body">
    <div class="p-d-flex">
      <div class="p-mr-2">
        <h2 class="mt-4">{{ product.name }}</h2>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ul class="nav">
          <div class="row align-items-end">
            <!--
            <li *ngIf="product?.wikipediaUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.wikipediaUrl" [target]="'_blank'">
                    <i class="pi pi-globe font-17 m-r-3"></i>
                    Wikipedia
                  </a>
                </div>
              </div>
            </li>
            -->
            <li *ngIf="product?.webSiteUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.webSiteUrl" [target]="'_blank'">
                    <i class="pi pi-globe font-17 m-r-3"></i>
                    Website
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="product?.twitterUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.twitterUrl" [target]="'_blank'">
                    <i class="pi pi-twitter font-17 m-r-3"></i>
                    X
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="product?.linkedInUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.linkedInUrl" [target]="'_blank'">
                    <i class="pi pi-linkedin font-17 m-r-3"></i>
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="product?.youTubeUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.youTubeUrl" [target]="'_blank'">
                    <i class="pi pi-youtube font-17 m-r-3"></i>
                    YouTube
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="product?.financial">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.financial" [target]="'_blank'">
                    <i class="pi pi-money-bill font-17 m-r-3"></i>
                    Financial
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="product?.streetAddress">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="product?.locationUrl" [target]="'_blank'">
                    <i class="pi pi-map-marker font-17 m-r-3"></i>
                    {{ product?.city }}
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
  <img
    class="mt-4 mr-5 photo"
    [src]="product.picture"
    onerror="this.src='assets/images/user.jpg'"
  />
</div>
<div class="grid">
  <div class="col-12">
    Related Topics:
    <span *ngFor="let rTopic of relatedTopics">
      <a
        href="javascript:;"
        role="button"
        (click)="onDisplayRelatedTopicContent(rTopic)"
        class="a-button-link"
        >{{ rTopic?.name }}</a
      >
    </span>
  </div>
  <div class="col-12">
    Related Companies:
    <span *ngFor="let rCompany of relatedCompanies">
      <a
        href="javascript:;"
        role="button"
        (click)="onDisplayRelatedCompanyContent(rCompany)"
        class="a-button-link"
        >{{ rCompany?.name }}</a
      >
    </span>
  </div>
  <!--  <div class="col-12">-->
  <!--    Related Products:-->
  <!--    <span *ngFor="let rProduct of relatedProducts">-->
  <!--      <a-->
  <!--        href="javascript:;"-->
  <!--        role="button"-->
  <!--        (click)="onDisplayRelatedProductContent(rProduct)"-->
  <!--        class="a-button-link"-->
  <!--        >{{ rProduct?.name }}</a-->
  <!--      >-->
  <!--    </span>-->
  <!--  </div>-->
</div>

<hr />
<h4>Recent content matching {{ search.getKeywords }}</h4>
<app-content-items
  [parentName]="parentName"
  [items]="contentItems"
  [loading]="!loading"
  [pageSize]="productContentPageSize"
  [page]="currentPage"
  [total]="productContentTotal"
  [keywords]="keywords"
></app-content-items>

<div id="paginator" class="p-d-flex p-jc-between">
  <div>
    <p-paginator
      #pp
      [showCurrentPageReport]="false"
      currentPageReportTemplate="Showing {{ productContentPage }} of {{
        productContentPageSize
      }} pages"
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="productContentPageSize"
      [totalRecords]="productContentTotal"
    >
    </p-paginator>
  </div>
  <div></div>
  <div class="p-mt-4 p-mr-4">
    <app-sort-options
      (sortChangesEmiter)="sortChange($event)"
      [defaultSort]="sortModel"
    ></app-sort-options>
  </div>
</div>
