<div class="card" *ngIf="topic">
  <div class="media">
    <div class="media-body">
      <div class="p-d-flex p-jc-between">
        <!-- Header title -->
        <div>
          <div class="p-d-flex">
            <div class="p-m-2">
              <h3 id="topicContactTitle" class="">{{ topic.name }}</h3>
            </div>
            <div class="p-mr-2">
              <button
                (click)="handleSaveTopic(topic)"
                pButton
                pRipple
                type="button"
                [icon]="starToggle"
                class="p-button-rounded p-button-secondary p-button-text p-button-sm"
              ></button>
            </div>
          </div>

          <!-- Social media contacts -->
          <ul
            class="nav customizer-tab"
            style="padding: 0px; background: rgb(255, 255, 255);"
          >
            <div class="row align-items-end">
              <li *ngIf="topic.webSiteUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="topic.webSiteUrl" [target]="'_blank'">
                      <i class="pi pi-globe font-17 m-r-3"></i>
                      Website
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="topic?.twitterUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="topic?.twitterUrl" [target]="'_blank'">
                      <i class="pi pi-twitter font-17 m-r-3"></i>
                      X
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="topic.linkedInUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="topic.linkedInUrl" [target]="'_blank'">
                      <i class="pi pi-linkedin font-17 m-r-3"></i>
                      LinkedIn
                    </a>
                  </div>
                </div>
              </li>

              <li *ngIf="topic.financial">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="topic.financial" [target]="'_blank'">
                      <i class="pi pi-money-bill font-17 m-r-3"></i>
                      Financial
                    </a>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <!-- Avatar image -->
        <div>
          <img
            class="mt-0 mr-0 photo"
            [src]="topic.picture"
            onerror="this.src='assets/images/blank.jpg'"
          />
        </div>
      </div>
    </div>
  </div>
</div>
