import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { SPINNER_ICON, STAR_ICON, STAR_ICON_FILL } from 'app/constants';
import { eq, find } from 'lodash';
import { OutletProfile, Notification, TopicProfile } from 'app/models';
import {
  addTopicFromDialog,
  addTopicToProject,
  deleteTopicFromManager,
  deleteTopicToProject,
  resetSearch,
  resetTopicProfile,
  showNotification,
  tabControl,
  unSetSearch
} from 'app/redux/actions';

@Component({
  selector: 'app-topic-contact-profile',
  templateUrl: './topic-contact-profile.component.html',
  styles: [
    `
      .back-icon {
        font-size: 0.8rem;
        cursor: pointer;
      }
    `
  ]
})
export class TopicProfileComponent implements OnInit {
  public actionType: string;
  public isTopicSaved: boolean;
  public project: any;
  public starToggle: string;
  public topic: TopicProfile;
  public topicsList: any[];

  constructor(private store: Store<AppState>) {
    this.topic = null;
    this.isTopicSaved = false;
    this.actionType = null;
    this.project = null;
    this.starToggle = STAR_ICON;
    this.topicsList = [];
  }

  /**
   * ejecutar esto de desde el typehead
   * ejecutar esto desde el botot go to coverage
   */

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store.select('topicDetails').subscribe(({ topicContactProfile }) => {
      this.topic = null;
      if (topicContactProfile) {
        this.topic = topicContactProfile;
        this.setTopicSaved();
      }
    });

    this.store
      .select('topics')
      .subscribe(
        ({
          topics,
          isAddingTopic,
          isDeletingTopic,
          hasTopicDeleted,
          hasTopicAdded,
          addTopicToProjectError,
          removeTopicToProjectError
        }) => {
          this.topicsList = topics;
          if (isAddingTopic || isDeletingTopic) {
            this.starToggle = SPINNER_ICON;
          }
          if (hasTopicAdded) {
            this.isTopicSaved = true;
            this.starToggle = STAR_ICON_FILL;
          }
          if (hasTopicDeleted) {
            this.isTopicSaved = false;
            this.starToggle = STAR_ICON;
          }
          if (addTopicToProjectError) {
            this.store.dispatch(
              showNotification({
                notify: new Notification(
                  `Error adding topic to project`,
                  'error',
                  'tl'
                )
              })
            );
            this.setTopicSaved();
          }

          if (removeTopicToProjectError) {
            this.store.dispatch(
              showNotification({
                notify: new Notification(
                  `Error removing topic to project`,
                  'error',
                  'tl'
                )
              })
            );
            this.setTopicSaved();
          }
        }
      );
  }

  handleSaveTopic(topic) {
    if (this.isTopicSaved) {
      this.store.dispatch(
        deleteTopicToProject({
          topic,
          userProject: this.project
        })
      );
      this.isTopicSaved = false;
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
      this.store.dispatch(deleteTopicFromManager({ topic }));
    } else {
      this.store.dispatch(
        addTopicToProject({
          topic,
          userProject: this.project
        })
      );
      this.isTopicSaved = true;
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
      this.store.dispatch(addTopicFromDialog({ topic }));
    }
  }

  handleBackToHome() {
    this.store.dispatch(resetTopicProfile());
    this.store.dispatch(tabControl({ index: 0 }));
    this.store.dispatch(unSetSearch());
    this.store.dispatch(resetSearch({ onReset: true }));
  }

  private setTopicSaved() {
    setTimeout(() => {
      this.isTopicSaved = !!find(this.topicsList, i => eq(i.id, this.topic.id));
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
    }, 500);
  }
}
