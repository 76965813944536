import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { SPINNER_ICON, STAR_ICON, STAR_ICON_FILL } from 'app/constants';
import { head, eq, find } from 'lodash';
import { OutletProfile, Notification } from 'app/models';
import {
  addOutletToProject,
  deleteOutletToProject,
  initAuthorOutletToProjectState,
  resetOutletProfile,
  resetSearch,
  showNotification,
  tabControl,
  unSetSearch
} from 'app/redux/actions';
import { OutletService } from 'app/services';

@Component({
  selector: 'app-outlet-contact-profile',
  templateUrl: './outlet-contact-profile.component.html',
  styles: [
    `
      .back-icon {
        font-size: 0.8rem;
        cursor: pointer;
      }
    `
  ]
})
export class OutletProfileComponent implements OnInit {
  public actionType: string;
  public isOutletSaved: boolean;
  public project: any;
  public starToggle: string;
  public outlet: OutletProfile;
  public outletsOutletsList: any[];
  public picture: string;

  constructor(
    private store: Store<AppState>,
    private outletService: OutletService
  ) {
    this.outlet = null;
    this.isOutletSaved = false;
    this.actionType = null;
    this.project = null;
    this.starToggle = STAR_ICON;
    this.outletsOutletsList = [];
  }

  ngOnInit() {
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.project = projectSelected;
      }
    });

    this.store
      .select('outletContactDetails')
      .subscribe(({ outletContactProfile }) => {
        this.outlet = null;
        if (outletContactProfile) {
          this.outlet = outletContactProfile;
          this.picture = this.outletService.getOutletPicture(
            outletContactProfile.id
          );
          this.setOutletSaved();
        }
      });

    this.store
      .select('authorsOutletsList')
      .subscribe(
        ({
          outlets,
          isAddingAuthorOutlet,
          isDeletingAuthorOutlet,
          hasAuthorOutletDeleted,
          hasAuthorOutletAdded,
          errors
        }) => {
          this.outletsOutletsList = outlets;

          if (isAddingAuthorOutlet || isDeletingAuthorOutlet) {
            this.starToggle = SPINNER_ICON;
          }

          if (hasAuthorOutletAdded) {
            this.isOutletSaved = true;
            this.starToggle = STAR_ICON_FILL;
          }

          if (hasAuthorOutletDeleted) {
            this.isOutletSaved = false;
            this.starToggle = STAR_ICON;
          }

          if (errors?.message) {
            this.store.dispatch(
              showNotification({
                notify: new Notification(
                  `Error ${this.actionType} outlet to project`,
                  'error',
                  'tl'
                )
              })
            );
            this.setOutletSaved();
            setTimeout(() => {
              this.store.dispatch(initAuthorOutletToProjectState());
            }, 500);
          }
        }
      );
  }

  handleSaveOutlet(outlet) {
    if (this.isOutletSaved) {
      this.actionType = 'deleting';
      this.store.dispatch(
        deleteOutletToProject({
          partyId: outlet.id,
          userProject: this.project,
          outlet
        })
      );
    } else {
      this.actionType = 'adding';
      this.store.dispatch(
        addOutletToProject({
          partyId: outlet.id,
          userProject: this.project,
          outlet
        })
      );
    }
  }

    handleBackToHome() {
      this.store.dispatch(resetOutletProfile());
      this.store.dispatch(tabControl({ index: 0 }));
      this.store.dispatch(unSetSearch());
      this.store.dispatch(resetSearch({ onReset: true }));
    }

  private setOutletSaved() {
    setTimeout(() => {
      this.isOutletSaved = !!find(this.outletsOutletsList, i =>
        eq(i.partyId, this.outlet.id)
      );
      this.starToggle = this.isOutletSaved ? STAR_ICON_FILL : STAR_ICON;
    }, 500);
  }
}
