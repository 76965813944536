import { createAction, props } from '@ngrx/store';
import { AuthorProfile } from 'app/models';

export const loadAuthorProfile = createAction(
  '[Author Profile] loadAuthorProfile',
  props<{
    authorId: number;
  }>()
);

export const loadAuthorProfileSuccess = createAction(
  '[Author Profile] loadAuthorProfileSuccess',
  props<{ authorContactProfile: AuthorProfile }>()
);

export const loadAuthorProfileError = createAction(
  '[Author Profile] loadAuthorProfileError',
  props<{ payload: any }>()
);

export const resetAuthorProfile = createAction(
  '[Author Profile] resetAuthorProfile'
);
