import { createAction, props } from '@ngrx/store';
import { Notification } from 'app/models';

export type HeaderMenuItems = 'Home' | 'Topics';

export const startLoading = createAction('[UI Loading] startLoading');

export const stopLoading = createAction('[UI Loading] stopLoading');

export const toggleShowSidebar = createAction('[UI Sidebar] toggleShowSidebar');

export const hideSidebar = createAction('[UI Sidebar] hideSidebar');

export const showSidebar = createAction('[UI Sidebar] showSidebar');

export const showAppSections = createAction(
  '[UI Sidebar] showAppSections',
  props<{
    showNewsFeedSection: boolean;
    showTopicSection: boolean;
    showCoverageSection: boolean;
    showCollectionSection: boolean;
  }>()
);

export const startSearch = createAction('[UI Search] startSearch');

export const stopSearch = createAction('[UI Search] stopSearch');

export const showNotification = createAction(
  '[UI Notification] showNotification',
  props<{ notify: Notification }>()
);

export const tabControl = createAction(
  '[UI Tab Section] tabControl',
  props<{ index: number }>()
);

export const resizeSpitter = createAction(
  '[UI Resize Splitter] resizeSpitter',
  props<{ facetPanelSize: number; contemItemPanelSize: number }>()
);

export const subscriptionExpire = createAction(
  '[UI Subscription Expire] subscriptionExpire',
  props<{ isSubscriptionExpired: boolean; expireMessage: string }>()
);

export const screenSize = createAction(
  '[UI Screen Size] screenSize',
  props<{ screenSizeValues: any }>()
);

export const headerMenuActive = createAction(
  '[UI Header Menu Active] HeaderMenuActive',
  props<{ menuActive: HeaderMenuItems }>()
);
