import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  ViewChild
} from '@angular/core';
import { AuthenticationService, ProjectService } from 'services/index';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { head, eq, includes, truncate } from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import * as moment from 'moment';
import { Project, Search, UserProfile } from 'app/models';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { OverlayPanel } from 'primeng/overlaypanel';
import { HeaderMenuItems } from 'redux/actions';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public expireMessage: string;
  public isSubscriptionExpired: boolean;
  public storeProjectSelected: any;
  public screenSize: number;
  public visibleLabels: boolean;
  public visibleMassageSub: boolean;
  public searchFromViewAllCoverage: boolean;
  public authorSearchForCoverage: boolean;
  public outletSearchForCoverage: boolean;
  public collectionSearch: boolean;
  public limitSize: number;
  public userProfile: UserProfile;
  public sliceControlProjectName: number;
  public isPanelShowed: boolean;
  public topicsCollection: number;

  name: string;
  account: string;
  picture: string;

  public selectedProject: Project;
  public projects: any[];
  public topicsList: any[];

  @ViewChild('opProfile') op: OverlayPanel;

  constructor(
    private authenticationService: AuthenticationService,
    private projectService: ProjectService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.expireMessage = null;
    this.projects = null;
    this.selectedProject = null;
    this.isSubscriptionExpired = false;
    this.visibleLabels = true;
    this.visibleMassageSub = true;
    this.searchFromViewAllCoverage = false;
    this.authorSearchForCoverage = false;
    this.outletSearchForCoverage = false;
    this.outletSearchForCoverage = false;
    this.collectionSearch = false;
    this.limitSize = 1290;
    this.sliceControlProjectName = 12;
    this.isPanelShowed = false;
  }

  ngOnInit() {
    // setTimeout(() => {
    this.store.select('userData').subscribe(({ userProfile, loginLoaded }) => {
      if (loginLoaded) {
        this.userProfile = this.authenticationService.getUserProfile();
        this.account = this.authenticationService.getAccount();
        if (this.authenticationService.isSubscriber()) {
          this.expireMessage = null;
          this.isSubscriptionExpired = false;
        } else if (this.authenticationService.isTrial()) {
          if (this.authenticationService.isTrialActivityExceeded()) {
            this.isSubscriptionExpired = true;
            this.expireMessage = `You have exceeded the maximum usage allowed for a free trial`;
          } else {
            const expiration = this.authenticationService.getExpiration();
            const expDate = expiration ? moment(expiration).format('LL') : null;
            this.expireMessage = `Your free trial expires on ${expDate}`;
          }
        } else {
          this.isSubscriptionExpired = true;
          this.expireMessage = `You do not have an active subscription`;
        }
      }
      if (userProfile) {
        const { firstName, lastName } = userProfile;
        this.name = `${firstName} ${lastName}`;
        this.picture = userProfile.picture;
      } else {
        this.name = this.userProfile
          ? `${this.userProfile.firstName} ${this.userProfile.lastName}`
          : '';
        this.picture = this.userProfile ? this.userProfile.picture : '';
      }
      this.store.dispatch(
        actions.subscriptionExpire({
          isSubscriptionExpired: this.isSubscriptionExpired,
          expireMessage: this.expireMessage
        })
      );
      if (this.expireMessage) {
        this.limitSize = 1700;
      } else {
        this.limitSize = 1300;
      }
    });

    // }, 1000);

    this.store.select('routerReducer').subscribe(({ state }) => {
      const { url, params } = state;
      if (includes(url, 'search') && includes(url, 'coverage')) {
        const { id } = params;
        if (id) {
          this.searchFromViewAllCoverage = true;
          this.authorSearchForCoverage = false;
          this.outletSearchForCoverage = false;
          this.collectionSearch = false;
        }
      }

      if (includes(url, 'author')) {
        this.searchFromViewAllCoverage = false;
        this.authorSearchForCoverage = true;
        this.outletSearchForCoverage = false;
        this.collectionSearch = false;
      }

      if (includes(url, 'outlet')) {
        this.searchFromViewAllCoverage = false;
        this.authorSearchForCoverage = false;
        this.outletSearchForCoverage = true;
        this.collectionSearch = false;
      }

      if (includes(url, 'collections')) {
        this.collectionSearch = true;
        this.searchFromViewAllCoverage = false;
        this.authorSearchForCoverage = false;
        this.outletSearchForCoverage = false;
      }
    });

    // Get topics collection id just to keep collection search after project has been changed
    this.store.select('topicsRanked').subscribe(({ searchBody }) => {
      if (searchBody) {
        const { topicsCollection } = searchBody;
        if (topicsCollection) {
          this.topicsCollection = topicsCollection;
        } else {
          this.topicsCollection = null;
        }
      }
    });

    this.store.select('projects').subscribe(({ projects }) => {
      const projectStorage = this.projectService.getProject();
      if (!!projects.length) {
        this.projects = projects;
        if (projectStorage) {
          const validProject = projectStorage
            ? projects.find(item => eq(item.value, projectStorage.value))
            : null;
          if (validProject) {
            this.store.dispatch(
              actions.projectChange({ projectSelected: validProject })
            );
            this.selectedProject = this.addTruncateProjectName(validProject);
            this.shootBootstrapData(validProject);
          } else {
            this.setProjectByDefault(projects);
          }
        } else {
          this.setProjectByDefault(projects);
        }
      }
    });

    this.store.select('ui').subscribe(({ screenSizeValues }) => {
      this.screenSize = screenSizeValues.scrWidth;

      if (this.screenSize <= 1120) {
        this.visibleMassageSub = false;
      } else {
        this.visibleMassageSub = true;
      }

      if (this.expireMessage) {
        this.visibleLabels = false;
      } else {
        if (this.screenSize <= this.limitSize) {
          this.visibleLabels = false;
        } else {
          this.visibleLabels = true;
        }
      }

      if (this.screenSize <= 1299 && this.screenSize >= 500) {
        this.sliceControlProjectName = 10;
      }
      if (this.screenSize <= 1300 && this.screenSize >= 1400) {
        this.sliceControlProjectName = 12;
      }
      if (this.screenSize <= 1401 && this.screenSize > 1900) {
        this.sliceControlProjectName = 30;
      }
      if (this.isSubscriptionExpired) {
        this.sliceControlProjectName = 9;
      }

      this.selectedProject = this.addTruncateProjectName(this.selectedProject);
    });
  }

  addTruncateProjectName(project: Project) {
    if (project) {
      return new Project({
        ...project,
        tooltip: project.name,
        label: this.textTrucate(project.name)
      });
    }
    return project;
  }

  textTrucate(text) {
    if (text) {
      if (text.length >= this.sliceControlProjectName) {
        return truncate(text, {
          length: this.sliceControlProjectName,
          omission: '...'
        });
      }
    }
    return text;
  }

  setProjectByDefault(projects) {
    const project = head(projects);
    this.store.dispatch(actions.projectChange({ projectSelected: project }));
    this.selectedProject = this.addTruncateProjectName(project);
    this.projectService.saveProjec(project);
    this.shootBootstrapData(project);
  }

  showSignMeUpDialog() {
    this.store.dispatch(actions.showSignUpDialog());
    this.hideOverlayPanel();
  }

  shootBootstrapData(project) {
    if (project) {
      // Dispatch load authors and outlets list
      this.store.dispatch(actions.loadCategories({ projectId: project.value }));
      this.store.dispatch(
        actions.loadAuthorsOutlets({ projectId: project.value })
      );
      this.store.dispatch(
        actions.loadProjectContentItems({ projectId: project.value })
      );
      // Dispatch load topics search
      if (
        !this.searchFromViewAllCoverage &&
        !this.authorSearchForCoverage &&
        !this.outletSearchForCoverage &&
        !this.collectionSearch
      ) {
        this.dispatchTopicSearch();
      }
    }
  }

  dispatchTopicSearch() {
    setTimeout(() => {
      this.store.dispatch(actions.stopSearch());
      this.runDispatchsTopicSearch();
      // this.runDispatchsCoverageSearch(); // Disabled in order to TN-801
    }, 500);
  }

  runDispatchsTopicSearch() {
    const newSearch = new Search({
      facets: { TOPICS: 'newsAuthorsWeek desc' },
      topicsCollection: this.topicsCollection
    });
    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );
    this.store.dispatch(
      actions.setTopicsRanked({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );
  }

  runDispatchsCoverageSearch() {
    const newSearch = new Search({
      facets: {
        AUTHORS: null,
        OUTLETS: null
      },
      topicsCollection: null,
      topicsFilter: null
    });

    this.store.dispatch(
      actions.setSearch({
        search: newSearch
      })
    );

    this.store.dispatch(
      actions.setFacetsSearch({
        searchBody: newSearch,
        page: 0,
        isNewSearch: true
      })
    );

    this.store.dispatch(
      actions.setCoverages({
        searchBody: newSearch,
        topicId: null,
        sort: 'recent',
        page: 0,
        size: 50,
        isSearch: true
      })
    );
  }

  public handleCreateProject({ option }) {
    if (option.value === 0) {
      this.selectedProject = this.addTruncateProjectName(
        this.storeProjectSelected
      );
      this.store.dispatch(actions.createProject());
    }
  }

  public handleEditProject() {
    this.store.dispatch(
      actions.showSelectProjectModal({ projectId: this.selectedProject.value })
    );
  }

  public onClickTab(option) {
    switch (option) {
      case 'search':
        return this.authenticationService.getSearchUrl(
          this.authenticationService.getSSOToken()
        );
      case 'lists':
        return this.authenticationService.getListUrl(
          this.authenticationService.getSSOToken()
        );
      case 'charts':
        return this.authenticationService.getChartsUrl(
          this.authenticationService.getSSOToken()
        );
      case 'events':
        return this.authenticationService.getEventsUrl(
          this.authenticationService.getLegacySSOToken()
        );
      case 'help':
        return this.authenticationService.getHelpUrl();
    }

    return null;
  }

  isTrial() {
    return this.authenticationService.isTrial();
  }

  helpUrl() {
    return this.authenticationService.getHelpUrl();
  }

  logoutUrl() {
    return this.authenticationService.getLogoutUrl();
  }

  logout(): void {
    this.authenticationService.goAuth0Login(null, 'logout');
  }

  removePermission() {
    this.authenticationService.logOut();
  }

  myAccount() {
    this.store.dispatch(actions.showMyAccountDialog());
    this.hideOverlayPanel();
  }

  paymentsUrl() {
    return this.authenticationService.getPaymentsUrl();
  }

  hasTechNewsJsAccess() {
    return this.authenticationService.hasTechNewsJsAccess();
  }

  openReportMissingDataModal() {
    this.store.dispatch(actions.showReportDialog());
  }

  public toggleProfilePanel(event) {
    if (this.isPanelShowed) {
      this.hideOverlayPanel();
    } else {
      this.showOverlayPanel(event);
    }
  }

  public showOverlayPanel(e) {
    this.op.show(e);
    this.isPanelShowed = true;
  }

  public hideOverlayPanel() {
    this.op.hide();
    this.isPanelShowed = false;
  }

  public storeResetLvLGod(option: HeaderMenuItems) {
    if (eq(option, 'Home')) {
      window.location.reload();
    }

    this.store.dispatch(
      actions.headerMenuActive({
        menuActive: option
      })
    );
    this.store.dispatch(actions.tabControl({ index: 1 }));
    this.store.dispatch(actions.unSetSearch());
    this.store.dispatch(actions.resetSearch({ onReset: true }));
  }
}
