import { TypeaheadSearchGrouped } from 'app/models';

export const TYPEAHEAD_FILTER_GROUP: TypeaheadSearchGrouped[] = [
  {
    id: 1,
    label: 'COMPANIES & PRODUCTS',
    items: []
  },
  {
    id: 2,
    label: 'OTHER TOPICS',
    items: []
  },
  {
    id: 3,
    label: 'AUTHORS',
    items: []
  },
  {
    id: 4,
    label: 'OUTLETS',
    items: []
  }
  // {
  //   id: 5,
  //   label: 'EVENTS & AWARDS',
  //   items: []
  // }
];

export const TOPIC = 'Topic';
export const COMPANY = 'Company';
export const AUTHOR = 'Author';
export const OUTLET = 'Outlet';
export const EVENT = 'Event';

export const DEBOUNCE_TIME = 1000;
