<perfect-scrollbar
  class="scroll-container"
  [style]="perfectScrollbarHeight"
  [config]="config"
  #contentItemsPerfectScroll
>
  <div
    *ngIf="!contentItems.length && loading"
    class="d-flex justify-content-center text-muted"
  >
    <app-not-records-found
      [visible]="!contentItems.length"
    ></app-not-records-found>
  </div>
  <ul class="p-list-group">
    <li *ngFor="let item of contentItems">
      <div class="p-grid">
        <div class="p-col">
          <div class="p-list-picture">
            <img [src]="item.imageUrl" />
          </div>
          <div class="p-grid">
            <div class="p-col">
              <span
                class="p-list-title"
                href="javascript:;"
                role="button"
                (click)="goToTitleUrl(item.url)"
              >
                <h6>
                  <span
                    *ngIf="item.titleExcerpt"
                    [innerHTML]="item.titleExcerpt"
                  ></span>
                  <span *ngIf="!item.titleExcerpt && item.title">
                    {{ item.title }}
                  </span>
                </h6>
              </span>
            </div>
            <div class="p-col-fixed" style="width:40px">
              <button
                (click)="handleSaveContenItem(item)"
                pButton
                type="button"
                [icon]="item.isSaved ? starSelected : starUnSelected"
                class="p-button-secondary p-button-text p-0"
              ></button>
            </div>
          </div>

          <div class="p-list-subtitle">
            {{ item.publishDate | date }}
            <span style="margin-left:1em"
              ><a
                href="javascript:;"
                role="button"
                (click)="getAuthorContent(item)"
                >{{ item.author }}</a
              >
              -
              <a
                href="javascript:;"
                role="button"
                (click)="getOutletContent(item)"
                >{{ item.outlet }}</a
              ></span
            >
          </div>
          <div class="p-list-text" [innerHTML]="item.bodyExcerpt"></div>
        </div>
      </div>
    </li>
  </ul>
  <div *ngIf="!loading">
    <app-content-items-skeleton></app-content-items-skeleton>
  </div>
</perfect-scrollbar>
