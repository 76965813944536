<div *ngIf="!showSpinnerProjects" style="width: 100%; height: 100vh;">
  <div class="p-d-flex p-jc-end m-r-10 m-t-20 m-b-10">
    <div>
      <button
        pButton
        type="button"
        [icon]="arrowIcon"
        iconPos="left"
        class="p-button-rounded p-button-outlined p-button-secondary"
        (click)="handleSidebarOpen()"
      ></button>
    </div>
  </div>

  <div *ngIf="!visibleSidebar" [perfectScrollbar]="config">
    <div class="accordion-box">
      <p-accordion (onOpen)="handleShowTopicSection($event)" class="mini">
        <p-accordionTab
          [(selected)]="activeState[4]"
          [transitionOptions]="animationOptions"
          pTooltip="My News Feed"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-wifi rss-icon"></i>
            {{ headerNewsFeed }}</ng-template
          >
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[0]"
          [transitionOptions]="animationOptions"
          pTooltip="My Authors"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-users" style="font-size: 1.2rem"></i>
            {{ headerAuthor }}<app-badge [total]="authorTotalItems"></app-badge
          ></ng-template>
          <app-sidebar-author-list></app-sidebar-author-list>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[2]"
          [transitionOptions]="animationOptions"
          pTooltip="My Topics"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-comments"></i> {{ headerTopic
            }}<app-badge [total]="topicTotalItems"></app-badge
          ></ng-template>
          <app-topics-sidebar></app-topics-sidebar>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[1]"
          [transitionOptions]="animationOptions"
          pTooltip="My Outlets"
          *ngIf="isAdmin()"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-building" style="font-size: 1.2rem"></i>
            {{ headerOutlet }}<app-badge [total]="outletTotalItems"></app-badge
          ></ng-template>
          <app-sidebar-outlet-list></app-sidebar-outlet-list>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[3]"
          [transitionOptions]="animationOptions"
          pTooltip="My Clips"
          *ngIf="isAdmin()"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-paperclip"></i> {{ headerContentItems
            }}<app-badge [total]="contentItemsTotalItems"></app-badge
          ></ng-template>
          <app-sidebar-content-items></app-sidebar-content-items>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>

  <div
    *ngIf="visibleSidebar"
    class="scroll-sidebar-mini"
    [perfectScrollbar]="config"
  >
    <div class="accordion-box">
      <p-accordion (onOpen)="handleShowTopicSection($event)" class="full">
        <p-accordionTab
          class="news-feed"
          [(selected)]="activeState[4]"
          [transitionOptions]="animationOptions"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-wifi p-mr-2 rss-icon"></i>
            {{ headerNewsFeed }}
          </ng-template>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[0]"
          [transitionOptions]="animationOptions"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-users p-mr-2" style="font-size: 1.2rem"></i>
            {{ headerAuthor }}
            <app-badge [total]="authorTotalItems"></app-badge>
          </ng-template>
          <app-sidebar-author-content></app-sidebar-author-content>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[2]"
          [transitionOptions]="animationOptions"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-comments p-mr-2"></i>
            {{ headerTopic }}
            <app-badge [total]="topicTotalItems"></app-badge>
          </ng-template>
          <app-topics-sidebar></app-topics-sidebar>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[1]"
          [transitionOptions]="animationOptions"
          *ngIf="isAdmin()"
        >
          <ng-template pTemplate="header"
            ><i
              class="pi pi-fw pi-building p-mr-2"
              style="font-size: 1.2rem"
            ></i>
            {{ headerOutlet }}
            <app-badge [total]="outletTotalItems"></app-badge>
          </ng-template>
          <app-sidebar-outlet-content></app-sidebar-outlet-content>
        </p-accordionTab>
        <p-accordionTab
          [(selected)]="activeState[3]"
          [transitionOptions]="animationOptions"
          *ngIf="isAdmin()"
        >
          <ng-template pTemplate="header"
            ><i class="pi pi-fw pi-paperclip p-mr-2"></i>
            {{ headerContentItems }}
            <app-badge [total]="contentItemsTotalItems"></app-badge>
          </ng-template>
          <app-sidebar-content-items></app-sidebar-content-items>
        </p-accordionTab>
      </p-accordion>
    </div>
  </div>
</div>
