<div class="card" *ngIf="outlet">
  <div class="media">
    <div class="media-body">
      <div class="p-d-flex p-jc-between">
        <!-- Header title -->
        <div>
          <div class="p-ml-2 p-mt-2">
            <i
              class="pi pi-arrow-left back-icon"
              (click)="handleBackToHome()"
            ></i>
          </div>
          <div class="p-d-flex">
            <div class="p-mr-2 p-ml-2">
              <h3 id="outletContactTitle" class="">{{ outlet.name }}</h3>
            </div>
            <div class="p-mr-2">
              <button
                (click)="handleSaveOutlet(outlet)"
                pButton
                pRipple
                type="button"
                [icon]="starToggle"
                class="p-button-rounded p-button-secondary p-button-text"
              ></button>
            </div>
          </div>
          <!-- Outlet associated -->
          <!-- <ul
            class="nav customizer-tab p-mb-4"
            style="padding: 0px; background: rgb(255, 255, 255);"
          >
            <li>
              <div class="row">
                <div class="col">
                  <h5>
                    <a
                      href="javascript:;"
                      role="button"
                      (click)="onDisplayOutletContent(author.outletId)"
                      >{{ author.outlet }}</a
                    >
                  </h5>
                </div>
              </div>
            </li>
          </ul> -->
          <!-- Social media contacts -->
          <ul
            class="nav customizer-tab"
            style="padding: 0px; background: rgb(255, 255, 255);"
          >
            <div class="row align-items-end">
              <li *ngIf="outlet.webSiteUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="outlet.webSiteUrl" [target]="'_blank'">
                      <i class="pi pi-globe font-17 m-r-3"></i>
                      Website
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="outlet.webSiteUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="outlet.webSiteUrl" [target]="'_blank'">
                      <i class="pi pi-globe font-17 m-r-3"></i>
                      Website
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="outlet.twitter && outlet.twitter != 'cbf'">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="outlet.twitter" [target]="'_blank'">
                      <i class="pi pi-twitter font-17 m-r-3"></i>
                      Twitter
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="outlet.linkedIn && outlet.linkedIn != 'cbf'">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="outlet.linkedIn" [target]="'_blank'">
                      <i class="pi pi-linkedin font-17 m-r-3"></i>
                      LinkedIn
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="outlet.youTubeUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="outlet.youTubeUrl" [target]="'_blank'">
                      <i class="pi pi-youtube font-17 m-r-3"></i>
                      YouTube
                    </a>
                  </div>
                </div>
              </li>
              <li *ngIf="outlet.locationUrl">
                <div class="row">
                  <div class="col-md-auto"></div>
                  <div class="col">
                    <a [href]="outlet.locationUrl" [target]="'_blank'">
                      <i class="pi pi-map-marker font-17 m-r-3"></i>
                      {{ outlet.location }}
                    </a>
                  </div>
                </div>
              </li>
            </div>
          </ul>
        </div>

        <!-- Avatar image -->
        <div>
          <img
            class="mt-0 mr-0 photo"
            [src]="picture"
            onerror="this.src='assets/images/blank.jpg'"
          />
        </div>
      </div>
    </div>
  </div>
</div>
