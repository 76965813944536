import { Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as actions from 'redux/actions';
import { CategorySubmit } from 'app/models';
import { eq } from 'lodash';
import { ToastNotificationService } from 'app/components';

@Component({
  selector: 'app-category-manager',
  template: `
    <p-dialog
      [dismissableMask]="true"
      [(visible)]="showModal"
      [style]="{ width: '40vw' }"
      [modal]="true"
      position="center"
      (onHide)="closeModalDialog()"
    >
      <ng-template pTemplate="header">
        <h4>{{ title }}</h4>
      </ng-template>
      <div class="p-mt-3">
        <form [formGroup]="form">
          <div class="p-d-flex p-jc-center">
            <div class="p-col">
              <div class="p-fluid">
                <div class="p-field">
                  <label>Name*</label>
                  <input
                    id="name"
                    formControlName="name"
                    inputId="name"
                    type="text"
                    aria-describedby="name-help"
                    [class]="
                      invalidField('name')
                        ? 'details-input ng-invalid ng-dirty'
                        : 'details-input'
                    "
                    pInputText
                  />
                  <small
                    *ngIf="invalidField('name')"
                    id="company-help"
                    class="p-error"
                    >Name is a required field.</small
                  >
                </div>
                <div class="p-field">
                  <label>Description</label>
                  <div class="p-mt-2"></div>
                  <input
                    id="description"
                    formControlName="description"
                    inputId="description"
                    type="text"
                    pInputText
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <ng-template pTemplate="footer">
        <div class="p-mt-3"></div>
        <p-button
          pRipple
          icon="pi pi-times"
          (click)="closeModalDialog()"
          label="Cancel"
          styleClass="p-button-raised p-button-secondary p-button-text"
        ></p-button>
        <p-button
          [disabled]="isSaving"
          pRipple
          icon="pi pi-check"
          (click)="handleSubmit()"
          label="Save"
          styleClass="p-button-primary"
        >
        </p-button>
      </ng-template>
    </p-dialog>
  `,
  styles: [
    `
      :host ::ng-deep .p-dialog .p-dialog-header {
        border-bottom: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-footer {
        border-top: 1px solid #dee2e6;
      }

      :host ::ng-deep .p-dialog .p-dialog-content {
        padding-top: 10px;
      }

      :host ::ng-deep .p-dialog .p-dialog-footer .p-button-primary {
        background: #465675 !important;
        background-color: #465675 !important;
        border-color: transparent !important;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
      }
    `
  ]
})
export class CategoryFormComponent implements OnInit {
  public form: FormGroup;
  public title: string;
  public showModal: boolean;
  public isSaving: boolean;
  public project: any;
  public userProject: any;
  public categorySelected: any;

  constructor(
    private store: Store<AppState>,
    private formBuilder: FormBuilder,
    private notificationService: ToastNotificationService
  ) {
    this.title = 'New Category';
    this.isSaving = false;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [{ value: null }, Validators.required],
      description: [null]
    });
    this.store
      .select('category')
      .subscribe(
        ({
          loading,
          error,
          message,
          creationMode,
          editionMode,
          responseSuccess,
          showCategoryManager,
          category
        }) => {
          this.showModal = showCategoryManager;
          this.title = category ? 'Edit Category' : 'New Category';

          // Store to Create/Edit/delete Topic
          if (responseSuccess) {
            this.notificationService.showMessage(message, 'Success');
            this.closeModalDialog();
          } else if (error) {
            this.notificationService.showMessage('Error category', 'Error');
            this.isSaving = false;
          }

          if (editionMode) {
            this.categorySelected = category;
            this.form.get('name').setValue(category.name);
            this.form.get('description').setValue(category.description);
          }

          if (creationMode) {
            this.categorySelected = null;
            this.form.get('name').setValue('');
            this.form.get('description').setValue('');
          }
        }
      );
    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.userProject = projectSelected;
      }
    });
  }

  closeModalDialog() {
    this.isSaving = false;
    this.store.dispatch(actions.hideCategoryManagerDialog());
  }

  invalidField(field: string) {
    return (
      this.form.controls[field].errors && this.form.controls[field].touched
    );
  }

  handleSubmit() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    if (this.categorySelected) {
      this.editCategory();
    } else {
      this.createCategory();
    }
  }

  createCategory() {
    this.isSaving = true;
    const payload = new CategorySubmit({
      name: this.form.get('name').value,
      description: this.form.get('description').value,
      projectId: this.userProject.value
    });
    this.store.dispatch(actions.createCategory({ payload }));
  }

  editCategory() {
    this.isSaving = true;
    const payload = new CategorySubmit({
      id: this.categorySelected.id,
      projectId: this.userProject.value,
      name: this.form.get('name').value,
      description: this.form.get('description').value
    });
    this.store.dispatch(actions.updateCategory({ payload }));
  }
}
