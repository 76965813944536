import { createAction, props } from '@ngrx/store';
import { TopicProfile } from 'app/models';

export const loadTopicProfile = createAction(
  '[Topic Profile] loadTopicProfile',
  props<{
    topicId: number;
  }>()
);

export const loadTopicProfileSuccess = createAction(
  '[Topic Profile] loadTopicProfileSuccess',
  props<{ topicContactProfile: TopicProfile }>()
);

export const loadTopicProfileError = createAction(
  '[Topic Profile] loadTopicProfileError',
  props<{ payload: any }>()
);

export const resetTopicProfile = createAction(
  '[Topic Profile] resetTopicProfile'
);
