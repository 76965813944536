import { createReducer, on } from '@ngrx/store';
import { TopicProfile } from 'app/models';
import {
  loadTopicProfile,
  loadTopicProfileSuccess,
  loadTopicProfileError,
  resetTopicProfile
} from 'redux/actions';

export interface TopicProfileState {
  topicContactProfile: TopicProfile;
  loaded: boolean;
  loading: boolean;
  error: any;
}

const initialState: TopicProfileState = {
  topicContactProfile: null,
  loaded: false,
  loading: false,
  error: null
};

const _topicProfileReducer = createReducer(
  initialState,

  on(loadTopicProfile, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(loadTopicProfileSuccess, (state, { topicContactProfile }) => ({
    ...state,
    topicContactProfile,
    loading: false,
    loaded: true
  })),

  on(loadTopicProfileError, (state, { payload }) => {
    const { url, name, message } = payload;
    return {
      ...state,
      topicContactProfile: null,
      loading: false,
      loaded: false,
      error: {
        url: url || null,
        name: name || null,
        message:
          message || 'An error occurred while fetching topic profile details.'
      }
    };
  }),

  on(resetTopicProfile, state => ({
    ...state,
    topicContactProfile: null
  }))
);

export function topicProfileReducer(state, action) {
  return _topicProfileReducer(state, action);
}
