<div class="media m-t-1">
  <div class="media-body">
    <div class="p-d-flex p-jc-between">
      <!-- Header title -->
      <div>
        <div class="p-d-flex">
          <div class="p-mr-2">
            <h2 id="topicTitle" class="mt-4">{{ topic.name }}</h2>
          </div>
          <div class="p-mr-2 p-mt-4" *ngIf="!hideFollowing">
            <button
              (click)="handleSaveTopic(topic)"
              pButton
              pRipple
              type="button"
              [icon]="starToggle"
              class="p-button-rounded p-button-secondary p-button-text"
            ></button>
          </div>
        </div>
        <!-- Social media contacts -->
        <ul class="nav p-mb-4">
          <div class="row align-items-end">
            <li *ngIf="topic?.webSiteUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="topic?.webSiteUrl" [target]="'_blank'">
                    <i class="pi pi-globe font-17 m-r-3"></i>
                    Website
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="topic?.twitterUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="topic?.twitterUrl" [target]="'_blank'">
                    <i class="pi pi-twitter font-17 m-r-3"></i>
                    X
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="topic?.linkedInUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="topic?.linkedInUrl" [target]="'_blank'">
                    <i class="pi pi-linkedin font-17 m-r-3"></i>
                    LinkedIn
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="topic?.youTubeUrl">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="topic?.youTubeUrl" [target]="'_blank'">
                    <i class="pi pi-youtube font-17 m-r-3"></i>
                    YouTube
                  </a>
                </div>
              </div>
            </li>
            <li *ngIf="topic?.financial">
              <div class="row">
                <div class="col-md-auto"></div>
                <div class="col">
                  <a [href]="topic?.financial" [target]="'_blank'">
                    <i class="pi pi-money-bill font-17 m-r-3"></i>
                    Financial
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
        <!-- Related topics & companies -->
        <div class="p-grid">
          <div class="col-12" *ngIf="relatedCompanies">
            <b>Related Companies & Products:</b>
            <span *ngFor="let rCompany of relatedCompanies">
              <a
                href="javascript:;"
                role="button"
                (click)="onDisplayRelatedCompanyContent(rCompany)"
                class="a-button-link"
                >{{ rCompany?.name }}</a
              >
            </span>
          </div>
          <div class="col-12" *ngIf="relatedTopics">
            <b>Related Topics:</b>
            <span *ngFor="let rTopic of relatedTopics">
              <a
                href="javascript:;"
                role="button"
                (click)="onDisplayRelatedTopicContent(rTopic)"
                class="a-button-link"
              >{{ rTopic?.name }}</a
              >
            </span>
          </div>
        </div>
      </div>
      <!-- Avatar image -->
      <div>
        <img
          class="mt-4 mr-5 photo"
          [src]="topic.picture"
          onerror="this.src='assets/images/blank.jpg'"
        />
      </div>
    </div>
  </div>
</div>

<!-- Coverage chart section -->
<div class="p-grid p-m-2" *ngIf="!hideChartContent">
  <hr />
  <div class="p-col-4" (click)="goToExploreRelatedCoverage(topic)">
    <label style="font-size: medium"
      >Coverage of <strong>{{ topic.name }}</strong> in the past year</label
    >
    <div id="chart-2" *ngIf="longTermChart">
      <app-long-term-chart
        class="p-p-2"
        [topic]="longTermChart"
      ></app-long-term-chart>
    </div>
    <div>
      {{ newsAuthors | number }} authors and {{ newsItems | number }} articles
    </div>
    <div class="p-text-italic" *ngIf="deltaYearPercent !== 0.000001">
      <small>
        {{
          (deltaYearPercent < 0 ? -deltaYearPercent : deltaYearPercent)
            | percent
        }}
        {{
          deltaYearPercent > 0
            ? ' more than previous year'
            : ' less than previous year'
        }}
      </small>
    </div>
  </div>
  <div class="p-col-4">
    <app-sov-chart
      *ngIf="showChartSov"
      [sovChartProps]="sovChartValuesA"
    ></app-sov-chart>
  </div>
  <div class="p-col-4">
    <app-sov-chart
      *ngIf="showChartSov"
      [sovChartProps]="sovChartValuesB"
    ></app-sov-chart>
  </div>
</div>
<hr />
<div class="p-grid" *ngIf="recentContentMatching">
  <div class="p-col-8">
    <label style="font-size: large"
      >Recent content matching {{ recentContentMatching }}</label
    >
  </div>
  <div class="p-col-4">
    <p-button
      class="cov-btn p-button-sm coverage"
      type="button"
      icon="pi pi-search"
      label="View all coverage for {{ topic.name }}"
      (click)="goToExploreRelatedCoverage(topic)"
    ></p-button>
  </div>
</div>

<app-content-items
  [parentName]="parentName"
  [items]="contentItems"
  [loading]="!loading"
  [pageSize]="topicContentPageSize"
  [page]="currentPage"
  [total]="topicContentTotal"
  [keywords]="keywords"
></app-content-items>

<div id="paginator" class="p-d-flex p-jc-between">
  <div *ngIf="topicContentTotalPages >= 2">
    <p-paginator
      #pp
      [showCurrentPageReport]="false"
      currentPageReportTemplate="Showing {{ topicContentPage }} of {{
        topicContentPageSize
      }} pages"
      [pageLinkSize]="3"
      [showFirstLastIcon]="false"
      (onPageChange)="getPage($event)"
      [rows]="topicContentPageSize"
      [totalRecords]="topicContentTotal"
    >
    </p-paginator>
  </div>
  <div class="p-mt-2">
    <p-button
      pTooltip="Report Missing Data"
      class="p-button-sm coverage"
      icon="pi pi-flag"
      label=""
      (click)="openReportMissingDataModal()"
    ></p-button>
  </div>
  <div id="sort">
    <app-sort-options
      (sortChangesEmiter)="sortChange($event)"
      [defaultSort]="sortSelected"
    ></app-sort-options>
  </div>
</div>
