import { createReducer, on } from '@ngrx/store';
import {
  setTopicsSuccess,
  setTopicsError,
  unSetTopics,
  deleteTopicFromManager,
  addTopicFromDialog,
  setAddTopicToProjectError,
  addTopicToProject,
  setDeleteTopicToProjectError,
  deleteTopicToProject,
  deleteTopicToProjectSuccess,
  addTopicToProjectSuccess,
  setTopicMenuItemSelected,
  unSetTopicMenuItemSelected,
  loadTopicsMenuSuccess,
  loadTopicsMenuError
} from 'redux/actions';
import { TopicCard, TopicResponse } from 'app/models';

export interface TopicsState {
  topics: TopicResponse[];
  error: any;
  isDeleteTopic: boolean;
  topicDeleted: TopicCard;
  isAddTopic: boolean;
  topicAdded: TopicCard;
  addTopicToProjectError: any;
  removeTopicToProjectError: any;
  isAddingTopic: boolean;
  isDeletingTopic: boolean;
  hasTopicDeleted: boolean;
  hasTopicAdded: boolean;
  topicsMenu: any;
  topicMenuItemSelected: any;
}

const initialState: TopicsState = {
  topics: [],
  error: null,
  isDeleteTopic: false,
  topicDeleted: null,
  isAddTopic: false,
  topicAdded: null,
  addTopicToProjectError: null,
  removeTopicToProjectError: null,
  isAddingTopic: false,
  isDeletingTopic: false,
  hasTopicDeleted: false,
  hasTopicAdded: false,
  topicsMenu: null,
  topicMenuItemSelected: null
};

const _topicsReducer = createReducer(
  initialState,

  on(setTopicsSuccess, (state, { topics }) => {
    console.log('setTopicsSuccess', topics);
    return {
      ...state,
      topics,
      isDeleteTopic: false,
      topicDeleted: null,
      isAddTopic: false,
      topicAdded: null,
      addTopicToProjectError: null,
      removeTopicToProjectError: null
    };
  }),

  on(setTopicsError, (state, { payload }) => ({
    ...state,
    isDeleteTopic: false,
    topicDeleted: null,
    isAddTopic: false,
    topicAdded: null,
    error: {
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(unSetTopics, state => ({
    ...state,
    topics: [],
    isDeleteTopic: false,
    topicDeleted: null,
    isAddTopic: false,
    topicAdded: null,
    addTopicToProjectError: null,
    removeTopicToProjectError: null
  })),

  on(deleteTopicFromManager, (state, { topic }) => ({
    ...state,
    isDeleteTopic: true,
    topicDeleted: topic,
    isAddTopic: false,
    topicAdded: null,
    topics: state.topics.filter(item => item.userTopicId !== topic.partyId)
  })),

  on(addTopicFromDialog, (state, { topic }) => ({
    ...state,
    isDeleteTopic: false,
    topicDeleted: null,
    isAddTopic: true,
    topicAdded: topic,
    topics: [...state.topics, topic]
  })),

  on(addTopicToProject, (state, { topic, userProject }) => ({
    ...state,
    hasTopicAdded: false,
    isAddingTopic: true,
    addTopicToProjectError: null
  })),

  on(deleteTopicToProject, (state, { topic, userProject }) => ({
    ...state,
    isDeletingTopic: true,
    hasTopicDeleted: false,
    removeTopicToProjectError: null
  })),

  on(addTopicToProjectSuccess, (state, { topic }) => ({
    ...state,
    hasTopicAdded: true,
    isAddingTopic: false,
    isAddTopic: true,
    topicAdded: topic
  })),

  on(deleteTopicToProjectSuccess, (state, { topic }) => ({
    ...state,
    isDeletingTopic: false,
    hasTopicDeleted: true,
    isDeleteTopic: true,
    topicDeleted: topic
  })),

  on(setAddTopicToProjectError, (state, { payload, topicId }) => ({
    ...state,
    hasTopicAdded: false,
    isAddingTopic: false,
    addTopicToProjectError: {
      topic: topicId,
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(setDeleteTopicToProjectError, (state, { payload, topicId }) => ({
    ...state,
    isDeletingTopic: false,
    hasTopicDeleted: false,
    removeTopicToProjectError: {
      topic: topicId,
      url: payload?.url,
      status: payload?.status,
      message: payload?.message
    }
  })),

  on(loadTopicsMenuSuccess, (state, { payload }) => ({
    ...state,
    topicsMenu: payload
  })),

  on(loadTopicsMenuError, (state, { payload }) => ({
    ...state,
    url: payload?.url,
    status: payload?.status,
    message: payload?.message
  })),

  on(setTopicMenuItemSelected, (state, { item }) => ({
    ...state,
    topicMenuItemSelected: item
  })),

  on(unSetTopicMenuItemSelected, (state) => ({
    ...state,
    topicMenuItemSelected: null
  }))
);

export function topicsReducer(state, action) {
  return _topicsReducer(state, action);
}
