import { Component, OnInit, Input, OnChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SearchService, AuthorService, OutletService } from 'services/index';
import { Store } from '@ngrx/store';
import { AppState } from 'app/app.reducer';
import { get, eq, isEmpty, includes, find, toLower } from 'lodash';
import * as actions from 'redux/actions';
import { Notification, FacetSearch } from 'app/models';
import { Paginator } from 'primeng/paginator';
import { Observable } from 'rxjs/Observable';
import { LongTermChart } from 'app/interfaces';
import {
  Content,
  RECENT_SORT,
  STAR_ICON,
  STAR_ICON_FILL
} from 'app/constants';
import { SovChartProps } from 'app/components/sov-chart/sov-chart.interface';

@Component({
  selector: 'facet-topic-content',
  templateUrl: './facet-topic-content.component.html',
  styleUrls: ['./facet-topic-content.component.scss']
})
export class FacetTopicContentComponent implements OnInit, OnChanges {
  @Input() contentData: any;
  public search: FacetSearch;
  public topicContentPage: number;
  public topicContentTotalPages: number;
  public topicContentPageSize: number;
  public topicContentTotal: number;
  public currentPage: number;
  public topicId: number;
  public isTopicSaved: boolean;
  public currentProject: any;
  public loading: boolean;

  public topic: any;
  public contentItems: any[] = [];
  public relatedTopics: any[];
  public relatedCompanies: any[];
  public relatedProducts: any[];
  public keywords: string;
  public topicKeywords: string;
  public companiesList: any[];
  public topicsList: any[];
  public longTermChart: LongTermChart;
  public coveragePastYear: number;
  public newsAuthors: number;
  public newsItems: number;
  public deltaYearPercent: number;
  public starToggle: string;
  public sortSelected: string;
  public hideFollowing: boolean;
  public recentContentMatching: string;
  public sovChartValuesA: SovChartProps;
  public sovChartValuesB: SovChartProps;
  public coverageNewItem: number;
  public hideChartContent: boolean;

  @ViewChild('pp', { static: false }) paginator: Paginator;
  public showChartSov: boolean;
  public parentName: Content;

  constructor(
    private searchService: SearchService,
    private outletService: OutletService,
    private store: Store<AppState>,
    private router: Router
  ) {
    this.parentName = Content.Topic;
    this.hideChartContent = true;
    this.loading = false;
    this.isTopicSaved = false;
    this.keywords = null;
    this.topicKeywords = null;
    this.relatedCompanies = null;
    this.relatedTopics = null;
    this.relatedProducts = null;
    this.longTermChart = null;
    this.starToggle = STAR_ICON;
    this.recentContentMatching = null;
    this.coverageNewItem = null;
    this.sovChartValuesA = null;
    this.sovChartValuesB = null;
    this.showChartSov = true;
  }

  ngOnInit() {
    this.hideFollowing = false;
    this.topicContentPage = 1;
    this.currentPage = 0;

    this.store.select('topicsRanked').subscribe(({ coveragePreview }) => {
      if (coveragePreview) {
        const stats = coveragePreview;
        this.coverageNewItem = stats?.newsItems;
      }
    });

    this.store.select('topic').subscribe(({ topic }) => {
      this.topicId = get(topic, 'id', null);
    });

    this.store
      .select('topicDialog')
      .subscribe(({ sort, error, isSidebarTopic }) => {
        this.sortSelected = sort;
        this.hideChartContent = isSidebarTopic;
        this.parentName = isSidebarTopic ? Content.TopicSidebar : Content.Topic;
        if (error) {
          this.store.dispatch(
            actions.showNotification({
              notify: new Notification('Topic not found', 'error', 'tl')
            })
          );
        }
      });

    this.store.select('search').subscribe(({ searchBody }) => {
      if (searchBody?.keywords) {
        this.keywords = searchBody.keywords;
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.currentProject = projectSelected;
      }
    });
    this.store.select('topics').subscribe(({ topics }) => {
      if (topics) {
        this.topicsList = topics;
      }
    });

    this.store.select('companies').subscribe(({ companies }) => {
      this.companiesList = companies;
    });

    this.store.select('offCanvas').subscribe(({ isOpen }) => {
      if (!isOpen) {
        if (this.currentPage > 0) {
          this.paginator.changePage(0);
        }
      }
    });
  }

  ngOnChanges(changes: any) {
    if (changes.contentData?.currentValue) {
      const {
        topic,
        contentItems,
        facetSearch
      } = changes.contentData?.currentValue;
      this.showChartSov = false;
      this.topic = topic;
      const { stats } = topic;
      const { name, query, implicitQuery } = topic;
      this.topicKeywords = query ? query : implicitQuery ? implicitQuery : name;
      this.setCoverageSection(stats);
      this.getRelatedInformation(topic);
      this.search = facetSearch;
      this.topicContentTotalPages = contentItems.totalPages;
      this.topicContentTotal = contentItems.totalElements;
      this.contentItems = contentItems.content;
      this.topicContentPageSize = this.search.getSize;
      this.currentPage = this.search.getPage;
      this.getIsTopicSaved();
      // this.resetPaginator();
      this.setSovChartData(stats);
    }
  }

  setSovChartData(stats) {
    setTimeout(() => {
      if (stats?.newsItems && this.keywords) {
        if (this.coverageNewItem) {
          if (eq(stats.newsItems, this.coverageNewItem)) {
            this.showChartSov = false;
            this.recentContentMatching = this.topic.name;
          } else {
            if (eq(toLower(this.topicKeywords), toLower(this.keywords))) {
              this.showChartSov = false;
              this.recentContentMatching = this.topic.name;
            } else {
              this.showChartSov = true;
              this.recentContentMatching = this.search.getKeywords;
            }
          }
        } else {
          this.showChartSov = false;
          this.recentContentMatching = this.topic.name;
        }
        this.sovChartValuesA = {
          chartLabels: [this.topic.name, this.keywords],
          chartData: [
            stats.newsItems - this.topicContentTotal,
            this.topicContentTotal
          ],
          chartDataTotal: stats.newsItems,
          query: {
            value: this.topic?.query || '',
            color: { color: 'black' }
          }
        };
        this.sovChartValuesB = {
          chartLabels: [this.keywords, this.topic.name],
          chartData: [
            this.coverageNewItem - this.topicContentTotal,
            this.topicContentTotal
          ],
          chartDataTotal: this.coverageNewItem,
          query: {
            value: this.topic?.query || '',
            color: { color: 'white' }
          }
        };
      } else {
        this.recentContentMatching = this.topic.name;
        this.showChartSov = false;
      }
    }, 1000);
  }

  private setCoverageSection(stats) {
    if (stats) {
      this.coveragePastYear = stats?.newsItems;
      this.newsAuthors = stats?.newsAuthors;
      this.newsItems = stats?.newsItems;
      this.deltaYearPercent = stats?.deltaYearPercent;
      this.longTermChart = {
        longTermSeries: [
          {
            name: 'longterm-history',
            data: stats?.longHistory
          }
        ],
        longTermColor: [stats?.longTrend > 0 ? 'lightgray' : 'lightgray']
      };
    } else {
      this.longTermChart = null;
    }
  }

  public goToExploreRelatedCoverage(topic) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/search/topic/${topic.id}/coverage`])
    );
    window.open(url, '_blank');
  }

  private getRelatedInformation(topic) {
    if (topic) {
      const { related } = topic;
      if (related) {
        this.relatedCompanies = related?.companies
          ? related.companies.content
          : null;
        this.relatedTopics = related?.topics ? related.topics.content : null;
        this.relatedProducts = related?.products
          ? related.products.content
          : null;
      } else {
        this.relatedCompanies = null;
        this.relatedTopics = null;
        this.relatedProducts = null;
      }
    } else {
      this.relatedCompanies = null;
      this.relatedTopics = null;
      this.relatedProducts = null;
    }
  }

  public sortChange(e) {
    this.sortSelected = e;
    this.topicContentPage = 1;
    this.currentPage = 0;
    this.resetPaginator();
    // this.getCompanyContent();
  }

  getPage(e: any) {
    this.topicContentPage = e.page + 1;
    this.currentPage = e.page;
    this.getCompanyContent();
  }

  getCompanyContent() {
    const facetSearch = new FacetSearch(
      this.search.getId,
      this.search.getSearchBy,
      this.search.getKeywords,
      this.sortSelected,
      this.currentPage,
      this.search.getSize
    );

    this.searchService.searchContent(facetSearch).subscribe(response => {
      this.topicContentTotalPages = response.totalPages;
      this.topicContentTotal = response.totalElements;
      this.contentItems = response.content;
      // this.scrollTop();
    });
  }

  resetPaginator() {
    if (this.paginator) {
      this.paginator.changePage(0);
    }
  }

  getOutlet$(id): Observable<any> {
    return this.outletService.getOutletWithAuthors(id, this.topicId);
  }

  handleSaveTopic(topic) {
    if (this.isTopicSaved) {
      this.store.dispatch(
        actions.deleteTopicToProject({
          topic,
          userProject: topic.project
        })
      );
      this.isTopicSaved = false;
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
      this.store.dispatch(actions.deleteTopicFromManager({ topic }));
    } else {
      this.store.dispatch(
        actions.addTopicToProject({
          topic,
          userProject: topic.project
        })
      );
      this.isTopicSaved = true;
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
      this.store.dispatch(actions.addTopicFromDialog({ topic }));
    }
  }

  onDisplayRelatedTopicContent(topic) {
    console.log(this.keywords);

    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(actions.startLoading());
    this.store.dispatch(
      actions.setTopicDialog({
        topicSelected: topic,
        projectSelected: this.currentProject,
        keywords: this.keywords,
        sort,
        isSidebarTopic: false
      })
    );
  }

  onDisplayRelatedCompanyContent(company) {
    const sort = /* this.keywords ? RELEVANT_SORT : */ RECENT_SORT;
    this.store.dispatch(
      actions.setCompanyDialog({
        companyId: company.id,
        projectSelected: this.currentProject,
        keywords: null,
        sort
      })
    );
  }

  private getIsTopicSaved() {
    setTimeout(() => {
      this.isTopicSaved = !!find(this.topicsList, i =>
        eq(i.name, this.topic.name)
      );
      this.starToggle = this.isTopicSaved ? STAR_ICON_FILL : STAR_ICON;
    }, 500);
  }

  getYahooFinanceURL(value: any) {
    if (isEmpty(value)) {
      return null;
    } else if (includes(value, 'http')) {
      const q = value.substring(value.lastIndexOf('/') + 1, value.length);
      return `https://finance.yahoo.com/quote/${q}`;
    } else {
      return `https://finance.yahoo.com/quote/${value}`;
    }
  }

  openReportMissingDataModal() {
    this.store.dispatch(actions.showReportDialog());
  }
}
