<p-menubar>
  <ng-template pTemplate="start">
    <div class="p-grid p-fluid">
      <div
        class="p-col-fixed p-ml-2 p-mr-2"
        style="width:40px; margin-top: 0.6rem;"
      >
        <button
          pButton
          icon="pi pi-refresh"
          class="p-button-sm"
          pTooltip="Reset / New Search"
          (click)="clearInput()"
          [disabled]="loading"
        ></button>
      </div>
      <div class="p-col-fixed input-search-content" [style]="searchWidth">
        <app-typeahead-search
          (runSearchFromInput)="handleSearchOnEnter($event)"
          (runSearchFromTypeahead)="handleSearchByTypeahead($event)"
          (runAuthorOutletSearchFromTypeahead)="
            handleAuthorOutletSearchByTypeahead($event)
          "
          (inputTypeahead)="onKeywordsChange($event)"
          [onSearch]="onSearch"
        ></app-typeahead-search>
      </div>
      <div class="p-col-fixed button-search-content" [style]="buttonWidth">
        <button
          id="searchBtn"
          pButton
          icon="pi pi-search"
          [label]="searchLabel"
          class="p-button-sm"
          (click)="newHandleSearch()"
          [disabled]="loading"
        ></button>
      </div>
      <div class="p-col-fixed" style="width:40px; margin-top: 0.6rem;">
        <button
          pButton
          class="p-button-secondary p-button-sm"
          icon="pi pi-filter"
          pTooltip="Search Filters"
          (click)="toggleModal()"
          [disabled]="loading"
        ></button>
      </div>
      <!-- <div
        class="p-col-fixed"
        style="width:40px; margin-top: 0.6rem;"
        *ngIf="tabControl === 0 && keywordsDispached"
      >
        <button
          pButton
          type="button"
          icon="pi pi-arrow-right"
          class="p-button-secondary p-button-sm"
          pTooltip="Back to coverage section"
          tooltipPosition="bottom"
          (click)="goCoverage()"
        ></button>
      </div> -->
      <div
        class="p-col-fixed"
        *ngIf="tabControl === 1"
        [style]="coverageMargin"
      >
        <!-- <app-coverage-preview></app-coverage-preview> -->
      </div>
      <div
        [class]="
          tabControl === 0 ? 'p-col p-grid p-jc-end' : topicsMenubarClass
        "
        style="width:100%; padding: 1rem;"
        *ngIf="showTopicsMenubar && headerMenuActive === 'Topics'"
      >
        <app-collections-panel></app-collections-panel>
        <app-topics-menubar></app-topics-menubar>
      </div>
      <!--
      <div
        class="p-col-fixed"
        style="width: 165px; margin-top: 0.6rem; margin-left: 0.3rem; margin-right: 0.6rem;"
        *ngIf="tabControl === 0"
      >
        <div>
          <button
            *ngIf="showNewThisWeekButton"
            pButton
            icon="pi pi-bolt"
            label="New this week"
            class="p-button-sm"
            (click)="handleNewThisWeek()"
          ></button>
        </div>
      </div>
      -->
    </div>
  </ng-template>
</p-menubar>

<app-advanced-search
  [reset]="onReset"
  [visible]="showFilters"
  [keywords]="searchValue"
  (toggle)="handleVisible($event)"
  (advanceSearchOptions)="handleAdvanceSearchOptions($event)"
  [setSearchFromTypeahead]="searchToAdvFilter"
>
</app-advanced-search>
