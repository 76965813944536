import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facets-table-skeleton',
  template: `
    <div class="d-flex flex-row">
      <div class="p-2" *ngFor="let content of totalTable">
        <p-table
          [value]="ramdomArray"
          scrollHeight="calc(100vh - 255px)"
          styleClass="p-datatable-sm"
        >
          <ng-template pTemplate="header">
            <tr>
              <th><p-skeleton></p-skeleton></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
            <tr>
              <td><p-skeleton></p-skeleton></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  `
})
export class FacetsSkeletonComponent implements OnInit {
  public ramdomArray: any[];
  public totalTable: any[];
  constructor() {}

  ngOnInit() {
    this.totalTable = new Array(4);
    this.totalTable = this.totalTable.fill(0).map(() => Math.random());
    this.ramdomArray = new Array(20);
    this.ramdomArray = this.ramdomArray.fill(0).map(() => Math.random());
  }
}
