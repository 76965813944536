import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/Observable';
import { AppState } from 'app/app.reducer';
import * as actions from 'redux/actions';
import { Project, ProjectToggle } from 'app/models';
import { AuthenticationService, CategoryService } from 'app/services';
import { Observer } from 'rxjs';
import { eq } from 'lodash';

@Component({
  selector: 'app-alert-settings',
  templateUrl: './alert-settings.component.html',
  styleUrls: ['./alert-settings.component.scss']
})
export class AlertSettingsComponent implements OnInit {
  public showModal: boolean;
  public includeAlerts: any[];
  public projects: Project[];
  public projectId: any;
  public frecuencyValue$: Observable<any>;
  public frecuencyObserver: Observer<any>;
  public disableAll: boolean;
  public frecuency: string;
  public frecuencies: any[];
  public stateOptions: any[];
  public loading: boolean;
  constructor(
    private authService: AuthenticationService,
    private store: Store<AppState>,
    private categoryService: CategoryService
  ) {
    this.loading = true;
    this.projects = null;
    this.projectId = null;
    this.frecuencyValue$ = this.categoryService.getFrequency();
    this.frecuencyObserver = {
      next: value => value,
      error: error => console.warn('error getting current frecuency:', error),
      complete: null
    };
    this.frecuencies = [
      { label: 'Daily', value: 'DAILY' },
      { label: 'Weekly', value: 'WEEKLY' },
      { label: 'Never', value: 'DISABLED' }
    ];
    this.frecuency = 'DAILY';
    this.stateOptions = [
      { label: 'Off', value: false },
      { label: 'On', value: true }
    ];
  }

  ngOnInit(): void {
    this.store.select('ui').subscribe(({ showAlertSettingstModal }) => {
      this.showModal = showAlertSettingstModal;
      if (showAlertSettingstModal) {
        this.getFrecuencyValue();
        this.toggleEmailAlertsValue();
      }
    });

    this.store.select('projects').subscribe(({ projects }) => {
      if (projects) {
        this.projects = projects;
      }
    });

    this.store.select('project').subscribe(({ projectSelected }) => {
      if (projectSelected) {
        this.projectId = projectSelected.value;
      }
    });
  }

  toggleEmailAlertsValue() {
    this.loading = true;
    const totalProject = this.projects.length;
    this.includeAlerts = this.projects.map(i => ({
      ...i,
      included: false
    }));

    this.includeAlerts.forEach((item, index) => {
      this.categoryService
        .getIncludeInEmailAlerts(item.value)
        .subscribe(({ value }) => {
          this.includeAlerts[index].included = value;
          if (eq(index + 1, totalProject)) {
            this.loading = false;
          }
        });
    });
  }

  getProjectLabel(): string {
    return this.authService.getProjectLabel();
  }

  public onClose() {
    this.store.dispatch(actions.hideAlertSettingstModal());
  }

  changeIncludeEmailAlertsByRow(e, id, index) {
    this.loading = true;
    if (e) {
      this.categoryService.activeIncludeInEmailAlerts(id).subscribe(
        (response: any) => {
          this.loading = false;
          this.includeAlerts[index].included = e;
          this.getFrecuencyValue();
          this.disableAll = false;
          if (eq(this.projectId, id)) {
            this.updateCurrentProjectToggle(true);
          }
        },
        ({ error }) => {
          console.log(error);
        }
      );
    } else {
      this.categoryService.deleteIncludeInEmailAlerts(id).subscribe(
        (response: any) => {
          this.loading = false;
          this.includeAlerts[index].included = e;
          if (eq(this.projectId, id)) {
            this.updateCurrentProjectToggle(false);
          }
        },
        ({ error }) => {
          console.log(error);
        }
      );
    }
  }

  getFrecuencyValue() {
    this.frecuencyValue$.subscribe(({ value }) => {
      this.frecuency = value;
      this.disableAll = value === 'DISABLED' ? true : false;
    });
  }

  changeFrecuency({ value }) {
    this.categoryService.setFrequency(value).subscribe(
      (response: any) => {
        console.log(response);
        this.disableAll = eq(value, 'DISABLED');
      },
      ({ error }) => {
        console.log(error);
      }
    );
  }

  public onClickDisableAllEmailAlerts() {
    this.categoryService.deleteAllUserEmailAlerts().subscribe(
      (response: any) => {
        console.log(response);
        this.disableAll = true;
        this.toggleEmailAlertsValue();
        this.updateCurrentProjectToggle(false);
      },
      ({ error }) => {
        console.log(error);
      }
    );
  }

  updateCurrentProjectToggle(active) {
    this.store.dispatch(
      actions.setEmailAlertToogle({
        toggle: new ProjectToggle(this.projectId, active)
      })
    );
  }
}
